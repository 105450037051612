import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import UserContext from "../../context/UserContext";
import { ArrowLeftFromLine } from "lucide-react";
import { ChevronLeft, ChevronRight } from "lucide-react";

const POSubmit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userData } = useContext(UserContext);

  // Имя загруженного PO-файла (PDF)
  const uploadedFile = location.state?.fileName || "No file uploaded";
  const chosenItemNumber = location.state?.itemNumber || null;

  // Список строк (items) + индекс текущего
  const [items, setItems] = useState([]);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  // Ошибки, сохранение, сабмит
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isFormSaved, setIsFormSaved] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Панель справа (скрыть/показать)
  const [isRightVisible, setIsRightVisible] = useState(true);

  // Cost Details (скрыть/показать)
  const [showCostDetails, setShowCostDetails] = useState(false);

  // Начальные значения формы
  const initialFormData = {
    // 1) Главное (цепочка статусов)
    order_placed: "", // No / ExWorks / FCA / DDP / DAP
    awaiting_goods_readiness_from_oem: "",
    arranging_pick_up_from_oem_warehouse: "",
    dispatched_from_oem_wh: "",
    under_customs_clearance: "",
    certificate_or_waiver_letter_required: "",
    // Новое поле для выбора между Certificate и Waiver letter:
    certificate_or_waiver_letter_choice: "",
    under_certification: "",
    certificate_or_waiver_letter_obtained: "",
    arrived_to_our_wh: "",
    arrangement_of_logistics: "",

    // 2) Бизнес-поля
    discount_has_requested: "",
    certificate_number_was_checked_with_ncoc: "",
    certificate_period_is_ok: "",
    certificate_number_is_match: "",

    // 3) Даты
    gate_pass_obtained: "",
    expected_payment_receive_date: "",
    expeditor_ncoc: "",
    status_of_payment_from_ncoc: "",
    payment_date: "",

    // Доставка
    delivery_to: "",
    all_documents_obtained: "",

    // Cost details
    order_cost: "",
    customs_costs: "",
    transportation_costs: "",
    certificate_costs: "",
    additional_unexpected_costs: "",
    overhead: "",
    final_costs: "",
    comments: ""
  };

  const [formData, setFormData] = useState(initialFormData);

  // Определяем список ключей, относящихся к суммовым полям
  const costFields = [
    "order_cost",
    "customs_costs",
    "transportation_costs",
    "certificate_costs",
    "additional_unexpected_costs",
    "overhead"
  ];

  // ---------- Основные поля (без cost details) ----------
  const mainFields = [
    { key: "discount_has_requested", label: "Discount Has Requested", type: "yesNo" },
    { key: "order_placed", label: "Order placed", type: "select3" },
    {
      key: "awaiting_goods_readiness_from_oem",
      label: "Awaiting for goods readiness from OEM",
      type: "dynamicSelect",
    },
    {
      key: "arranging_pick_up_from_oem_warehouse",
      label: "Arranging pick up from OEM warehouse",
      type: "yesNo",
    },
    {
      key: "arrangement_of_logistics",
      label: "Arrangement of logistics",
      type: "yesNo"
    },
    {
      key: "dispatched_from_oem_wh",
      label: "Dispatched from OEM WH",
      type: "yesNo",
    },
    {
      key: "under_customs_clearance",
      label: "Under Customs Clearance",
      type: "yesNo",
    },
    {
      key: "certificate_or_waiver_letter_required",
      label: "Certificate or waiver letter required?",
      type: "yesNo2",
    },
    { key: "certificate_number_was_checked_with_ncoc", label: "Certificate Number Checked With NCOC", type: "yesNo" },
    { key: "certificate_period_is_ok", label: "Certificate Period is OK?", type: "yesNo" },
    { key: "certificate_number_is_match", label: "Certificate Number Is Match", type: "yesNo" },
    {
      key: "under_certification",
      label: "Under certification",
      type: "yesNo",
    },
    {
      key: "certificate_or_waiver_letter_obtained",
      label: "Certificate or waiver letter obtained",
      type: "yesNo",
    },
    {
      key: "arrived_to_our_wh",
      label: "Arrived to our WH",
      type: "yesNo2",
    },
    { key: "delivery_to", label: "Delivered to", type: "deliveryDest" },
    { key: "gate_pass_obtained", label: "Gate Pass Obtained", type: "date" },
    { key: "expected_payment_receive_date", label: "Expected Payment Receive Date", type: "date" },
    { key: "status_of_payment_from_ncoc", label: "Under payment by NCOC", type: "yesNo" },
    { key: "payment_date", label: "Payment Date", type: "date" },
    { key: "expeditor_ncoc", label: "Expeditor NCOC" },
    { key: "all_documents_obtained", label: "All documents obtained", type: "yesNo2" },
  ];

  // ---------- Список для Cost details ----------
  const costDetailFields = [
    { key: "order_cost", label: "Order cost ($)" },
    { key: "customs_costs", label: "Customs costs ($)" },
    { key: "transportation_costs", label: "Transportation costs ($)" },
    { key: "certificate_costs", label: "Certificate costs ($)" },
    { key: "additional_unexpected_costs", label: "Additional unexpected costs ($)" },
    { key: "overhead", label: "Overhead ($)" },
    { key: "final_costs", label: "Final costs ($)" },
    { key: "comments", label: "Comments", type: "textarea" }
  ];

  const isFormComplete = true;

  // Функция для пересчёта итоговой суммы (Final costs)
  const recalcFinalCosts = (updatedData) => {
    let sum = 0;
    costFields.forEach((key) => {
      const raw = updatedData[key] ? updatedData[key].replace(/,/g, "") : "";
      const num = parseFloat(raw);
      if (!isNaN(num)) {
        sum += num;
      }
    });
    let formatted = "";
    if (!isNaN(sum)) {
      formatted = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(sum);
    }
    setFormData((prev) => ({ ...prev, final_costs: formatted }));
  };

  useEffect(() => {
    if (!uploadedFile || uploadedFile === "No file uploaded") return;

    async function fetchData() {
      try {
        const resp = await fetch(
          `https://ai-back-23-production.up.railway.app/get-documents-po/?document_name=${encodeURIComponent(uploadedFile)}`
        );
        if (!resp.ok) {
          throw new Error("Failed to load PO data from server");
        }
        const data = await resp.json();
        if (data.length > 0) {
          setItems(data);
          let idx = 0;
          if (chosenItemNumber) {
            const foundIndex = data.findIndex(
              (el) => String(el.item) === String(chosenItemNumber)
            );
            if (foundIndex !== -1) {
              idx = foundIndex;
            }
          }
          setCurrentItemIndex(idx);
          fillFormData(data[idx]);
        }
      } catch (err) {
        console.error("Error loading po data:", err);
        setError("Ошибка загрузки: " + err.message);
      }
    }
    fetchData();
  }, [uploadedFile, chosenItemNumber]);

  function fillFormData(item) {
    if (!item) return;
    setIsFormSaved(false);

    const {
      order_placed,
      awaiting_goods_readiness_from_oem,
      arranging_pick_up_from_oem_warehouse,
      dispatched_from_oem_wh,
      under_customs_clearance,
      certificate_or_waiver_letter_required,
      certificate_or_waiver_letter_choice,
      under_certification,
      certificate_or_waiver_letter_obtained,
      arrived_to_our_wh,
      arrangement_of_logistics,
      delivery_to,
      status_of_payment_from_ncoc,
      all_documents_obtained,
      discount_has_requested,
      certificate_number_was_checked_with_ncoc,
      certificate_period_is_ok,
      certificate_number_is_match,
      gate_pass_obtained,
      expected_payment_receive_date,
      expeditor_ncoc,
      payment_date,
      order_cost,
      customs_costs,
      transportation_costs,
      certificate_costs,
      additional_unexpected_costs,
      overhead,
      final_costs,
      comments
    } = item;

    setFormData({
      order_placed: order_placed || "",
      awaiting_goods_readiness_from_oem: awaiting_goods_readiness_from_oem || "",
      arranging_pick_up_from_oem_warehouse: arranging_pick_up_from_oem_warehouse || "",
      dispatched_from_oem_wh: dispatched_from_oem_wh || "",
      under_customs_clearance: under_customs_clearance || "",
      certificate_or_waiver_letter_required: certificate_or_waiver_letter_required || "",
      certificate_or_waiver_letter_choice: certificate_or_waiver_letter_choice ? certificate_or_waiver_letter_choice.trim() : "",
      under_certification: under_certification || "",
      certificate_or_waiver_letter_obtained: certificate_or_waiver_letter_obtained || "",
      arrived_to_our_wh: arrived_to_our_wh || "",
      arrangement_of_logistics: arrangement_of_logistics || "",
      delivery_to: delivery_to || "",
      status_of_payment_from_ncoc: status_of_payment_from_ncoc || "",
      all_documents_obtained: all_documents_obtained || "",
      discount_has_requested: discount_has_requested || "",
      certificate_number_was_checked_with_ncoc: certificate_number_was_checked_with_ncoc || "",
      certificate_period_is_ok: certificate_period_is_ok || "",
      certificate_number_is_match: certificate_number_is_match || "",
      gate_pass_obtained: gate_pass_obtained || "",
      expected_payment_receive_date: expected_payment_receive_date || "",
      expeditor_ncoc: expeditor_ncoc || "",
      payment_date: payment_date || "",
      order_cost: order_cost || "",
      customs_costs: customs_costs || "",
      transportation_costs: transportation_costs || "",
      certificate_costs: certificate_costs || "",
      additional_unexpected_costs: additional_unexpected_costs || "",
      overhead: overhead || "",
      final_costs: final_costs || "",
      comments: comments || ""
    });
  }

  function handleInputChange(e) {
    const { name, value } = e.target;
    setIsFormSaved(false);

    // Если пользователь вводит сумму и значение заканчивается на точку – не форматируем пока не появятся цифры после точки
    if (costFields.includes(name)) {
      if (value.slice(-1) === ".") {
        setFormData((prev) => {
          const updated = { ...prev, [name]: value };
          // Не пересчитываем итог, пока точка не завершена цифрами
          return updated;
        });
        return;
      }
      // Удаляем возможные запятые
      let rawVal = value.replace(/,/g, "");
      let numberVal = parseFloat(rawVal);
      let formatted = value;
      if (!isNaN(numberVal)) {
        if (rawVal.includes(".")) {
          const decimals = rawVal.split(".")[1]?.length || 0;
          formatted = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
          }).format(numberVal);
        } else {
          formatted = new Intl.NumberFormat("en-US", {
            maximumFractionDigits: 0,
          }).format(numberVal);
        }
      }
      setFormData((prev) => {
        const updated = { ...prev, [name]: formatted };
        recalcFinalCosts(updated);
        return updated;
      });
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  }

  async function handleSaveChanges() {
    if (!items[currentItemIndex]) {
      alert("No item selected!");
      return;
    }
    setIsSaving(true);
    setError(null);

    try {
      const token = localStorage.getItem("token");
      const bodyObj = {
        document_name: uploadedFile,
        item: items[currentItemIndex].item,
        ...formData
      };

      const resp = await fetch("https://ai-back-23-production.up.railway.app/save-data-po/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(bodyObj)
      });
      if (!resp.ok) {
        const errData = await resp.json();
        throw new Error(errData.detail || "Failed to save PO data");
      }

      alert("Data saved successfully!");
      const newItems = [...items];
      newItems[currentItemIndex] = { ...newItems[currentItemIndex], ...formData };
      setItems(newItems);
      setIsFormSaved(true);
    } catch (err) {
      console.error("Error saving changes:", err);
      setError("Ошибка сохранения: " + err.message);
    } finally {
      setIsSaving(false);
    }
  }

  async function handleSubmit() {
    alert("Submitted! (implement your final logic if needed)");
    setIsSubmitted(true);
  }

  function handlePreviousItem() {
    if (currentItemIndex > 0) {
      const prevIdx = currentItemIndex - 1;
      setCurrentItemIndex(prevIdx);
      fillFormData(items[prevIdx]);
    }
  }
  function handleNextItem() {
    if (currentItemIndex < items.length - 1) {
      const nextIdx = currentItemIndex + 1;
      setCurrentItemIndex(nextIdx);
      fillFormData(items[nextIdx]);
    }
  }

  // Функция для рендеринга поля на основе типа
  function renderField(fld) {
    const val = formData[fld.key] || "";
    if (fld.type === "select3") {
      return (
        <select name={fld.key} value={val} onChange={handleInputChange} className="w-full border rounded px-2 py-1">
          <option value="">-select-</option>
          <option value="No">No</option>
          <option value="ExWorks">ExWorks</option>
          <option value="FCA">FCA</option>
          <option value="DDP">DDP</option>
          <option value="DAP">DAP</option>
        </select>
      );
    }
    if (fld.key === "awaiting_goods_readiness_from_oem") {
      const disabled = (formData.order_placed === "No" || !formData.order_placed);
      let options = [{ value: "", label: "-select-" }];
      if (formData.order_placed === "ExWorks" || formData.order_placed === "FCA") {
        options.push({ value: "Yes", label: "Yes" });
      } else if (formData.order_placed === "DDP" || formData.order_placed === "DAP") {
        options.push({ value: "Dispatched from OEM WH", label: "Dispatched from OEM WH" });
        options.push({ value: "Arrangement of logistics", label: "Arrangement of logistics" });
      }
      return (
        <select
          name={fld.key}
          value={val}
          onChange={handleInputChange}
          disabled={disabled}
          className={`w-full border rounded px-2 py-1 ${disabled ? "bg-gray-100" : ""}`}
        >
          {options.map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
      );
    }
    if (fld.key === "arranging_pick_up_from_oem_warehouse") {
      const disabled = !(
        (formData.order_placed === "ExWorks" || formData.order_placed === "FCA") &&
        formData.awaiting_goods_readiness_from_oem === "Yes"
      );
      return (
        <select
          name={fld.key}
          value={val}
          onChange={handleInputChange}
          disabled={disabled}
          className={`w-full border rounded px-2 py-1 ${disabled ? "bg-gray-100" : ""}`}
        >
          <option value="">-select-</option>
          <option value="Yes">Yes</option>
        </select>
      );
    }
    if (fld.key === "arrangement_of_logistics") {
      const disabled = !(
        (formData.order_placed === "DDP" || formData.order_placed === "DAP") &&
        formData.awaiting_goods_readiness_from_oem === "Arrangement of logistics"
      );
      return (
        <select
          name={fld.key}
          value={val}
          onChange={handleInputChange}
          disabled={disabled}
          className={`w-full border rounded px-2 py-1 ${disabled ? "bg-gray-100" : ""}`}
        >
          <option value="">-select-</option>
          <option value="Yes">Yes</option>
        </select>
      );
    }
    if (fld.key === "dispatched_from_oem_wh") {
      let disabled = true;
      if (
        (formData.order_placed === "ExWorks" || formData.order_placed === "FCA") &&
        formData.arranging_pick_up_from_oem_warehouse === "Yes"
      ) {
        disabled = false;
      }
      if (
        (formData.order_placed === "DDP" || formData.order_placed === "DAP") &&
        formData.awaiting_goods_readiness_from_oem === "Dispatched from OEM WH"
      ) {
        disabled = false;
      }
      return (
        <select
          name={fld.key}
          value={val}
          onChange={handleInputChange}
          disabled={disabled}
          className={`w-full border rounded px-2 py-1 ${disabled ? "bg-gray-100" : ""}`}
        >
          <option value="">-select-</option>
          <option value="Yes">Yes</option>
        </select>
      );
    }
    if (fld.key === "under_customs_clearance") {
      const disabled = !(
        (formData.order_placed === "ExWorks" || formData.order_placed === "FCA") &&
        formData.dispatched_from_oem_wh === "Yes"
      );
      return (
        <select
          name={fld.key}
          value={val}
          onChange={handleInputChange}
          disabled={disabled}
          className={`w-full border rounded px-2 py-1 ${disabled ? "bg-gray-100" : ""}`}
        >
          <option value="">-select-</option>
          <option value="Yes">Yes</option>
        </select>
      );
    }
    if (fld.key === "certificate_or_waiver_letter_required") {
      return (
        <select
          name={fld.key}
          value={val}
          onChange={handleInputChange}
          className="w-full border rounded px-2 py-1"
        >
          <option value="">-select-</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      );
    }
    if (fld.key === "certificate_number_was_checked_with_ncoc" ||
        fld.key === "certificate_period_is_ok" ||
        fld.key === "certificate_number_is_match") {
      const disabled = (formData.certificate_or_waiver_letter_required !== "Yes");
      return (
        <select
          name={fld.key}
          value={val}
          onChange={handleInputChange}
          disabled={disabled}
          className={`w-full border rounded px-2 py-1 ${disabled ? "bg-gray-100" : ""}`}
        >
          <option value="">-select-</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      );
    }
    if (fld.key === "under_certification") {
      const disabled = (formData.certificate_or_waiver_letter_required !== "Yes");
      return (
        <select
          name={fld.key}
          value={val}
          onChange={handleInputChange}
          disabled={disabled}
          className={`w-full border rounded px-2 py-1 ${disabled ? "bg-gray-100" : ""}`}
        >
          <option value="">-select-</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      );
    }
    if (fld.key === "certificate_or_waiver_letter_obtained") {
      const disabled = (formData.under_certification !== "Yes");
      return (
        <select
          name={fld.key}
          value={val}
          onChange={handleInputChange}
          disabled={disabled}
          className={`w-full border rounded px-2 py-1 ${disabled ? "bg-gray-100" : ""}`}
        >
          <option value="">-select-</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      );
    }
    if (fld.key === "arrived_to_our_wh") {
      let disabled = true;
      if (formData.certificate_or_waiver_letter_required === "No") {
        disabled = false;
      }
      if (formData.certificate_or_waiver_letter_obtained === "Yes") {
        disabled = false;
      }
      return (
        <select
          name={fld.key}
          value={val}
          onChange={handleInputChange}
          disabled={disabled}
          className={`w-full border rounded px-2 py-1 ${disabled ? "bg-gray-100" : ""}`}
        >
          <option value="">-select-</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      );
    }
    if (fld.key === "delivery_to") {
      const disabled = (formData.arrived_to_our_wh !== "Yes");
      return (
        <select
          name={fld.key}
          value={val}
          onChange={handleInputChange}
          disabled={disabled}
          className={`w-full border rounded px-2 py-1 ${disabled ? "bg-gray-100" : ""}`}
        >
          <option value="">-select-</option>
          <option value="No">No</option>
          <option value="Delivery to Onshore">Delivery to Onshore</option>
          <option value="Delivery to Bautino">Delivery to Bautino</option>
          <option value="Delivery to Eskene">Delivery to Eskene</option>
        </select>
      );
    }
    if (fld.key === "gate_pass_obtained") {
      const allowedDestinations = ["Delivery to Onshore", "Delivery to Bautino", "Delivery to Eskene"];
      const disabled = !allowedDestinations.includes(formData.delivery_to);
      return (
        <input
          type="date"
          name={fld.key}
          value={val}
          onChange={handleInputChange}
          disabled={disabled}
          className={`w-full border rounded px-2 py-1 ${disabled ? "bg-gray-100" : ""}`}
        />
      );
    }
    if (fld.key === "expected_payment_receive_date") {
      const allowedDestinations = ["Delivery to Onshore", "Delivery to Bautino", "Delivery to Eskene"];
      const disabled = !allowedDestinations.includes(formData.delivery_to);
      return (
        <input
          type="date"
          name={fld.key}
          value={val}
          onChange={handleInputChange}
          disabled={disabled}
          className={`w-full border rounded px-2 py-1 ${disabled ? "bg-gray-100" : ""}`}
        />
      );
    }
    if (fld.key === "status_of_payment_from_ncoc") {
      let disabled = true;
      if (formData.delivery_to && formData.delivery_to !== "No") {
        disabled = false;
      }
      return (
        <select
          name={fld.key}
          value={val}
          onChange={handleInputChange}
          disabled={disabled}
          className={`w-full border rounded px-2 py-1 ${disabled ? "bg-gray-100" : ""}`}
        >
          <option value="">-select-</option>
          <option value="Yes">Yes</option>
          <option value="No">Paid by NCOC</option>
        </select>
      );
    }
    if (fld.key === "payment_date") {
      const disabled = (formData.status_of_payment_from_ncoc !== "Yes");
      return (
        <input
          type="date"
          name={fld.key}
          value={val}
          onChange={handleInputChange}
          disabled={disabled}
          className={`w-full border rounded px-2 py-1 ${disabled ? "bg-gray-100" : ""}`}
        />
      );
    }
    if (fld.key === "all_documents_obtained") {
      let disabled = true;
      if (formData.status_of_payment_from_ncoc === "Yes") {
        disabled = false;
      }
      return (
        <select
          name={fld.key}
          value={val}
          onChange={handleInputChange}
          disabled={disabled}
          className={`w-full border rounded px-2 py-1 ${disabled ? "bg-gray-100" : ""}`}
        >
          <option value="">-select-</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      );
    }
    // Для типов yesNo и yesNo2
    if (fld.type === "yesNo" || fld.type === "yesNo2") {
      return (
        <select
          name={fld.key}
          value={val}
          onChange={handleInputChange}
          className="w-full border rounded px-2 py-1"
        >
          <option value="">-select-</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      );
    }
    if (fld.type === "date") {
      return (
        <input
          type="date"
          name={fld.key}
          value={val}
          onChange={handleInputChange}
          className="w-full border rounded px-2 py-1"
        />
      );
    }
    if (fld.type === "textarea") {
      return (
        <textarea
          name={fld.key}
          value={val}
          onChange={handleInputChange}
          rows={2}
          className="w-full border rounded p-1"
        />
      );
    }
    // Если это поле Final costs, делаем его read-only
    if (fld.key === "final_costs") {
      return (
        <input
          type="text"
          name={fld.key}
          value={val}
          readOnly
          className="w-full border rounded px-2 py-1 bg-gray-100"
        />
      );
    }
    return (
      <input
        type="text"
        name={fld.key}
        value={val}
        onChange={handleInputChange}
        className="w-full border rounded px-2 py-1"
      />
    );
  }

  return (
    <div className="h-screen bg-gray-100 p-6">
      <div className="relative h-[calc(100vh-3rem)] overflow-hidden rounded-xl border bg-white shadow-sm">
        <div className="flex h-full">
          <div className={`h-full transition-all duration-300 ease-in-out ${isRightVisible ? "w-1/2" : "w-full"}`}>
            <div className="h-full flex flex-col p-4 overflow-auto">
              <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">PO by Line Item</h1>
                <button onClick={() => navigate("/po")} className="flex items-center bg-gray-500 text-white px-4 py-2 rounded">
                  <ArrowLeftFromLine className="h-4 w-4 mr-2" />
                  PO Status Overview
                </button>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">Uploaded PO: {uploadedFile}</label>
                <label className="block text-gray-700 font-bold">Current Item: {items[currentItemIndex]?.item || "No item selected"}</label>
              </div>
              <div className="overflow-auto border rounded-lg shadow-md mb-4">
                <table className="w-full border-collapse text-sm">
                  <thead className="bg-gray-100 sticky top-0">
                    <tr>
                      <th className="border px-4 py-2">Field</th>
                      <th className="border px-4 py-2">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mainFields.flatMap((fld) => {
                      if (fld.key === "certificate_or_waiver_letter_required") {
                        const rows = [
                          <tr key={fld.key}>
                            <td className="border px-4 py-2">{fld.label}</td>
                            <td className="border px-4 py-2">{renderField(fld)}</td>
                          </tr>
                        ];
                        if (formData.certificate_or_waiver_letter_required === "Yes") {
                          rows.push(
                            <tr key="certificate_or_waiver_letter_choice">
                              <td className="border px-4 py-2">Select one:</td>
                              <td className="border px-4 py-2">
                                <select
                                  name="certificate_or_waiver_letter_choice"
                                  value={formData.certificate_or_waiver_letter_choice || ""}
                                  onChange={handleInputChange}
                                  className="w-full border rounded px-2 py-1"
                                >
                                  <option value="">-select-</option>
                                  <option value="Certificate">Certificate</option>
                                  <option value="Waiver letter">Waiver letter</option>
                                </select>
                              </td>
                            </tr>
                          );
                        }
                        return rows;
                      } else {
                        return (
                          <tr key={fld.key}>
                            <td className="border px-4 py-2">{fld.label}</td>
                            <td className="border px-4 py-2">{renderField(fld)}</td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
              <button onClick={() => setShowCostDetails(!showCostDetails)} className="bg-amber-200 px-3 py-1 rounded">
                {showCostDetails ? "Hide Cost Details" : "Show Cost Details"}
              </button>
              {showCostDetails && (
                <div className="mt-3 border rounded-lg p-3 bg-gray-50">
                  <h2 className="text-sm font-bold mb-2">Cost Details</h2>
                  <table className="w-full border-collapse text-sm">
                    <tbody>
                      {costDetailFields.map((fld) => (
                        <tr key={fld.key}>
                          <td className="border px-2 py-1 w-1/2">
                            <label className="font-medium">{fld.label}</label>
                          </td>
                          <td className="border px-2 py-1 w-1/2">{renderField(fld)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              <div className="my-4 flex flex-col space-y-4">
                {userData?.role === "admin" && (
                  <div className="flex justify-between items-center">
                    <button onClick={handlePreviousItem} disabled={currentItemIndex === 0} className="bg-gray-500 text-white px-4 py-2 rounded">
                      Previous Item
                    </button>
                    <button onClick={handleNextItem} disabled={currentItemIndex === items.length - 1} className="bg-gray-500 text-white px-4 py-2 rounded">
                      Next Item
                    </button>
                  </div>
                )}
                <div className="flex flex-row items-center space-x-2">
                  <button
                    onClick={handleSaveChanges}
                    disabled={isSaving}
                    className={`bg-blue-500 text-white px-3 py-2 rounded ${
                      isSaving ? "cursor-not-allowed opacity-60" : ""
                    }`}
                  >
                    {isSaving ? "Saving..." : "Save Changes"}
                  </button>
                  {(userData?.role === "buyer" || userData?.role === "buyerFull") && (
                    <button
                      onClick={handleSubmit}
                      disabled={!isFormComplete || !isFormSaved}
                      className={`bg-green-500 text-white px-3 py-2 rounded ${
                        !isFormComplete || !isFormSaved ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
              {error && <p className="text-red-600 mt-2">{error}</p>}
            </div>
          </div>
          <div
            className={`h-full overflow-auto border-l transition-all duration-300 ease-in-out ${
              isRightVisible ? "w-1/2 opacity-100" : "w-0 opacity-0"
            }`}
          >
            <div className="h-full flex flex-col p-6">
              <h2 className="text-xl font-semibold mb-4">PO Information</h2>
              <div className="grid gap-6">
                <div className="space-y-4 rounded-lg border p-4">
                  <div className="grid gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Material / Service</label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {items[currentItemIndex]?.material_service || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Description</label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {items[currentItemIndex]?.description || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Manufacturer</label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {items[currentItemIndex]?.manufacturer || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Part Number</label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {items[currentItemIndex]?.manufacturer_part_number || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Qty</label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {items[currentItemIndex]?.qty || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Unit Price</label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {items[currentItemIndex]?.unit_price || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Order Value</label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {items[currentItemIndex]?.order_value || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Base Unit</label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {items[currentItemIndex]?.base_unit || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Certificate</label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {items[currentItemIndex]?.certificate || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Certificate of Conformity</label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {items[currentItemIndex]?.certificate_of_conformity || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={() => setIsRightVisible(!isRightVisible)}
            className="absolute right-0 top-1/2 z-20 -translate-y-1/2 rounded-l-lg bg-gray-200 px-2 py-8 hover:bg-gray-300"
          >
            {isRightVisible ? <ChevronRight className="h-4 w-4" /> : <ChevronLeft className="h-4 w-4" />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default POSubmit;
