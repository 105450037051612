import React, { useState, useEffect, useRef, useContext } from "react";
import UserContext from "../../context/UserContext";

const Chat = () => {
  const { authorizedFetch, userData } = useContext(UserContext);

  // Список всех пользователей (для списка чатов)
  const [users, setUsers] = useState([]);

  // Текущий выбранный собеседник
  const [selectedUser, setSelectedUser] = useState(null);

  // Все полученные сообщения (между мной и кем угодно). 
  // Приходят из WebSocket, мы их не теряем
  const [allMessages, setAllMessages] = useState([]);

  // Массив сообщений в ТЕКУЩЕМ чате (с выбранным пользователем)
  const [messages, setMessages] = useState([]);

  // Текст (input) нового сообщения
  const [newMessage, setNewMessage] = useState("");

  // Храним WebSocket в useRef, чтобы не пересоздавать на каждый рендер
  const wsRef = useRef(null);

  // =============================
  // 1) Загружаем список пользователей
  // =============================
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const resp = await authorizedFetch("https://ai-back-23-production.up.railway.app/api/users", {
          method: "GET",
        });
        if (resp.ok) {
          const data = await resp.json();
          setUsers(data);
        } else {
          console.error("Ошибка при get_all_users:", resp.status);
        }
      } catch (err) {
        console.error("Ошибка сети fetchUsers:", err);
      }
    };
    fetchUsers();
  }, [authorizedFetch]);

  // =============================
  // 2) Открываем WebSocket, когда userData.email известен
  // =============================
  useEffect(() => {
    if (!userData || !userData.email) {
      console.log("userData ещё не загружен, не открываем WebSocket");
      return;
    }

    console.log("Открываем WebSocket для пользователя:", userData.email);

    // Берём токен (если вы храните его в localStorage)
    const token = localStorage.getItem("token");
    if (!token) {
      console.warn("Нет токена => не открываем WebSocket");
      return;
    }

    // Создаём подключение
    const ws = new WebSocket(`wss://ai-back-23-production.up.railway.app/ws/chat?token=${token}`);
    wsRef.current = ws;

    ws.onopen = () => {
      console.log("WebSocket connected");
    };

    // ---- При получении сообщения из WebSocket ----
    ws.onmessage = (evt) => {
      try {
        const data = JSON.parse(evt.data);

        if (data.type === "chat_history") {
          // Пришла полная история переписки => заменяем messages для текущего чата
          setMessages(data.messages || []);
          // И добавим их в общий список (если нужно)
          setAllMessages((prev) => [...prev, ...(data.messages || [])]);

        } else if (data.type === "chat_message") {
          // Пришло новое сообщение
          // 1) Добавляем во все сообщения (allMessages)
          setAllMessages((prev) => [...prev, data]);

          // 2) Если сейчас выбрана переписка между мной и автором (или получателем) сообщения
          //    => сразу же добавим в "messages"
          if (selectedUser) {
            const isRelevant =
              (
                data.sender_id === selectedUser.email &&
                data.receiver_id === userData.email
              ) ||
              (
                data.sender_id === userData.email &&
                data.receiver_id === selectedUser.email
              );

            if (isRelevant) {
              setMessages((prev) => [...prev, data]);
            } else {
              console.log("Сообщение для другого чата:", data);
            }
          } else {
            console.log("Нет выбранного собеседника => игнорируем в UI (но сохранили в allMessages)", data);
          }

        } else {
          console.log("Неизвестный тип сообщения:", data);
        }
      } catch (err) {
        console.error("Ошибка JSON parse в ws.onmessage:", err, evt.data);
      }
    };

    ws.onerror = (err) => {
      console.error("WebSocket error:", err);
    };

    ws.onclose = () => {
      console.log("WebSocket disconnected");
    };

    // При размонтировании закрываем соединение
    return () => {
      ws.close();
    };
  }, [userData]);

  // =============================
  // 3) При выборе собеседника => запрашиваем историю из БД
  //    + фильтруем локальные allMessages
  // =============================
  const handleSelectUser = (user) => {
    setSelectedUser(user);

    // Сразу же очищаем messages (потом либо запросим get_history, либо возьмём из allMessages)
    setMessages([]);

    // Запрос истории у сервера
    const ws = wsRef.current;
    if (ws && ws.readyState === WebSocket.OPEN) {
      const req = {
        type: "get_history",
        other_user_id: user.email,
      };
      ws.send(JSON.stringify(req));
    }

    // Или, если вы хотите моментально показать «старые сообщения» 
    // (которые мы уже получили прежде) без запроса к серверу:
    // const filtered = allMessages.filter(m => {
    //   return (
    //     (m.sender_id === user.email && m.receiver_id === userData.email) ||
    //     (m.sender_id === userData.email && m.receiver_id === user.email)
    //   );
    // });
    // setMessages(filtered);
  };

  // =============================
  // 4) Отправка нового сообщения
  // =============================
  const handleSendMessage = () => {
    if (!newMessage.trim() || !selectedUser) return;

    const ws = wsRef.current;
    if (!ws || ws.readyState !== WebSocket.OPEN) {
      console.warn("WebSocket не готов => не отправляем");
      return;
    }

    // Подготавливаем объект для отправки
    const payload = {
      type: "chat_message",
      receiver_id: selectedUser.email,
      text: newMessage.trim(),
    };
    ws.send(JSON.stringify(payload));

    // Добавляем сообщение сразу к себе в messages 
    // (так называемый оптимистичный UI)
    const tempMsg = {
      type: "chat_message",
      id: "temp-" + Date.now(),
      sender_id: userData.email,
      receiver_id: selectedUser.email,
      text: newMessage,
      created_at: new Date().toISOString(),
    };

    // 1) добавим во все сообщения
    setAllMessages((prev) => [...prev, tempMsg]);

    // 2) если сейчас этот пользователь выбран => отображаем
    setMessages((prev) => [...prev, tempMsg]);

    setNewMessage("");
  };

  // =============================
  // Рендер одного сообщения
  // =============================
  const renderMessage = (msg, idx) => {
    const isMyMessage = (msg.sender_id === userData.email);
    return (
      <div
        key={idx}
        className={`mb-2 flex ${isMyMessage ? "justify-end" : "justify-start"}`}
      >
        <div
          className={`rounded p-3 ${
            isMyMessage ? "bg-blue-200" : "bg-gray-200"
          } max-w-sm`}
        >
          <p className="text-sm">{msg.text}</p>
          <p className="text-xs text-gray-600">
            {msg.created_at
              ? new Date(msg.created_at).toLocaleTimeString()
              : ""}
          </p>
        </div>
      </div>
    );
  };

  // =============================
  // Интерфейс
  // =============================
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Левая часть – список пользователей */}
      <div className="flex-grow bg-gray-100 flex h-full">
        {/* Сайдбар */}
        <div className="w-1/4 bg-white border-r border-gray-300 h-full overflow-y-auto">
          <h2 className="text-lg font-semibold p-4 border-b">Chats</h2>
          <ul>
            {users.map((u) => (
              <li
                key={u.id}
                className={`p-4 border-b hover:bg-gray-50 cursor-pointer ${
                  selectedUser && selectedUser.id === u.id ? "bg-gray-200" : ""
                }`}
                onClick={() => handleSelectUser(u)}
              >
                <p className="font-semibold">
                  {u.first_name}
                </p>
                <p className="text-sm text-gray-500">
                  {u.email}
                </p>
              </li>
            ))}
          </ul>
        </div>

        {/* Правая часть – окно чата */}
        <div className="flex-grow flex flex-col h-full">
          <header className="p-4 bg-white border-b border-gray-300">
            {selectedUser ? (
              <h2 className="text-lg font-semibold">
                Чат с {selectedUser.first_name}
              </h2>
            ) : (
              <h2 className="text-lg font-semibold">
                Выберите пользователя
              </h2>
            )}
          </header>

          {/* Сами сообщения */}
          <div className="flex-grow p-4 overflow-y-auto">
            {!selectedUser && (
              <p className="text-center text-gray-500">
                Нет выбранного собеседника
              </p>
            )}
            {selectedUser && messages.length === 0 && (
              <p className="text-center text-gray-500">
                Пока нет сообщений
              </p>
            )}
            {selectedUser && messages.map(renderMessage)}
          </div>

          {/* Поле ввода + кнопка "Send" */}
          {selectedUser && (
            <footer className="p-4 bg-white border-t border-gray-300 flex items-center">
              <input
                type="text"
                placeholder="Type a new message"
                className="flex-grow border border-gray-300 rounded p-2"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
              />
              <button
                className="ml-4 bg-blue-500 text-white px-4 py-2 rounded"
                onClick={handleSendMessage}
              >
                Send
              </button>
            </footer>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chat;
