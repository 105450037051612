import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";
import { ChevronDown, ChevronUp, Maximize2 } from "lucide-react";

// Импорт FullCalendar
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

// Импорт Recharts
import {
  BarChart,
  Bar,
  LabelList,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

/**
 * Парсим строку "DD.MM.YYYY" -> Date(UTC).
 * Нужно, чтобы избежать смещения на -1 день из-за локального часового пояса.
 */
function parseDDMMYYYY(dateStr) {
  if (!dateStr) return null;
  const parts = dateStr.split(".");
  if (parts.length !== 3) return null;
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const year = parseInt(parts[2], 10);
  if (isNaN(day) || isNaN(month) || isNaN(year)) return null;
  return new Date(Date.UTC(year, month, day));
}

// ====== Списки для General Performance ======
const shortMonths = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];
const possibleYears = [
  "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"
];

const PoDashboard = () => {
  const navigate = useNavigate();
  const { userData, authorizedFetch } = useContext(UserContext);

  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  // ===== KPI (опционально) =====
  const [userKpis, setUserKpis] = useState(null);
  const [isLoadingUserKpis, setIsLoadingUserKpis] = useState(false);
  const [errorUserKpis, setErrorUserKpis] = useState(null);

  // ===== PO Completion Status =====
  const [poStats, setPoStats] = useState([]);
  const [isLoadingTableOne, setIsLoadingTableOne] = useState(false);
  const [errorTableOne, setErrorTableOne] = useState(null);

  // ===== PO Tracking =====
  const [trackingData, setTrackingData] = useState([]);
  const [isLoadingTableTwo, setIsLoadingTableTwo] = useState(false);
  const [errorTableTwo, setErrorTableTwo] = useState(null);

  // ===== General Performance =====
  const [generalPerformance, setGeneralPerformance] = useState(null);
  const [isLoadingGenPerf, setIsLoadingGenPerf] = useState(false);
  const [errorGenPerf, setErrorGenPerf] = useState(null);

  // ===== Календарь (события) =====
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [errorCalendar, setErrorCalendar] = useState(null);

  // ===== Список PO документов (для календаря, summary) =====
  const [poDocs, setPoDocs] = useState([]);

  // ===== Summary =====
  const [summaryData, setSummaryData] = useState({
    activeRFQ: 0,
    cumulativeRFQ: 0,
    activeLI: 0,
    cumulativeLI: 0
  });

  // Развёрнуты/свёрнуты секции
  const [sectionsVisible, setSectionsVisible] = useState({
    completion: true,
    tracking: false,
    performance: true,
    calendar: false
  });
  const toggleSection = (section) => {
    setSectionsVisible((prev) => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  // Детали "In progress" (в General Performance)
  const [showInProgressDetails, setShowInProgressDetails] = useState(false);

  // Текущий месяц
  const currentMonthIndex = new Date().getMonth();
  const currentMonthShort = shortMonths[currentMonthIndex];

  // "Сдвинутые" месяцы
  const [secondMonthGP, setSecondMonthGP] = useState(shortMonths[(currentMonthIndex + 1) % 12]);
  const [thirdMonthGP, setThirdMonthGP] = useState(shortMonths[(currentMonthIndex + 2) % 12]);
  const [fourthMonthGP, setFourthMonthGP] = useState(shortMonths[(currentMonthIndex + 3) % 12]);
  const [fifthMonthGP, setFifthMonthGP] = useState(shortMonths[(currentMonthIndex + 4) % 12]);
  const [sixthMonthGP, setSixthMonthGP] = useState(shortMonths[(currentMonthIndex + 5) % 12]);
  const [yearGP, setYearGP] = useState(new Date().getFullYear().toString());

  // Все байеры (для Active PO allocation)
  const [allBuyers, setAllBuyers] = useState([]);

  // -------------------- 0) Fetch Buyers (опционально) --------------------
  useEffect(() => {
    const fetchAllBuyers = async () => {
      try {
        const resp = await authorizedFetch("https://ai-back-23-production.up.railway.app/get-buyers/", {
          method: "GET"
        });
        if (!resp.ok) {
          throw new Error("Не удалось загрузить список байеров");
        }
        const data = await resp.json();
        setAllBuyers(data);
      } catch (err) {
        console.error("Failed to fetch buyers:", err);
      }
    };
    fetchAllBuyers();
  }, [authorizedFetch]);

  // -------------------- 1) KPI (опционально) --------------------
  useEffect(() => {
    const fetchUserKpis = async () => {
      try {
        setIsLoadingUserKpis(true);
        setErrorUserKpis(null);
        const resp = await authorizedFetch("https://ai-back-23-production.up.railway.app/get-user-kpis/", {
          method: "GET"
        });
        if (!resp.ok) {
          throw new Error("Не удалось загрузить KPI пользователя");
        }
        const data = await resp.json();
        setUserKpis(data);
      } catch (err) {
        setErrorUserKpis(err.message);
      } finally {
        setIsLoadingUserKpis(false);
      }
    };
    fetchUserKpis();
  }, [authorizedFetch]);

  // -------------------- 2) PO Completion Status --------------------
  useEffect(() => {
    const fetchPoStats = async () => {
      try {
        setIsLoadingTableOne(true);
        setErrorTableOne(null);

        const response = await authorizedFetch("https://ai-back-23-production.up.railway.app/get-po-completion-stats/", {
          method: "GET"
        });
        if (!response.ok) {
          throw new Error("Не удалось загрузить PO Completion Status");
        }
        const data = await response.json();
        setPoStats(data);
      } catch (err) {
        setErrorTableOne(err.message);
      } finally {
        setIsLoadingTableOne(false);
      }
    };
    fetchPoStats();
  }, [authorizedFetch]);

  // -------------------- 3) PO Tracking & Documents --------------------
  useEffect(() => {
    const fetchTrackingData = async () => {
      try {
        setIsLoadingTableTwo(true);
        setErrorTableTwo(null);

        // a) Получаем line items (теперь хотим, чтобы возвращались ВСЕ — даже без статуса)
        const statusResp = await authorizedFetch("https://ai-back-23-production.up.railway.app/get-po-item-status/", {
          method: "GET"
        });
        if (!statusResp.ok) {
          throw new Error("Не удалось загрузить статусы PO (line items)");
        }
        let statuses = await statusResp.json();

        // b) Все PO-документы
        const docsResp = await authorizedFetch("https://ai-back-23-production.up.railway.app/get-documents-po/", {
          method: "GET"
        });
        if (!docsResp.ok) {
          throw new Error("Не удалось загрузить список PO документов");
        }
        const docs = await docsResp.json();
        setPoDocs(docs);

        // --- Формируем события для календаря ---
        const uniqueDocs = {};
        docs.forEach((d) => {
          const dateObj = parseDDMMYYYY(d.deliv_date);
          if (dateObj && !uniqueDocs[d.document_name]) {
            uniqueDocs[d.document_name] = { ...d, dateObj };
          }
        });
        const newCalendarEvents = Object.values(uniqueDocs).map((doc) => {
          const dateStr = doc.dateObj.toISOString().slice(0, 10);
          return {
            title: doc.po_number || doc.document_name,
            start: dateStr,
            end: dateStr,
            document_name: doc.document_name
          };
        });
        setCalendarEvents(newCalendarEvents);

        // --- Summary (active / cumulative) ---
        const now = new Date();
        let activePO = 0,
          cumulativePO = 0,
          activeLI = 0,
          cumulativeLI = 0;

        // Группируем по document_name
        const groups = {};
        docs.forEach((doc) => {
          if (!groups[doc.document_name]) {
            groups[doc.document_name] = {
              deliv_date: doc.deliv_date,
              count: 0
            };
          }
          groups[doc.document_name].count += 1;
        });

        Object.keys(groups).forEach((docName) => {
          const info = groups[docName];
          const parsed = parseDDMMYYYY(info.deliv_date);
          if (parsed && parsed > now) {
            activePO++;
            activeLI += info.count;
          } else {
            cumulativePO++;
            cumulativeLI += info.count;
          }
        });

        setSummaryData({
          activeRFQ: activePO,
          cumulativeRFQ: cumulativePO,
          activeLI,
          cumulativeLI
        });

        // --- Дополнительно: сортируем trackingData по upload_date, затем по item ---
        // Предварительно нужно знать upload_date для каждого document_name
        // (берём из docsResp)
        const uploadDatesMap = {};
        docs.forEach((doc) => {
          // Например, храним ISO-строку, чтобы сравнивать Date(UTC)
          // Или сравниваем как string. Предположим, doc.upload_date - timestamp
          const dateVal = doc.upload_date ? new Date(doc.upload_date) : new Date(0);
          uploadDatesMap[doc.document_name] = dateVal;
        });

        // Преобразуем item в число (если возможно) для сортировки
        // и, главное, чтобы doc без статуса тоже отображался
        // = На самом деле, "get-po-item-status" уже должен вернуть все строки, если бэкенд сделает LEFT JOIN
        // Но если нет, пропустим.

        // Применим локальную сортировку
        statuses.sort((a, b) => {
          const dateA = uploadDatesMap[a.document_name] || new Date(0);
          const dateB = uploadDatesMap[b.document_name] || new Date(0);

          // 1. Сравним по дате загрузки
          const diffDate = dateA - dateB;
          if (diffDate !== 0) return diffDate;

          // 2. Если дата одинаковая, сравним item числово
          const itemA = parseInt(a.item, 10) || 0;
          const itemB = parseInt(b.item, 10) || 0;
          return itemA - itemB;
        });

        setTrackingData(statuses);
      } catch (err) {
        setErrorTableTwo(err.message);
        setErrorCalendar(err.message);
      } finally {
        setIsLoadingTableTwo(false);
      }
    };
    fetchTrackingData();
  }, [authorizedFetch]);

  // -------------------- 4) General Performance --------------------
  useEffect(() => {
    const fetchGeneralPerformance = async () => {
      try {
        setIsLoadingGenPerf(true);
        setErrorGenPerf(null);
        const resp = await authorizedFetch("https://ai-back-23-production.up.railway.app/get-po-general-performance/", {
          method: "GET"
        });
        if (!resp.ok) {
          throw new Error("Не удалось загрузить PO General Performance");
        }
        const data = await resp.json();
        setGeneralPerformance(data);
      } catch (err) {
        setErrorGenPerf(err.message);
      } finally {
        setIsLoadingGenPerf(false);
      }
    };
    fetchGeneralPerformance();
  }, [authorizedFetch]);

  // -------------------- Welcome Modal (один раз) --------------------
  useEffect(() => {
    if (userData) {
      const isWelcomeShown = localStorage.getItem("welcomeShown");
      if (!isWelcomeShown) {
        setShowWelcomeModal(true);
        localStorage.setItem("welcomeShown", "true");
      }
    }
  }, [userData]);

  const handleWelcomeClose = () => {
    setShowWelcomeModal(false);
  };

  // ----- safeGet для General Performance -----
  const safeGet = (monthKey, field) => {
    if (!generalPerformance) return 0;
    if (!generalPerformance[monthKey]) return 0;
    return generalPerformance[monthKey][field] ?? 0;
  };

  // ----- Диаграмма Active PO allocation -----
  const barChartData = React.useMemo(() => {
    if (!trackingData.length || !allBuyers.length) return [];

    const now = new Date();
    const buyerCount = {};
    allBuyers.forEach((b) => {
      if (b.first_name) {
        buyerCount[b.first_name.trim()] = 0;
      }
    });

    trackingData.forEach((li) => {
      const dd = parseDDMMYYYY(li.deliv_date);
      if (dd && dd > now) {
        if (li.responsible && li.responsible.trim() !== "N/A") {
          const key = li.responsible.trim();
          buyerCount[key] = (buyerCount[key] || 0) + 1;
        }
      }
    });

    return allBuyers.map((b) => {
      const buyerName = b.first_name ? b.first_name.trim() : "";
      return {
        name: buyerName,
        assigned: buyerCount[buyerName] || 0
      };
    });
  }, [trackingData, allBuyers]);

  const { activeRFQ, cumulativeRFQ, activeLI, cumulativeLI } = summaryData;

  if (!userData) {
    return <div>Загрузка пользователя...</div>;
  }

  return (
    <div className="container mx-auto p-4 space-y-4 relative">
      {/* ===== Кнопка "Go to RFQ Dashboard" ===== */}
      <div className="absolute top-4 right-4 z-10">
        <button
          onClick={() => navigate("/dashboard")}
          className="px-5 py-2.5 font-semibold rounded-md bg-pink-600 text-white shadow-lg hover:bg-pink-500 transition-transform transform hover:-translate-y-0.5 active:translate-y-0"
        >
          Go to RFQ Dashboard
        </button>
      </div>

      {/* ===== Welcome Modal ===== */}
      {showWelcomeModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h1 className="text-2xl font-bold mb-4">Добро пожаловать, {userData.first_name}!</h1>
            <button
              onClick={handleWelcomeClose}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Продолжить
            </button>
          </div>
        </div>
      )}

      <h1 className="text-3xl font-bold mb-6">PO Dashboard</h1>

      {/* ===== Summary + Calendar ===== */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        {/* Левая колонка: Summary + диаграмма */}
        <div className="bg-white shadow rounded-lg p-4">
          <h2 className="text-xl font-semibold mb-4">Summary</h2>
          <table className="min-w-full">
            <thead>
              <tr>
                <th></th>
                <th className="text-center">Active</th>
                <th className="text-center">Cumulative</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="font-medium">Total PO</td>
                <td className="text-center">{activeRFQ}</td>
                <td className="text-center">{cumulativeRFQ}</td>
              </tr>
              <tr>
                <td className="font-medium">Line items</td>
                <td className="text-center">{activeLI}</td>
                <td className="text-center">{cumulativeLI}</td>
              </tr>
            </tbody>
          </table>

          <h3 className="text-lg font-semibold mt-6">Active PO allocation</h3>
          {!barChartData.length && (
            <p className="text-gray-500 mt-2">Нет данных для диаграммы.</p>
          )}
          {barChartData.length > 0 && (
            <div style={{ width: "100%", height: 300, marginTop: "1rem" }}>
              <ResponsiveContainer>
                <BarChart
                  data={barChartData}
                  layout="vertical"
                  margin={{ top: 20, right: 20, bottom: 20, left: 60 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" />
                  <YAxis dataKey="name" type="category" />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="assigned" fill="#f97316" name="Assigned">
                    <LabelList dataKey="assigned" position="center" fill="white" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>

        {/* Правая колонка: PO Calendar */}
        <div className="bg-white shadow rounded-lg p-4 relative">
          <h2 className="text-xl font-semibold mb-4">PO Calendar</h2>
          {errorCalendar && <p className="text-red-500">{errorCalendar}</p>}
          <div className="absolute top-2 right-2">
            <Link to="/pocalendar">
              <Maximize2 className="text-blue-500 hover:text-blue-700" size={24} />
            </Link>
          </div>
          <div
            style={{
              width: "100%",
              maxWidth: "700px",
              margin: "0 auto",
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: "8px"
            }}
          >
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              events={calendarEvents}
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,dayGridWeek"
              }}
              allDaySlot={true}
              height="450px"
              firstDay={1}
              displayEventTime={false}
              eventDisplay="list-item"
              eventClick={(info) => {
                alert(`PO Document: ${info.event.extendedProps.document_name}`);
              }}
            />
          </div>
        </div>
      </div>

      {/* ===== PO Completion Status ===== */}
      <div className="bg-white shadow rounded-lg">
        <div
          className="flex justify-between items-center p-4 cursor-pointer bg-gray-100"
          onClick={() => toggleSection("completion")}
        >
          <h2 className="text-xl font-semibold">PO Completion Status</h2>
          {sectionsVisible.completion ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </div>
        {sectionsVisible.completion && (
          <div className="p-4 overflow-x-auto">
            {isLoadingTableOne && <p>Загрузка данных...</p>}
            {errorTableOne && <p className="text-red-500">{errorTableOne}</p>}
            {!isLoadingTableOne && !errorTableOne && (
              <table className="min-w-full bg-white border border-gray-200">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="border px-4 py-2">PO</th>
                    <th className="border px-4 py-2">PO Date</th>
                    <th className="border px-4 py-2">Deliv. date</th>
                    <th className="border px-4 py-2">Total Line Items</th>
                    <th className="border px-4 py-2">Not started</th>
                    <th className="border px-4 py-2">In Progress</th>
                    <th className="border px-4 py-2">Completed</th>
                  </tr>
                </thead>
                <tbody>
                  {poStats.length > 0 ? (
                    poStats.map((row, index) => (
                      <tr key={index}>
                        <td className="border px-4 py-2 font-medium">{row.document_name}</td>
                        <td className="border px-4 py-2">{row.po_date || "N/A"}</td>
                        <td className="border px-4 py-2">{row.deliv_date || "N/A"}</td>
                        <td className="border px-4 py-2">{row.total_items}</td>
                        <td className="border px-4 py-2 bg-red-50">{row.not_started}</td>
                        <td className="border px-4 py-2 bg-blue-50">{row.in_progress ?? 0}</td>
                        <td className="border px-4 py-2 bg-green-50">{row.completed}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7} className="border px-4 py-2 text-center text-gray-500">
                        Нет данных для отображения.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>

      {/* ===== General Performance ===== */}
      <div className="bg-white shadow rounded-lg">
        <div
          className="flex justify-between items-center p-4 cursor-pointer bg-gray-100"
          onClick={() => toggleSection("performance")}
        >
          <h2 className="text-xl font-semibold">General Performance</h2>
          {sectionsVisible.performance ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </div>
        {sectionsVisible.performance && (
          <div className="p-4 overflow-x-auto">
            {isLoadingGenPerf && <p>Загрузка данных...</p>}
            {errorGenPerf && <p className="text-red-500">{errorGenPerf}</p>}
            {!isLoadingGenPerf && !errorGenPerf && generalPerformance && (
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                  <thead className="bg-gray-200">
                    <tr>
                      <th className="border px-4 py-2">PO</th>
                      <th className="border px-4 py-2 w-30 text-center">{currentMonthShort}</th>
                      <th className="border px-4 py-2 w-30 text-center">
                        <select
                          value={secondMonthGP}
                          onChange={(e) => setSecondMonthGP(e.target.value)}
                          className="border p-1 rounded"
                        >
                          {shortMonths.map((m) => (
                            <option key={m} value={m}>
                              {m}
                            </option>
                          ))}
                        </select>
                      </th>
                      <th className="border px-4 py-2 w-30 text-center">
                        <select
                          value={thirdMonthGP}
                          onChange={(e) => setThirdMonthGP(e.target.value)}
                          className="border p-1 rounded"
                        >
                          {shortMonths.map((m) => (
                            <option key={m} value={m}>
                              {m}
                            </option>
                          ))}
                        </select>
                      </th>
                      <th className="border px-4 py-2 w-30 text-center">
                        <select
                          value={fourthMonthGP}
                          onChange={(e) => setFourthMonthGP(e.target.value)}
                          className="border p-1 rounded"
                        >
                          {shortMonths.map((m) => (
                            <option key={m} value={m}>
                              {m}
                            </option>
                          ))}
                        </select>
                      </th>
                      <th className="border px-4 py-2 w-30 text-center">
                        <select
                          value={fifthMonthGP}
                          onChange={(e) => setFifthMonthGP(e.target.value)}
                          className="border p-1 rounded"
                        >
                          {shortMonths.map((m) => (
                            <option key={m} value={m}>
                              {m}
                            </option>
                          ))}
                        </select>
                      </th>
                      <th className="border px-4 py-2 w-30 text-center">
                        <select
                          value={sixthMonthGP}
                          onChange={(e) => setSixthMonthGP(e.target.value)}
                          className="border p-1 rounded"
                        >
                          {shortMonths.map((m) => (
                            <option key={m} value={m}>
                              {m}
                            </option>
                          ))}
                        </select>
                      </th>
                      <th className="border px-4 py-2 w-30 text-center">
                        <select
                          value={yearGP}
                          onChange={(e) => setYearGP(e.target.value)}
                          className="border p-1 rounded"
                        >
                          {possibleYears.map((y) => (
                            <option key={y} value={y}>
                              {y}
                            </option>
                          ))}
                        </select>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Пример строк */}
                    <tr className="hover:bg-gray-100">
                      <td className="border px-4 py-2">Total PO</td>
                      <td className="border px-4 py-2 text-center">
                        {safeGet(currentMonthShort, "total_rfq") || 0}
                      </td>
                      <td className="border px-4 py-2 text-center">
                        {safeGet(secondMonthGP, "total_rfq") || 0}
                      </td>
                      <td className="border px-4 py-2 text-center">
                        {safeGet(thirdMonthGP, "total_rfq") || 0}
                      </td>
                      <td className="border px-4 py-2 text-center">
                        {safeGet(fourthMonthGP, "total_rfq") || 0}
                      </td>
                      <td className="border px-4 py-2 text-center">
                        {safeGet(fifthMonthGP, "total_rfq") || 0}
                      </td>
                      <td className="border px-4 py-2 text-center">
                        {safeGet(sixthMonthGP, "total_rfq") || 0}
                      </td>
                      <td className="border px-4 py-2 text-center">
                        {safeGet(yearGP, "total_rfq") || 0}
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-100">
                      <td className="border px-4 py-2">Total LI</td>
                      <td className="border px-4 py-2 text-center">
                        {safeGet(currentMonthShort, "lines") || 0}
                      </td>
                      <td className="border px-4 py-2 text-center">
                        {safeGet(secondMonthGP, "lines") || 0}
                      </td>
                      <td className="border px-4 py-2 text-center">
                        {safeGet(thirdMonthGP, "lines") || 0}
                      </td>
                      <td className="border px-4 py-2 text-center">
                        {safeGet(fourthMonthGP, "lines") || 0}
                      </td>
                      <td className="border px-4 py-2 text-center">
                        {safeGet(fifthMonthGP, "lines") || 0}
                      </td>
                      <td className="border px-4 py-2 text-center">
                        {safeGet(sixthMonthGP, "lines") || 0}
                      </td>
                      <td className="border px-4 py-2 text-center">
                        {safeGet(yearGP, "lines") || 0}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </div>

      {/* ===== PO Tracking ===== */}
      <div className="bg-white shadow rounded-lg">
        <div
          className="flex justify-between items-center p-4 cursor-pointer bg-gray-100"
          onClick={() => toggleSection("tracking")}
        >
          <h2 className="text-xl font-semibold">PO Tracking</h2>
          {sectionsVisible.tracking ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </div>
        {sectionsVisible.tracking && (
          <div
            className={`p-4 overflow-x-auto ${
              trackingData.length > 5 ? "max-h-96 overflow-y-auto" : ""
            }`}
          >
            {isLoadingTableTwo && <p>Загрузка данных...</p>}
            {errorTableTwo && <p className="text-red-500">{errorTableTwo}</p>}
            {!isLoadingTableTwo && !errorTableTwo && (
              <table className="min-w-full bg-white border border-gray-200">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="border px-4 py-2">PO</th>
                    <th className="border px-4 py-2">Line Item</th>
                    <th className="border px-4 py-2">Deliv. date</th>
                    <th className="border px-4 py-2">Status</th>
                    <th className="border px-4 py-2">Responsible</th>
                  </tr>
                </thead>
                <tbody>
                  {trackingData.length > 0 ? (
                    trackingData.map((row, index) => {
                      let shortDate = "N/A";
                      const dd = parseDDMMYYYY(row.deliv_date);
                      if (dd) {
                        shortDate = dd.toISOString().slice(0, 10);
                      }
                      return (
                        <tr key={index}>
                          <td className="border px-4 py-2 font-medium">{row.document_name}</td>
                          <td className="border px-4 py-2">{row.item}</td>
                          <td className="border px-4 py-2">{shortDate}</td>
                          <td className="border px-4 py-2">{row.status}</td>
                          <td className="border px-4 py-2">
                            {row.responsible || "N/A"}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5} className="border px-4 py-2 text-center text-gray-500">
                        Нет данных для отображения.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PoDashboard;
