import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ChevronLeft, ChevronRight, ArrowLeftFromLine } from "lucide-react";

// Функция для форматирования числа с разделителями тысяч
function formatNumber(numStr) {
  // Если значение пустое – возвращаем его как есть
  if (!numStr || numStr.trim() === "") return "";
  // Убираем уже существующие запятые
  const clean = numStr.replace(/,/g, "");
  const num = Number(clean);
  if (isNaN(num)) return numStr;
  // Опция maximumFractionDigits оставляем большой, чтобы сохранить дробную часть без округления
  return num.toLocaleString("en-US", { maximumFractionDigits: 20 });
}

// Обновлённая функция пересчёта: теперь возвращает форматированные значения
function recalcCosts(values) {
  const transCost = parseFloat(values.TransportationCost.replace(/,/g, "")) || 0;
  const certCost = parseFloat(values.CertificateCost.replace(/,/g, "")) || 0;
  const customsCost = parseFloat(values.CustomsClearance.replace(/,/g, "")) || 0;
  const totalCost = transCost + certCost + customsCost;

  const transDays = parseFloat(values.LeadTimeForTransportationDays.replace(/,/g, "")) || 0;
  const certDays = parseFloat(values.LeadTimeForCertificateDays.replace(/,/g, "")) || 0;
  const customDays = parseFloat(values.LeadTimeForCustomsClearanceDays.replace(/,/g, "")) || 0;
  const totalDays = transDays + certDays + customDays;

  return {
    totalCost: formatNumber(totalCost.toString()),
    totalDays: formatNumber(totalDays.toString()),
  };
}

const CertificationAndLogistics = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Из location.state получаем document_name и item
  const uploadedFile = location.state?.document_name || "No file uploaded";
  const currentItem = location.state?.item || "";

  // Начальные данные формы
  const initialFormData = {
    TransportationCost: "",
    LeadTimeForTransportationDays: "",
    CertificateCost: "",
    LeadTimeForCertificateDays: "",
    CustomsClearance: "",
    LeadTimeForCustomsClearanceDays: "",
    TotalTAndCCosts: "",
    TotalLeadTimeForTAndC: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [itemData, setItemData] = useState({});
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isFormSaved, setIsFormSaved] = useState(false);
  const [isRightVisible, setIsRightVisible] = useState(true);

  // Проверка заполнения формы (только для тех полей, которые вводит пользователь)
  const isFormComplete = [
    "TransportationCost",
    "LeadTimeForTransportationDays",
    "CertificateCost",
    "LeadTimeForCertificateDays",
    "CustomsClearance",
    "LeadTimeForCustomsClearanceDays",
  ].every(key => (formData[key] || "").trim() !== "");

  // При "Save Changes" ставим статус "In Progress"
  async function updateCertificationStatus(newStatus) {
    try {
      const resp = await fetch("https://ai-back-23-production.up.railway.app/update-certification-status/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          document_name: uploadedFile,
          item: currentItem,
          status: newStatus,
        }),
      });
      if (!resp.ok) {
        throw new Error(`Не удалось обновить статус сертификации на '${newStatus}'.`);
      }
      console.log(`Status updated to ${newStatus}`);
    } catch (err) {
      throw new Error(err.message);
    }
  }

  // Загрузка данных о документе (из API /get-documents-manually/)
  useEffect(() => {
    const fetchItemData = async () => {
      try {
        const resp = await fetch(
          `https://ai-back-23-production.up.railway.app/get-documents-manually/?document_name=${encodeURIComponent(uploadedFile)}`
        );
        if (!resp.ok) {
          throw new Error("Не удалось загрузить данные документа.");
        }
        const data = await resp.json();
        // Ищем строку с нужным item
        const found = data.find(d => String(d.item) === String(currentItem));
        if (found) {
          setItemData(found);
          // Обратите внимание: здесь обращаемся к ключам с нужным регистром!
          setFormData(prev => ({
            ...prev,
            TransportationCost: found.transportation_cost || "",
            LeadTimeForTransportationDays: found.lead_time_for_transportation_days || "",
            CertificateCost: found.certificate_cost || "",
            LeadTimeForCertificateDays: found.lead_time_for_certificate_days || "",
            CustomsClearance: found.customs_clearance || "",
            LeadTimeForCustomsClearanceDays: found.lead_time_for_customs_clearance_days || "",
            TotalTAndCCosts: found.TotalVendorPriceT_C || "",
            TotalLeadTimeForTAndC: found.TotalLeadTimeForTAndC || "",
          }));
        }
      } catch (err) {
        setError(err.message || "Ошибка при загрузке данных");
      }
    };

    if (uploadedFile !== "No file uploaded" && currentItem) {
      fetchItemData();
    }
  }, [uploadedFile, currentItem]);

  // Обработка изменения полей формы
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Обновляем поле как есть
    setFormData(prev => {
      const newForm = { ...prev, [name]: value };
      // Пересчитываем итоговые поля с помощью recalcCosts (результат уже форматируется)
      const { totalCost, totalDays } = recalcCosts(newForm);
      newForm.TotalTAndCCosts = totalCost;
      newForm.TotalLeadTimeForTAndC = totalDays;
      return newForm;
    });
    setIsFormSaved(false);
  };

  // Обработчик onBlur для форматирования числовых полей
  const handleBlurFormat = (e) => {
    const { name, value } = e.target;
    const formatted = formatNumber(value);
    setFormData(prev => ({
      ...prev,
      [name]: formatted
    }));
  };

  // Сохранение данных через endpoint /save-certification-data/
  const handleSaveChanges = async () => {
    setIsSaving(true);
    setError(null);
    try {
      const resp = await fetch("https://ai-back-23-production.up.railway.app/save-certification-data/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        // Отправляем данные как есть (в формате с запятыми)
        body: JSON.stringify({
          document_name: uploadedFile,
          item: currentItem,
          TransportationCost: formData.TransportationCost,
          LeadTimeForTransportationDays: formData.LeadTimeForTransportationDays,
          CertificateCost: formData.CertificateCost,
          LeadTimeForCertificateDays: formData.LeadTimeForCertificateDays,
          CustomsClearance: formData.CustomsClearance,
          LeadTimeForCustomsClearanceDays: formData.LeadTimeForCustomsClearanceDays,
          TotalTAndCCosts: formData.TotalTAndCCosts,
          TotalLeadTimeForTAndC: formData.TotalLeadTimeForTAndC, // вычисляемое поле – оставляем как есть
        }),
      });
      if (!resp.ok) {
        const data = await resp.json();
        throw new Error(data.detail || "Не удалось сохранить данные сертификации.");
      }
      // После сохранения обновляем статус на "In Progress"
      await updateCertificationStatus("In Progress");
      alert("Данные сохранены (Certification & Logistics). Статус => In Progress");
      setIsFormSaved(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSaving(false);
    }
  };

  // При Submit ставим статус "Completed" и переходим на Pricing
  const handleSubmit = async () => {
    if (!isFormComplete || !isFormSaved) {
      alert("Сначала заполните и сохраните форму!");
      return;
    }
    try {
      await updateCertificationStatus("Completed");
      alert("Статус документа/позиции => Completed. Переходим на Pricing...");
      navigate("/pricing-overview");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="h-screen bg-gray-100 p-6 relative">
      <div className="relative h-[calc(100vh-3rem)] overflow-hidden rounded-xl border bg-white shadow-sm">
        <div className="flex h-full">
          {/* Левая панель (форма) */}
          <div className={`h-full transition-all duration-300 ease-in-out ${isRightVisible ? "w-1/2" : "w-full"}`}>
            <div className="h-full flex flex-col p-4 overflow-auto">
              <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">C&L by line item</h1>
                <div className="flex space-x-2">
                  <button
                    className="flex items-center bg-gray-500 text-white px-4 py-2 rounded"
                    onClick={() => navigate("/certification-overview")}
                  >
                    <ArrowLeftFromLine className="h-4 w-4 mr-2" />
                    back to Certification & Logistics Overview
                  </button>
                </div>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-1">
                  Document: {uploadedFile}
                </label>
                <label className="block text-gray-700 font-bold mb-1">
                  Item: {currentItem}
                </label>
              </div>

              {/* Форма */}
              <div className="overflow-auto border rounded-lg shadow-md mb-4">
                <table className="w-full border-collapse text-sm">
                  <thead className="bg-gray-100 sticky top-0">
                    <tr>
                      <th className="border px-4 py-2">Field</th>
                      <th className="border px-4 py-2">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-4 py-2">Transportation Cost ($)</td>
                      <td className="border px-4 py-2">
                        <input
                          type="text"
                          name="TransportationCost"
                          value={formData.TransportationCost}
                          onChange={handleInputChange}
                          onBlur={handleBlurFormat}
                          className="w-full border rounded px-2 py-1"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">Lead Time (days) - Transport</td>
                      <td className="border px-4 py-2">
                        <input
                          type="text"
                          name="LeadTimeForTransportationDays"
                          value={formData.LeadTimeForTransportationDays}
                          onChange={handleInputChange}
                          className="w-full border rounded px-2 py-1"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">Certificate Cost ($)</td>
                      <td className="border px-4 py-2">
                        <input
                          type="text"
                          name="CertificateCost"
                          value={formData.CertificateCost}
                          onChange={handleInputChange}
                          onBlur={handleBlurFormat}
                          className="w-full border rounded px-2 py-1"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">Lead Time (days) - Certificate</td>
                      <td className="border px-4 py-2">
                        <input
                          type="text"
                          name="LeadTimeForCertificateDays"
                          value={formData.LeadTimeForCertificateDays}
                          onChange={handleInputChange}
                          className="w-full border rounded px-2 py-1"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">Customs Clearance ($)</td>
                      <td className="border px-4 py-2">
                        <input
                          type="text"
                          name="CustomsClearance"
                          value={formData.CustomsClearance}
                          onChange={handleInputChange}
                          onBlur={handleBlurFormat}
                          className="w-full border rounded px-2 py-1"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">Lead Time (days) - Customs</td>
                      <td className="border px-4 py-2">
                        <input
                          type="text"
                          name="LeadTimeForCustomsClearanceDays"
                          value={formData.LeadTimeForCustomsClearanceDays}
                          onChange={handleInputChange}
                          className="w-full border rounded px-2 py-1"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">Total C&L Costs ($)</td>
                      <td className="border px-4 py-2">
                        <input
                          type="text"
                          name="TotalTAndCCosts"
                          value={formData.TotalTAndCCosts ? formatNumber(formData.TotalTAndCCosts) : ""}
                          readOnly
                          className="w-full border rounded px-2 py-1 bg-gray-100 text-gray-700"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">Total Lead Time for C&L (days)</td>
                      <td className="border px-4 py-2">
                        <input
                          type="text"
                          name="TotalLeadTimeForTAndC"
                          value={formData.TotalLeadTimeForTAndC ? formatNumber(formData.TotalLeadTimeForTAndC) : ""}
                          readOnly
                          className="w-full border rounded px-2 py-1 bg-gray-100 text-gray-700"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="mb-4 flex space-x-2">
                <button
                  className={`bg-blue-500 text-white px-4 py-2 rounded ${isSaving ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={handleSaveChanges}
                  disabled={isSaving}
                >
                  {isSaving ? "Saving..." : "Save Changes"}
                </button>
                <button
                  className={`bg-green-500 text-white px-4 py-2 rounded ${!isFormComplete || !isFormSaved ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={handleSubmit}
                  disabled={!isFormComplete || !isFormSaved}
                >
                  Submit
                </button>
              </div>

              {error && <p className="text-red-500 mt-2 whitespace-pre-wrap">{error}</p>}
            </div>
          </div>

          {/* Правая панель (информация об item) */}
          <div className={`h-full overflow-auto border-l transition-all duration-300 ease-in-out ${isRightVisible ? "w-1/2 opacity-100" : "w-0 opacity-0"}`}>
            <div className="h-full flex flex-col p-6">
              <h2 className="text-xl font-semibold mb-4">Item Information</h2>
              <div className="grid gap-6">
                <div className="space-y-4 rounded-lg border p-4">
                  <div className="grid gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Material / Service
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {itemData?.material_service || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Description
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {itemData?.description || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Manufacturer
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {itemData?.manufacturer || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Part Number
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {itemData?.manufacturer_part_number || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Qty
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {itemData?.qty || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Base Unit
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {itemData?.base_unit || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Certificate
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {itemData?.certificate || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Certificate of Conformity
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {itemData?.certificate_of_conformity || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            onClick={() => setIsRightVisible(!isRightVisible)}
            className="absolute right-0 top-1/2 z-20 -translate-y-1/2 rounded-l-lg bg-gray-200 px-2 py-8 hover:bg-gray-300"
          >
            {isRightVisible ? <ChevronRight /> : <ChevronLeft />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CertificationAndLogistics;
