import React, { useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import UserContext from "../../context/UserContext"; 

const LoginPage = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // <-- Новое состояние


  const { fetchUserData } = useContext(UserContext);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    setIsLoading(true);

    const { email, password } = formData;

    // Вызов кастомной функции аутентификации
    try {
      const response = await fetch("https://ai-back-23-production.up.railway.app/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const result = await response.json();
      console.log("Login response:", result);

      if (response.ok && result.token) {
        console.log("Login result:", result);
        // Сохраняем токен (если нужен)
        localStorage.setItem("token", result.token);
        if (result.refresh_token) {
          localStorage.setItem("refresh_token", result.refresh_token);
        }

        // 2) Вызовем fetchUserData(), чтобы контекст подтянул свежие данные
        await fetchUserData();

        // Перенаправляем на страницу Dashboard
        navigate("/dashboard");
      } else {
        console.log("Login error result:", result);
        setErrors({ email: result.message || "Ошибка входа." });
      }
    } catch (error) {
      console.error("Login error:", error);
      setErrors({ email: "Ошибка соединения с сервером." });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-gray-300">
      <div className="bg-gray-800 p-10 rounded-lg shadow-xl w-full max-w-md">
        <h1 className="text-3xl font-extrabold text-white mb-6">Вход в аккаунт</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-400 text-sm mb-2">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Введите ваш email"
              className="w-full px-4 py-2 rounded-md bg-gray-700 text-gray-100"
            />
            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-gray-400 text-sm mb-2">Пароль</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              placeholder="Введите пароль"
              className="w-full px-4 py-2 rounded-md bg-gray-700 text-gray-100"
            />
          </div>
          <div className="flex justify-center">
          <button
            type="submit"
            className={`px-6 py-2 rounded-md text-white font-bold flex items-center justify-center ${
              isLoading ? "bg-gray-500 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-600"
            }`}
            disabled={isLoading} // <-- Блокируем кнопку во время загрузки
          >
            {isLoading ? (
              <span className="loader w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin"></span>
            ) : (
              "Войти"
            )}
          </button>
          </div>
        </form>
        <div className="mt-4 text-center">
          <p className="text-gray-400">
            Нет аккаунта?{" "}
            <Link to="/register" className="text-blue-400 hover:underline">
              Зарегистрируйтесь
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
