// src/pages/Dashboard/Calendar.js
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import UserContext from "../../context/UserContext";

/**
 * Форматирует дату (ISO-строку) в вид "YYYY-MM-DD HH:mm:ss"
 */
function formatDeadline(isoString) {
  if (!isoString) return "";
  const dateObj = new Date(isoString);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");
  const hour = String(dateObj.getHours()).padStart(2, "0");
  const minute = String(dateObj.getMinutes()).padStart(2, "0");
  const second = String(dateObj.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

const CalendarPage = () => {
  const { authorizedFetch } = useContext(UserContext);

  const [calendarEvents, setCalendarEvents] = useState([]);
  const [selectedDateEvents, setSelectedDateEvents] = useState([]);
  const [error, setError] = useState(null);

  // Загружаем документы и формируем события
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const resp = await authorizedFetch(
          "https://ai-back-23-production.up.railway.app/get-documents/",
          {
            method: "GET",
          }
        );
        if (!resp.ok) {
          throw new Error("Не удалось загрузить документы");
        }
        const docs = await resp.json();

        // Для календаря — по одному событию на документ, если есть rfq_deadline
        const uniqueDocs = {};
        docs.forEach((d) => {
          if (d.rfq_deadline && !uniqueDocs[d.document_name]) {
            uniqueDocs[d.document_name] = d;
          }
        });
        const uniqueDocsArray = Object.values(uniqueDocs);

        // Формируем события с пользовательским отображением
        const events = uniqueDocsArray.map((doc) => {
          // Форматируем дату дедлайна, чтобы в title вывести "HH:MM - имя"
          const deadlineObj = new Date(doc.rfq_deadline);
          const timeStr = deadlineObj.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });

          return {
            title: `${timeStr} - ${doc.document_name}`,
            start: doc.rfq_deadline,
            end: doc.rfq_deadline,
            extendedProps: {
              fullDocument: doc.document_name,
              // Отформатируем для внутреннего отображения "YYYY-MM-DD HH:MM:SS"
              deadline: formatDeadline(doc.rfq_deadline),
            },
          };
        });

        setCalendarEvents(events);
      } catch (err) {
        setError(err.message);
      }
    };
    fetchEvents();
  }, [authorizedFetch]);

  // Клик по дню в календаре => показываем события за этот день в левом блоке
  const handleDateClick = (arg) => {
    const clickedDate = new Date(arg.dateStr);
    const eventsForDay = calendarEvents.filter((event) => {
      const eventDate = new Date(event.start);
      return (
        eventDate.getFullYear() === clickedDate.getFullYear() &&
        eventDate.getMonth() === clickedDate.getMonth() &&
        eventDate.getDate() === clickedDate.getDate()
      );
    });
    setSelectedDateEvents(eventsForDay);
  };

  return (
    <div className="flex h-screen">
      {/* Левая панель (25%) */}
      <div className="w-1/4 bg-gray-100 p-4 overflow-auto">
        <h2 className="text-xl font-bold mb-4">События на выбранный день</h2>
        {selectedDateEvents.length === 0 ? (
          <p>Нажмите на день календаря для просмотра событий</p>
        ) : (
          <ul>
            {selectedDateEvents.map((evt, index) => (
              <li key={index} className="mb-2 p-2 bg-white rounded shadow">
                <p className="font-semibold">{evt.extendedProps.fullDocument}</p>
                <p>Дедлайн: {evt.extendedProps.deadline}</p>
              </li>
            ))}
          </ul>
        )}

        {/* Кнопка возврата на Dashboard */}
        <div className="mt-4">
          <Link to="/dashboard" className="text-blue-500 hover:underline">
            ← Назад к Dashboard
          </Link>
        </div>
      </div>

      {/* Правая панель: Календарь (75%) */}
      <div className="w-3/4 p-4">
        {error && <p className="text-red-500">{error}</p>}
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={calendarEvents}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,dayGridWeek",
          }}
          height="75vh"
          firstDay={1}
          dateClick={handleDateClick}
          displayEventTime={false}
          eventDisplay="list-item"
        />
      </div>
    </div>
  );
};

export default CalendarPage;
