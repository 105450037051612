import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import UserContext from "../context/UserContext";

const ProtectedRoute = ({ children, allowedRoles = [] }) => {
  const { userData, isUserLoading } = useContext(UserContext);

  // Пока идёт запрос /users/me и контекст ещё не знает точно
  // залогинен ли пользователь
  if (isUserLoading) {
    console.log("ProtectedRoute: ещё грузим пользователя...");
    return <div>Загрузка...</div>;
  }

  // Если загрузка завершена, но userData===null => неавторизован
  if (!userData) {
    console.log("ProtectedRoute: неавторизован => /login");
    return <Navigate to="/login" replace />;
  }

  // Проверяем допустимые роли
  if (allowedRoles.length > 0 && !allowedRoles.includes(userData.role.trim())) {
    console.log("ProtectedRoute: роль не подходит => /404", userData.role);
    return <Navigate to="/404" replace />;
  }

  // Если всё хорошо
  console.log("ProtectedRoute: всё ок, показываем children, роль:", userData.role);
  return children;
};

export default ProtectedRoute;
