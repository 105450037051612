import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

const RegistrationPage = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    companyId: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  // Например
  const API_URL = "https://ai-back-23-production.up.railway.app/api/register";


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { firstName, lastName, email, password, companyId } = formData;
    const newErrors = {};

    // Валидация данных
    if (!firstName.trim()) newErrors.firstName = "Имя обязательно.";
    if (!lastName.trim()) newErrors.lastName = "Фамилия обязательна.";
    if (!email.trim() || !/\S+@\S+\.\S+/.test(email)) newErrors.email = "Введите корректный email.";
    if (!password) newErrors.password = "Пароль обязателен.";
    if (!companyId.trim()) newErrors.companyId = "ID компании обязателен.";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      // Подготовка данных для соответствия API
      const payload = {
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        company_id: companyId,
      };

      const response = await fetch(API_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        navigate("/login"); // Перенаправление на страницу входа
      } else if (response.status === 400) {
        const { detail } = await response.json();
        setErrors({ email: detail || "Ошибка регистрации" });
      } else {
        setErrors({ email: "Ошибка сервера. Попробуйте позже." });
      }
    } catch (error) {
      setErrors({ email: "Сервер недоступен. Попробуйте позже." });
      console.error("Ошибка:", error);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-gray-300">
      <div className="bg-gray-800 p-10 rounded-lg shadow-xl w-full max-w-lg">
        <h1 className="text-3xl font-extrabold text-white mb-6">Регистрация</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            placeholder="Имя"
            className="w-full mb-4 px-4 py-2 rounded-md bg-gray-700"
          />
          {errors.firstName && <p className="text-red-500">{errors.firstName}</p>}
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            placeholder="Фамилия"
            className="w-full mb-4 px-4 py-2 rounded-md bg-gray-700"
          />
          {errors.lastName && <p className="text-red-500">{errors.lastName}</p>}
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Email"
            className="w-full mb-4 px-4 py-2 rounded-md bg-gray-700"
          />
          {errors.email && <p className="text-red-500">{errors.email}</p>}
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            placeholder="Пароль"
            className="w-full mb-4 px-4 py-2 rounded-md bg-gray-700"
          />
          {errors.password && <p className="text-red-500">{errors.password}</p>}
          <input
            type="text"
            name="companyId"
            value={formData.companyId}
            onChange={handleInputChange}
            placeholder="ID Компании"
            className="w-full mb-4 px-4 py-2 rounded-md bg-gray-700"
          />
          {errors.companyId && <p className="text-red-500">{errors.companyId}</p>}
          <button type="submit" className="w-full bg-blue-500 py-2 rounded-md text-white">
            Зарегистрироваться
          </button>
        </form>
        <div className="mt-4 text-center">
          <p className="text-gray-400">
            Уже есть аккаунт?{" "}
            <Link to="/login" className="text-blue-400 hover:underline">
              Войдите
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegistrationPage;
