import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";

// Функция для обрезки описания (максимум 10 символов и три точки, если длиннее)
function truncateDescription(desc) {
  if (!desc) return "";
  return desc.length > 10 ? desc.slice(0, 10) + "..." : desc;
}

const CertificationOverview = () => {
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Состояния для модального окна описания
  const [modalVisible, setModalVisible] = useState(false);
  const [modalDescription, setModalDescription] = useState("");

  const fetchDocumentsStatus = async () => {
    setIsLoading(true);
    try {
      const response = await fetch("https://ai-back-23-production.up.railway.app/get-certification-status/");
      if (!response.ok) {
        throw new Error("Не удалось загрузить данные.");
      }
      const result = await response.json();
      setDocuments(result);
    } catch (err) {
      setError("Ошибка при загрузке данных с сервера.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDocumentsStatus();
  }, []);

  // По клику переходим на страницу /certification-logistics и передаём document_name, item
  const handleLineItemClick = (doc) => {
    navigate("/certification-logistics", {
      state: {
        document_name: doc.document_name,
        item: doc.item,
        status: doc.status,
      },
    });
  };

  // Отображаем статус с эмоджи
  function renderStatusWithEmoji(status) {
    switch (status) {
      case "Not Started":
        return <span className="text-red-500">❌ Not Started</span>;
      case "In Progress":
        return <span className="text-orange-500">⏳ In Progress</span>;
      case "Completed":
        return <span className="text-green-600">✅ Completed</span>;
      default:
        return <span className="text-gray-500">{status}</span>;
    }
  }

  // Модальное окно: показать полное описание
  const handleShowDescription = (desc) => {
    setModalDescription(desc || "");
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setModalDescription("");
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Certification & Logistics Overview</h1>
      {isLoading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}

      <div className="overflow-auto bg-white p-4 border rounded">
        <table className="w-full border-collapse border border-gray-300">
          <thead className="bg-gray-200">
            <tr>
              <th className="border px-4 py-2 text-left">RFQ#</th>
              <th className="border px-4 py-2 text-left">Line item#</th>
              <th className="border px-4 py-2 text-left">Description</th>
              <th className="border px-4 py-2 text-left">Status</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((doc, index) => (
              <tr
                key={index}
                className="cursor-pointer hover:bg-gray-100"
                onClick={() => handleLineItemClick(doc)}
              >
                <td className="border px-4 py-2">{doc.document_name}</td>
                <td className="border px-4 py-2">{doc.item}</td>
                <td
                  className="border px-4 py-2 text-blue-500 underline cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShowDescription(doc.description);
                  }}
                >
                  {truncateDescription(doc.description)}
                </td>
                <td className="border px-4 py-2">{renderStatusWithEmoji(doc.status)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Модальное окно для полного описания */}
      {modalVisible && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded shadow-lg p-6 w-11/12 md:w-1/2">
            <h2 className="text-xl font-bold mb-4">Full Description</h2>
            <p className="mb-4">{modalDescription}</p>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CertificationOverview;
