import React, { useContext } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  FaBars,
  FaHome,
  FaFileAlt,
  FaPaperPlane,
  FaComments,
  FaSignOutAlt,
  FaDollarSign,
  FaShoppingCart,
  FaUserCircle
} from "react-icons/fa";
import UserContext from "../context/UserContext";
import { ReactComponent as PoIcon } from "../assets/icons/po.svg";
import { ReactComponent as Dashboard } from "../assets/icons/dashboard.svg";
import { ReactComponent as RFQIcon1 } from "../assets/icons/rfq1.svg";
import { ReactComponent as Cl } from "../assets/icons/cl.svg";
import { ReactComponent as Procon } from "../assets/icons/procon.svg";
import { ReactComponent as Pricing } from "../assets/icons/price.svg";

const Sidebar = ({ isChatOpen, toggleSidebar }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userData, logout } = useContext(UserContext);

  // Если мы на главной / странице логина / регистрации, не отображаем сайдбар
  if (
    location.pathname === "/" ||
    location.pathname === "/login" ||
    location.pathname === "/register"
  ) {
    return null;
  }

  // Если userData ещё не загрузились
  if (!userData) {
    return <div>Загрузка...</div>;
  }

  // Список ссылок с ролями, которым они доступны
  const links = [
    {
      href: "/profile",
      icon: <FaUserCircle />,
      label: userData?.first_name ? userData.first_name + " " : "NoName",
      roles: ["admin", "buyer", "accountant", "buyerFull", "newbie", "observer"],
    },
    {
      href: "/dashboard",
      icon: <Dashboard className="w-6 h-6 fill-current text-white" />,
      label: "Dashboard",
      roles: ["admin", "buyer", "accountant", "buyerFull", "newbie", "observer"],
    },
    {
      href: "/rfq",
      icon: <RFQIcon1 className="w-6 h-6 fill-current text-white" />,
      label: "RFQ",
      roles: ["admin", "buyer", "buyerFull", "newbie", "observer"],
    },
    {
      href: "/certification-overview",
      icon: <Cl className="w-6 h-6 fill-current text-white" />,
      label: "Certifications",
      roles: ["admin", "buyer", "buyerFull", "newbie", "observer"],
    },
    {
      href: "/pricing-overview",
      icon: <Pricing className="w-6 h-6 fill-current text-white" />,
      label: "Pricing",
      roles: ["admin", "buyer", "buyerFull", "newbie", "observer"],
    },
    {
      href: "/procon",
      icon: <Procon className="w-6 h-6 fill-current text-white" />,
      label: "Procon",
      roles: ["admin", "buyer", "buyerFull", "newbie", "observer"],
    },
    {
      href: "/po",
      icon: <PoIcon className="w-6 h-6 fill-current text-white" />,
      label: "PO",
      roles: ["admin", "buyer", "accountant", "buyerFull", "newbie", "observer"],
    },
    {
      href: "/chat",
      icon: <FaComments />,
      label: "Chat",
      roles: ["admin", "buyer", "accountant", "buyerFull", "newbie", "observer"],
    },
    // Новая ссылка под Chat:
  ];

  // Удаляем ненужные страницы для accountant (остаётся только dashboard, chat, po)
  const accountantSpecificLinks = links.filter((link) =>
    ["dashboard", "chat", "po"].some((name) => link.href.includes(name))
  );

  const filteredLinks =
    userData.role.trim() === "accountant" ? accountantSpecificLinks : links;

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <div
      className={`fixed top-0 left-0 h-screen ${
        isChatOpen ? "w-16" : "w-64"
      } bg-gray-900 text-white p-4 flex flex-col justify-between`}
    >
      {/* Кнопка для скрытия/показа Sidebar */}
      <button
        onClick={toggleSidebar}
        className="bg-gray-700 hover:bg-gray-600 text-white p-2 rounded mb-6"
      >
        <FaBars />
      </button>

      {/* Ссылки для навигации */}
      <nav>
        <ul className="space-y-4">
          {filteredLinks.map(({ href, icon, label }) => (
            <li key={href}>
              {href === "/profile" ? (
                // Отображаем информацию о пользователе как простой div без фона и клика
                <div className="flex items-center space-x-4 p-2">
                  <span className="text-xl">{icon}</span>
                  {!isChatOpen && <span>{label}</span>}
                </div>
              ) : (
                <NavLink
                  to={href}
                  className={({ isActive }) =>
                    `flex items-center space-x-4 p-2 rounded ${
                      isActive ? "bg-gray-700" : "hover:bg-gray-700"
                    }`
                  }
                >
                  <span className="text-xl">{icon}</span>
                  {!isChatOpen && <span>{label}</span>}
                </NavLink>
              )}
            </li>
          ))}
        </ul>
      </nav>

      {/* Кнопка выхода */}
      <div className="mt-auto">
        <button
          onClick={handleLogout}
          className="flex items-center space-x-4 p-2 rounded hover:bg-red-600 w-full"
        >
          <span className="text-xl">
            <FaSignOutAlt />
          </span>
          {!isChatOpen && <span>Logout</span>}
        </button>
      </div>
    </div>
  );
};

export default Sidebar;