// src/pages/Dashboard/PoCalendarPage.js
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import UserContext from "../../context/UserContext";

/**
 * Если у вас deliv_date в формате "DD.MM.YYYY" (например, "15.03.2025"),
 * распарсим её без времени.
 * Если уже ISO (типа "2025-03-15T00:00:00"), можно упростить код.
 */
function parseDDMMYYYY(dateStr) {
  if (!dateStr) return null;
  const parts = dateStr.split(".");
  if (parts.length !== 3) return null;
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const year = parseInt(parts[2], 10);
  if (isNaN(day) || isNaN(month) || isNaN(year)) return null;
  // Создаём дату в UTC (00:00), чтобы избежать смещения на -1 день
  return new Date(Date.UTC(year, month, day));
}

/**
 * Форматируем объект Date в "YYYY-MM-DD" (без времени).
 * Если хотите другой формат, меняйте на нужный.
 */
function formatDateYYYYMMDD(dateObj) {
  if (!dateObj) return "";
  const year = dateObj.getUTCFullYear();
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getUTCDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

const PoCalendarPage = () => {
  const { authorizedFetch } = useContext(UserContext);

  // События для календаря
  const [calendarEvents, setCalendarEvents] = useState([]);
  // События за выбранную дату (отображаем слева)
  const [selectedDateEvents, setSelectedDateEvents] = useState([]);

  const [error, setError] = useState(null);

  // Загружаем PO-документы и готовим события
  useEffect(() => {
    const fetchPoDocs = async () => {
      try {
        const resp = await authorizedFetch(
          "https://ai-back-23-production.up.railway.app/get-documents-po/",
          { method: "GET" }
        );
        if (!resp.ok) {
          throw new Error("Не удалось загрузить PO документы");
        }
        const docs = await resp.json();

        // 1) Группируем по document_name, чтобы один документ
        // не появлялся несколько раз, даже если line items много.
        // Возьмём первую (или любую) deliv_date, которая нам попадётся
        // (Если нужно брать минимальную/максимальную, сделайте сравнение.)
        const uniqueDocs = {};
        docs.forEach((doc) => {
          if (!uniqueDocs[doc.document_name]) {
            uniqueDocs[doc.document_name] = doc;
          }
          // Или, при желании, сравнивайте doc.deliv_date
          // чтобы найти min/max, если документ появляется несколько раз
        });

        // Превращаем в массив
        const uniqueDocsArray = Object.values(uniqueDocs);

        // 2) Формируем события. Если нет deliv_date, пропускаем.
        const events = [];
        uniqueDocsArray.forEach((doc) => {
          if (doc.deliv_date) {
            const parsed = parseDDMMYYYY(doc.deliv_date);
            if (parsed) {
              // Для календаря нужна ISO-строка (время можно ставить T00:00:00Z)
              const isoStr = parsed.toISOString(); 
              // Для заголовка убираем любое время, оставим "YYYY-MM-DD" или 00:00
              // Выведем: "PO 4512590449 AISSAN LLP.pdf"
              // (вместо "00:00 - ..." если не хотим время)
              const shortDate = formatDateYYYYMMDD(parsed);

              events.push({
                // Пример title:
                title: `${shortDate} - ${doc.document_name}`,
                start: isoStr,
                end: isoStr,
                extendedProps: {
                  // Для левой панели
                  fullDocument: doc.document_name,
                  // "Deliv. date" без времени
                  dateStr: shortDate,
                },
              });
            }
          }
        });

        setCalendarEvents(events);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchPoDocs();
  }, [authorizedFetch]);

  // Клик по дню => показать события (в левом блоке)
  const handleDateClick = (arg) => {
    const clicked = new Date(arg.dateStr); // локальное время
    // Сравниваем только по году-месяцу-дню
    const matches = calendarEvents.filter((evt) => {
      const evtDate = new Date(evt.start); // ISO => Date
      return (
        evtDate.getUTCFullYear() === clicked.getFullYear() &&
        evtDate.getUTCMonth() === clicked.getMonth() &&
        evtDate.getUTCDate() === clicked.getDate()
      );
    });
    setSelectedDateEvents(matches);
  };

  return (
    <div className="flex h-screen">
      {/* Левая панель (1/4 экрана) */}
      <div className="w-1/4 bg-gray-100 p-4 overflow-auto">
        <h2 className="text-xl font-bold mb-4">События на выбранный день</h2>
        {selectedDateEvents.length === 0 ? (
          <p>Нажмите на день календаря для просмотра событий</p>
        ) : (
          <ul>
            {selectedDateEvents.map((evt, index) => (
              <li key={index} className="mb-2 p-2 bg-white rounded shadow">
                <p className="font-semibold">{evt.extendedProps.fullDocument}</p>
                {/* Выводим Deliv. date = YYYY-MM-DD */}
                <p>Deliv. date: {evt.extendedProps.dateStr}</p>
              </li>
            ))}
          </ul>
        )}

        {/* Кнопка возврата на PO Dashboard */}
        <div className="mt-4">
          <Link to="/podashboard" className="text-blue-500 hover:underline">
            ← Назад к PO Dashboard
          </Link>
        </div>
      </div>

      {/* Правая панель: Календарь (3/4 экрана) */}
      <div className="w-3/4 p-4">
        {error && <p className="text-red-500">{error}</p>}

        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={calendarEvents}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,dayGridWeek",
          }}
          height="75vh"
          firstDay={1}
          dateClick={handleDateClick}
          displayEventTime={false}
          eventDisplay="list-item"
        />
      </div>
    </div>
  );
};

export default PoCalendarPage;
