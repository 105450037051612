import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";

// Небольшая вспомогательная функция для отображения любого статуса с цветовой подсветкой.
function renderStatus(status) {
  if (!status || status === "Not assigned") {
    return <span className="text-red-500">❌ Not assigned</span>;
  }
  // Проверяем на "Completed" или "complited"
  if (status.toLowerCase() === "completed" || status.toLowerCase() === "complited") {
    return <span className="text-green-500">✔ {status}</span>;
  }
  // Все остальные статусы (In Progress, Under order placement, etc.) — оранжевым
  return <span className="text-orange-500">{status}</span>;
}

// Функция для обрезки описания (максимум 10 символов и три точки, если длиннее)
function truncateDescription(desc) {
  if (!desc) return "";
  return desc.length > 10 ? desc.slice(0, 10) + "..." : desc;
}

const PO = () => {
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);

  // Список документов (1-я таблица)
  const [documents, setDocuments] = useState([]);
  // Данные line items (2-я таблица)
  const [lineItems, setLineItems] = useState([]);
  // Статус каждого item (po_documents_status_item)
  const [itemStatuses, setItemStatuses] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Состояния для модального окна описания
  const [modalVisible, setModalVisible] = useState(false);
  const [modalDescription, setModalDescription] = useState("");

  // --- Новые состояния для выгрузки в Google Sheets ---
  const [selectedDocName, setSelectedDocName] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);

  // =============== 1) Получаем список PO-документов со статусами ===============
  const fetchDocumentsStatus = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch("https://ai-back-23-production.up.railway.app/get-documents-status-po/", {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      });
      if (response.ok) {
        const result = await response.json();

        /*
          --- ВАЖНО: УБИРАЕМ ДУБЛИКАТЫ, ЧТОБЫ КАЖДЫЙ DOCUMENT БЫЛ 1 РАЗ ---
          Если на бэке почему-то приходит несколько строк для одного и того же doc,
          то мы оставим только первую, например. Самый простой путь —
          сгруппировать по document_name.
        */
        const uniqueDocs = [];
        const seenNames = new Set();

        for (const doc of result) {
          if (!seenNames.has(doc.document_name)) {
            seenNames.add(doc.document_name);
            uniqueDocs.push(doc);
          }
        }
        // Теперь uniqueDocs содержит каждую document_name ровно один раз.
        setDocuments(uniqueDocs);
      } else {
        throw new Error("Не удалось получить статусы PO-документов.");
      }
    } catch (error) {
      setError("Не удалось получить PO-документы с сервера.");
    } finally {
      setIsLoading(false);
    }
  };

  // =============== 2) Загрузка line items и их статусов ===============
  const fetchLineItems = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch("https://ai-back-23-production.up.railway.app/get-documents-po/", {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      });
      if (!response.ok) {
        console.log("Response status:", response.status, response.statusText);
        const text = await response.text();
        console.log("Response body text:", text);
        throw new Error("Не удалось получить PO line items.");
      }
      const result = await response.json();

      // Загружаем статусы для items
      const statusResp = await fetch("https://ai-back-23-production.up.railway.app/get-po-item-status/", {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      });
      if (!statusResp.ok) {
        throw new Error("Не удалось получить статусы элементов PO.");
      }
      const statusData = await statusResp.json();

      result.sort((a, b) => Number(a.id) - Number(b.id));

      setLineItems(result);
      setItemStatuses(statusData);
    } catch (error) {
      setError(error.message || "Не удалось получить PO line items.");
    } finally {
      setIsLoading(false);
    }
  };

  // =============== 3) Удаление документа PO ===============
  const handleDeleteDocument = async (documentName) => {
    if (!window.confirm(`Удалить PO-документ "${documentName}"?`)) {
      return;
    }
    try {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      const response = await fetch("https://ai-back-23-production.up.railway.app/delete-po-document/", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ document_name: documentName }),
      });

      if (response.ok) {
        alert(`PO-документ "${documentName}" успешно удален.`);
        setDocuments((prevDocuments) =>
          prevDocuments.filter((doc) => doc.document_name !== documentName)
        );
        setLineItems((prevItems) =>
          prevItems.filter((item) => item.document_name !== documentName)
        );
        setItemStatuses((prev) =>
          prev.filter((st) => st.document_name !== documentName)
        );
      } else {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Не удалось удалить PO-документ.");
      }
    } catch (error) {
      setError(error.message || "Не удалось удалить PO-документ.");
    } finally {
      setIsLoading(false);
    }
  };

  // =============== 4) Клик по названию PO-документа (для buyerFull) ===============
  const handleDocumentClick = (documentName) => {
    if (userData?.role === "buyerFull") {
      navigate("/po-view", { state: { fileName: documentName } });
    } else {
      alert("У вас нет прав для доступа к этой функции PO.");
    }
  };

  // =============== 5) Клик по line item (2-я таблица) ===============
  const handleLineItemClick = (documentName, itemNumber, responsible) => {
    if (!responsible || responsible === "N/A") {
      alert("Ответственный не назначен. Вы не можете редактировать этот элемент.");
      return;
    }
    if (userData?.role === "buyer" || userData?.role === "buyerFull") {
      navigate("/po-submit", {
        state: {
          fileName: documentName,
          itemNumber: itemNumber,
        },
      });
    }
  };

  // Функция для отображения модального окна с полным описанием
  const handleShowDescription = (desc) => {
    setModalDescription(desc);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setModalDescription("");
  };

  // Функция для получения статуса item
  const getItemStatus = (document_name, item) => {
    const st = itemStatuses.find(
      (s) => s.document_name === document_name && String(s.item) === String(item)
    );
    return st ? st.status : "Not assigned";
  };

  // Вызов загрузки документов при монтировании
  useEffect(() => {
    fetchDocumentsStatus();
    fetchLineItems();
  }, []);

  // --- Функция для выгрузки в Google Sheets (Лист2) ---
  const handleDownloadGoogleSheetPo = async () => {
    if (!selectedDocName) {
      alert("Please select a document first.");
      return;
    }
    try {
      setIsDownloading(true);
      // Вызываем новый эндпоинт export-po (см. изменения в бэке)
      const resp = await fetch(
        `https://ai-back-23-production.up.railway.app/export-po?document_name=${encodeURIComponent(selectedDocName)}`,
        { method: "GET" }
      );
      if (!resp.ok) {
        throw new Error("Failed to export PO to Google Sheet.");
      }
      const blob = await resp.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = `PODocument_${selectedDocName}.xlsx`;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      alert("Ошибка при скачивании Google Sheet: " + error.message);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">PO Status Overview</h1>

      {(userData?.role === "admin" || userData?.role === "buyerFull") && (
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
          onClick={() => navigate("/po-system")}
        >
          Upload New PO
        </button>
      )}

      {isLoading && <p>Загрузка...</p>}
      {error && <p className="text-red-500">{error}</p>}

      {/* ========== 1-я таблица (Список документов) ========== */}
      <div
        className="overflow-auto bg-white p-4 border rounded mb-6"
        style={{ maxHeight: "300px" }}
      >
        <table className="w-full border-collapse border border-gray-300">
          <thead className="bg-gray-200">
            <tr>
              <th className="border px-4 py-2 text-left">PO#</th>
              <th className="border px-4 py-2 text-left">Company name</th>
              <th className="border px-4 py-2 text-left">Upload Date</th>
              <th className="border px-4 py-2 text-left">Status</th>
              {userData?.role === "buyerFull" && (
                <th className="border px-4 py-2 text-left">Action</th>
              )}
            </tr>
          </thead>
          <tbody>
            {documents.map((doc, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td
                  className={
                    userData?.role === "buyerFull"
                      ? "border px-4 py-2 cursor-pointer text-blue-500 underline"
                      : "border px-4 py-2"
                  }
                  onClick={
                    userData?.role === "buyerFull"
                      ? () => handleDocumentClick(doc.document_name)
                      : undefined
                  }
                >
                  {doc.document_name}
                </td>
                {/* Предполагаем, что в documents (doc) уже приходит company_name */}
                <td className="border px-4 py-2">
                  {doc.company_name || "—"}
                </td>
                <td className="border px-4 py-2">
                  {doc.upload_date
                    ? doc.upload_date.split("T")[0] // <-- только дата (YYYY-MM-DD)
                    : "—"}
                </td>
                <td className="border px-4 py-2">{renderStatus(doc.status)}</td>
                {userData?.role === "buyerFull" && (
                  <td className="border px-4 py-2 text-center">
                    <button
                      className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-700"
                      onClick={() => handleDeleteDocument(doc.document_name)}
                    >
                      🗑️
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* ========== Блок выбора документа и кнопка "Download to Google Sheets" ========== */}
      <div className="bg-white p-4 border rounded mb-4">
        <h2 className="font-semibold mb-2">
          Download Document Data to Google Sheets
        </h2>
        <div className="flex items-center space-x-2">
          <select
            className="border p-2 rounded"
            value={selectedDocName}
            onChange={(e) => setSelectedDocName(e.target.value)}
          >
            <option value="">-- Select Document --</option>
            {documents.map((doc) => (
              <option key={doc.document_name} value={doc.document_name}>
                {doc.document_name}
              </option>
            ))}
          </select>
          <button
            onClick={handleDownloadGoogleSheetPo}
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
            disabled={isDownloading}
          >
            {isDownloading ? "Downloading..." : "Download to Google Sheets"}
          </button>
        </div>
      </div>

      {/* ========== 2-я таблица (Список line items) ========== */}
      <div
        className="overflow-auto bg-white p-4 border rounded"
        style={{ maxHeight: "300px" }}
      >
        <table className="w-full border-collapse border border-gray-300">
          <thead className="bg-gray-200">
            <tr>
              <th className="border px-4 py-2 text-left">PO#</th>
              <th className="border px-4 py-2 text-left">Company name</th>
              <th className="border px-4 py-2 text-left">Description</th>
              <th className="border px-4 py-2 text-left">Line item#</th>
              <th className="border px-4 py-2 text-left">Status</th>
              <th className="border px-4 py-2 text-left">Responsible</th>
            </tr>
          </thead>
          <tbody>
            {lineItems.length > 0 ? (
              lineItems.map((item, index) => {
                const itemStatus = getItemStatus(item.document_name, item.item);
                return (
                  <tr
                    key={index}
                    className={
                      userData?.role === "buyer" || userData?.role === "buyerFull"
                        ? "cursor-pointer hover:bg-gray-100"
                        : ""
                    }
                    onClick={() =>
                      (userData?.role === "buyer" ||
                        userData?.role === "buyerFull") &&
                      handleLineItemClick(
                        item.document_name,
                        item.item,
                        item.responsible
                      )
                    }
                  >
                    <td className="border px-4 py-2">{item.document_name}</td>
                    <td className="border px-4 py-2">
                      {item.company_name || "—"}
                    </td>
                    <td
                      className="border px-4 py-2 cursor-pointer text-blue-500 underline"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleShowDescription(item.description);
                      }}
                    >
                      {truncateDescription(item.description)}
                    </td>
                    <td className="border px-4 py-2">{item.item}</td>
                    <td className="border px-4 py-2">
                      {renderStatus(itemStatus)}
                    </td>
                    <td className="border px-4 py-2">
                      {item.responsible || "N/A"}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="border px-4 py-2 text-center" colSpan={6}>
                  Данных PO нет
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Модальное окно для показа полного Description */}
      {modalVisible && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded shadow-lg p-6 w-11/12 md:w-1/2">
            <h2 className="text-xl font-bold mb-4">Полное описание</h2>
            <p className="mb-4">{modalDescription}</p>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded"
              onClick={handleCloseModal}
            >
              Закрыть
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PO;
