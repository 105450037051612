import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom"; // <-- Добавляем useNavigate
import UserContext from "../../context/UserContext";
import { ChevronDown, ChevronUp, Maximize2 } from "lucide-react";

// Импорт FullCalendar
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

// Импорт Recharts
import {
  BarChart,
  Bar,
  LabelList,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

// Список сокращённых месяцев (Jan..Dec)
const shortMonths = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

// Возможные года (примерный список)
const possibleYears = [
  "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"
];

const Dashboard = () => {
  const navigate = useNavigate(); // <-- Для перехода
  const { userData, authorizedFetch } = useContext(UserContext);

  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  // ====== KPI ======
  const [userKpis, setUserKpis] = useState(null);
  const [isLoadingUserKpis, setIsLoadingUserKpis] = useState(false);
  const [errorUserKpis, setErrorUserKpis] = useState(null);

  // ====== RFQ Completion Status ======
  const [rfqStats, setRfqStats] = useState([]);
  const [isLoadingTableOne, setIsLoadingTableOne] = useState(false);
  const [errorTableOne, setErrorTableOne] = useState(null);

  // ====== RFQ Tracking (все line items) ======
  const [trackingData, setTrackingData] = useState([]);
  const [isLoadingTableTwo, setIsLoadingTableTwo] = useState(false);
  const [errorTableTwo, setErrorTableTwo] = useState(null);

  // ====== General Performance ======
  const [generalPerformance, setGeneralPerformance] = useState(null);
  const [isLoadingGenPerf, setIsLoadingGenPerf] = useState(false);
  const [errorGenPerf, setErrorGenPerf] = useState(null);

  // ====== Календарь (события) ======
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [errorCalendar, setErrorCalendar] = useState(null);

  // ====== Список документов (для календаря, summary, и фильтрации) ======
  const [rfqDocs, setRfqDocs] = useState([]);

  // ====== Сводка (Summary) ======
  const [summaryData, setSummaryData] = useState({
    activeRFQ: 0,
    cumulativeRFQ: 0,
    activeLI: 0,
    cumulativeLI: 0
  });

  // ====== Сворачиваемые секции ======
  const [sectionsVisible, setSectionsVisible] = useState({
    completion: true,
    tracking: false,
    performance: true,
    calendar: false
  });
  const toggleSection = (section) => {
    setSectionsVisible((prev) => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  // Детали "In progress"
  const [showInProgressDetails, setShowInProgressDetails] = useState(false);

  // ====== Текущий месяц (короткое название) ======
  const currentMonthIndex = new Date().getMonth();
  const currentMonthShort = shortMonths[currentMonthIndex];

  // ====== Динамические месяцы для General Performance ======
  const [secondMonthGP, setSecondMonthGP] = useState(
    () => shortMonths[(currentMonthIndex + 1) % 12]
  );
  const [thirdMonthGP, setThirdMonthGP] = useState(
    () => shortMonths[(currentMonthIndex + 2) % 12]
  );
  const [fourthMonthGP, setFourthMonthGP] = useState(
    () => shortMonths[(currentMonthIndex + 3) % 12]
  );
  const [fifthMonthGP, setFifthMonthGP] = useState(
    () => shortMonths[(currentMonthIndex + 4) % 12]
  );
  const [sixthMonthGP, setSixthMonthGP] = useState(
    () => shortMonths[(currentMonthIndex + 5) % 12]
  );

  // ====== Год (выбираемый) ======
  const [yearGP, setYearGP] = useState(() =>
    new Date().getFullYear().toString()
  );

  // ====== Список всех байеров (чтобы показывать их имена даже если assigned=0) ======
  const [allBuyers, setAllBuyers] = useState([]);

  // ----------------------------------
  // 0) Fetch all Buyers
  // ----------------------------------
  useEffect(() => {
    const fetchAllBuyers = async () => {
      try {
        const resp = await authorizedFetch(
          "https://ai-back-23-production.up.railway.app/get-buyers/",
          { method: "GET" }
        );
        if (!resp.ok) {
          throw new Error("Не удалось загрузить список байеров");
        }
        const data = await resp.json();
        setAllBuyers(data); // [{id, first_name, last_name, role}, ...]
      } catch (err) {
        console.error("Failed to fetch buyers:", err);
      }
    };
    fetchAllBuyers();
  }, [authorizedFetch]);

  // ----------------------------------
  // 1) KPI
  // ----------------------------------
  useEffect(() => {
    const fetchUserKpis = async () => {
      try {
        setIsLoadingUserKpis(true);
        setErrorUserKpis(null);
        const resp = await authorizedFetch("https://ai-back-23-production.up.railway.app/get-user-kpis/", {
          method: "GET"
        });
        if (!resp.ok) {
          throw new Error("Не удалось загрузить KPI пользователя");
        }
        const data = await resp.json();
        setUserKpis(data);
      } catch (err) {
        setErrorUserKpis(err.message);
      } finally {
        setIsLoadingUserKpis(false);
      }
    };
    fetchUserKpis();
  }, [authorizedFetch]);

  // ----------------------------------
  // 2) RFQ Completion Status
  // ----------------------------------
  useEffect(() => {
    const fetchRfqStats = async () => {
      try {
        setIsLoadingTableOne(true);
        setErrorTableOne(null);
        const response = await authorizedFetch("https://ai-back-23-production.up.railway.app/get-rfq-completion-stats/", {
          method: "GET"
        });
        if (!response.ok) {
          throw new Error("Не удалось загрузить RFQ Completion Status");
        }
        const data = await response.json();
        setRfqStats(data);
      } catch (err) {
        setErrorTableOne(err.message);
      } finally {
        setIsLoadingTableOne(false);
      }
    };
    fetchRfqStats();
  }, [authorizedFetch]);

  // ----------------------------------
  // 3) RFQ Tracking & Documents (для календаря и Summary)
  // ----------------------------------
  useEffect(() => {
    const fetchTrackingData = async () => {
      try {
        setIsLoadingTableTwo(true);
        setErrorTableTwo(null);

        // a) Все статусы line items
        const statusResp = await authorizedFetch("https://ai-back-23-production.up.railway.app/get-item-status/", {
          method: "GET"
        });
        if (!statusResp.ok) {
          throw new Error("Не удалось загрузить статусы элементов (для трекинга)");
        }
        const statuses = await statusResp.json();
        setTrackingData(statuses);

        // b) Все документы
        const docsResp = await authorizedFetch("https://ai-back-23-production.up.railway.app/get-documents/", {
          method: "GET"
        });
        if (!docsResp.ok) {
          throw new Error("Не удалось загрузить список документов (для календаря и Summary)");
        }
        const docs = await docsResp.json();
        setRfqDocs(docs);

        // Формируем события для календаря (по дедлайну)
        const uniqueDocs = {};
        docs.forEach((d) => {
          if (d.rfq_deadline && !uniqueDocs[d.document_name]) {
            uniqueDocs[d.document_name] = d;
          }
        });
        const uniqueDocsArray = Object.values(uniqueDocs);
        const newCalendarEvents = uniqueDocsArray.map((d) => {
          const time = new Date(d.rfq_deadline).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit"
          });
          return {
            title: time,
            start: d.rfq_deadline,
            end: d.rfq_deadline,
            document_name: d.document_name
          };
        });
        setCalendarEvents(newCalendarEvents);

        // --- Подсчёт Summary ---
        // Активным считаем документ, у которого дедлайн > now
        let activeRFQ = 0,
          cumulativeRFQ = 0,
          activeLI = 0,
          cumulativeLI = 0;
        const now = new Date();
        const groups = {};

        docs.forEach((doc) => {
          if (!groups[doc.document_name]) {
            groups[doc.document_name] = {
              deadline: doc.rfq_deadline,
              count: 0
            };
          }
          groups[doc.document_name].count += 1;
        });

        Object.keys(groups).forEach((docName) => {
          const group = groups[docName];
          const deadlineDate = group.deadline ? new Date(group.deadline) : null;
          if (deadlineDate && deadlineDate > now) {
            activeRFQ += 1;
            activeLI += group.count;
          } else {
            cumulativeRFQ += 1;
            cumulativeLI += group.count;
          }
        });

        setSummaryData({
          activeRFQ,
          cumulativeRFQ,
          activeLI,
          cumulativeLI
        });
      } catch (err) {
        setErrorTableTwo(err.message);
        setErrorCalendar(err.message);
      } finally {
        setIsLoadingTableTwo(false);
      }
    };
    fetchTrackingData();
  }, [authorizedFetch]);

  // ----------------------------------
  // 4) General Performance
  // ----------------------------------
  useEffect(() => {
    const fetchGeneralPerformance = async () => {
      try {
        setIsLoadingGenPerf(true);
        setErrorGenPerf(null);
        const resp = await authorizedFetch("https://ai-back-23-production.up.railway.app/get-general-performance/", {
          method: "GET"
        });
        if (!resp.ok) {
          throw new Error("Не удалось загрузить General Performance");
        }
        const data = await resp.json();
        setGeneralPerformance(data);
      } catch (err) {
        setErrorGenPerf(err.message);
      } finally {
        setIsLoadingGenPerf(false);
      }
    };
    fetchGeneralPerformance();
  }, [authorizedFetch]);

  // ----------------------------------
  // Welcome modal (отображается один раз)
  // ----------------------------------
  useEffect(() => {
    if (userData) {
      const isWelcomeShown = localStorage.getItem("welcomeShown");
      if (!isWelcomeShown) {
        setShowWelcomeModal(true);
        localStorage.setItem("welcomeShown", "true");
      }
    }
  }, [userData]);

  const handleWelcomeClose = () => {
    setShowWelcomeModal(false);
  };

  // --------------------------
  // Вспомогательные функции
  // --------------------------
  // safeGet: получить значение из generalPerformance по ключу месяца
  const safeGet = (monthKey, field) => {
    if (!generalPerformance) return 0;
    if (!generalPerformance[monthKey]) return 0;
    return generalPerformance[monthKey][field] ?? 0;
  };

  // Функция: рассчитываем "Lost opportunity" = Total LI - Quotation loaded to Procon
  // (УЖЕ учтено, что в бэке считаются line items с просроченным дедлайном)
  const getLostOpportunity = (monthKey) => {
    const totalLines = safeGet(monthKey, "lines");
    const loaded = safeGet(monthKey, "quotation_loaded_procon");
    return totalLines - loaded;
  };

  // --------------------------
  // Подготовка данных для диаграммы "Active RFQ allocation"
  // --------------------------
  const barChartData = React.useMemo(() => {
    if (!trackingData.length || !allBuyers.length) return [];

    const now = new Date();
    const buyerCount = {};

    // 1) Инициализируем buyerCount = 0 для всех байеров (даже если ничего не assigned)
    allBuyers.forEach((b) => {
      if (b.first_name) {
        buyerCount[b.first_name.trim()] = 0;
      }
    });

    // 2) Отбираем активные line items (deadline > now)
    const activeItems = trackingData.filter((li) => {
      if (!li.rfq_deadline) return false;
      const dd = new Date(li.rfq_deadline);
      return dd > now;
    });

    // 3) Инкремент, если responsible совпадает
    activeItems.forEach((li) => {
      if (li.responsible && li.responsible.trim() !== "N/A") {
        const respKey = li.responsible.trim();
        if (respKey in buyerCount) {
          buyerCount[respKey] += 1;
        } else {
          buyerCount[respKey] = (buyerCount[respKey] || 0) + 1;
        }
      }
    });

    // 4) Формируем массив для диаграммы, сохраняя порядок байеров из allBuyers
    const result = allBuyers.map((b) => {
      const buyerKey = b.first_name ? b.first_name.trim() : "";
      const assigned = buyerCount[buyerKey] || 0;
      // Для Y–оси используем first_name (или first_name + last_name)
      const fullName = b.last_name
        ? b.first_name.trim()
        : b.first_name;
      return {
        name: fullName,
        assigned
      };
    });

    return result;
  }, [trackingData, allBuyers]);

  // Извлекаем из summaryData
  const { activeRFQ, cumulativeRFQ, activeLI, cumulativeLI } = summaryData;

  // Если userData ещё не прогрузился
  if (!userData) {
    return <div>Загрузка пользователя...</div>;
  }

  return (
    <div className="container mx-auto p-4 space-y-4 relative">
      {/* ===== КНОПКА переключения на /podashboard ===== */}
      <div className="absolute top-4 right-4 z-10">
        <button
          onClick={() => navigate("/podashboard")}
          className="px-5 py-2.5 font-semibold rounded-md bg-pink-600 text-white shadow-lg hover:bg-pink-500 transition-transform transform hover:-translate-y-0.5 active:translate-y-0"
        >
          Go to PO Dashboard
        </button>
      </div>

      {/* ===== Welcome Modal ===== */}
      {showWelcomeModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h1 className="text-2xl font-bold mb-4">
              Добро пожаловать, {userData.first_name}!
            </h1>
            <button
              onClick={handleWelcomeClose}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Продолжить
            </button>
          </div>
        </div>
      )}

      <h1 className="text-3xl font-bold mb-6">RFQ Dashboard</h1>

      {/* ===== Summary + Calendar ===== */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        {/* Левая колонка: Summary + диаграмма Active RFQ allocation */}
        <div className="bg-white shadow rounded-lg p-4">
          <h2 className="text-xl font-semibold mb-4">Summary</h2>
          <table className="min-w-full">
            <thead>
              <tr>
                <th></th>
                <th className="text-center">Active</th>
                <th className="text-center">Cumulative</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="font-medium">Total RFQ</td>
                <td className="text-center">{activeRFQ}</td>
                <td className="text-center">{cumulativeRFQ}</td>
              </tr>
              <tr>
                <td className="font-medium">Line items</td>
                <td className="text-center">{activeLI}</td>
                <td className="text-center">{cumulativeLI}</td>
              </tr>
            </tbody>
          </table>

          <h3 className="text-lg font-semibold mt-6">Active RFQ allocation</h3>
          {!barChartData.length && (
            <p className="text-gray-500 mt-2">Нет данных для диаграммы.</p>
          )}
          {barChartData.length > 0 && (
            <div style={{ width: "100%", height: 300, marginTop: "1rem" }}>
              <ResponsiveContainer>
                <BarChart
                  layout="vertical"
                  data={barChartData}
                  margin={{ top: 20, right: 20, bottom: 20, left: 60 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" />
                  <YAxis dataKey="name" type="category" />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="assigned" fill="#f97316" name="Assigned">
                    <LabelList dataKey="assigned" position="center" fill="white" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>

        {/* Правая колонка: Calendar */}
        <div className="bg-white shadow rounded-lg p-4 relative">
          <h2 className="text-xl font-semibold mb-4">RFQ Calendar</h2>
          {errorCalendar && <p className="text-red-500">{errorCalendar}</p>}
          <div className="absolute top-2 right-2">
            <Link to="/calendar">
              <Maximize2 className="text-blue-500 hover:text-blue-700" size={24} />
            </Link>
          </div>
          <div
            style={{
              width: "100%",
              maxWidth: "700px",
              margin: "0 auto",
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: "8px"
            }}
          >
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              events={calendarEvents}
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,dayGridWeek"
              }}
              allDaySlot={true}
              height="450px"
              firstDay={1}
              displayEventTime={false}
              eventDisplay="list-item"
              eventClick={(info) => {
                alert(`RFQ Document: ${info.event.extendedProps.document_name}`);
              }}
            />
          </div>
        </div>
      </div>

      {/* ===== RFQ Completion Status ===== */}
      <div className="bg-white shadow rounded-lg">
        <div
          className="flex justify-between items-center p-4 cursor-pointer bg-gray-100"
          onClick={() => toggleSection("completion")}
        >
          <h2 className="text-xl font-semibold">RFQ Completion Status</h2>
          {sectionsVisible.completion ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </div>
        {sectionsVisible.completion && (
          <div className="p-4 overflow-x-auto">
            {isLoadingTableOne && <p>Загрузка данных...</p>}
            {errorTableOne && <p className="text-red-500">{errorTableOne}</p>}
            {!isLoadingTableOne && !errorTableOne && (
              <table className="min-w-full bg-white border border-gray-200">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="border px-4 py-2">RFQ</th>
                    <th className="border px-4 py-2">RFQ Received Date</th>
                    <th className="border px-4 py-2">RFQ Deadline</th>
                    <th className="border px-4 py-2">Total Line Items</th>
                    <th className="border px-4 py-2">Not started</th>
                    <th className="border px-4 py-2">In Progress</th>
                    <th className="border px-4 py-2">Completed</th>
                  </tr>
                </thead>
                <tbody>
                  {rfqStats.length > 0 ? (
                    rfqStats.map((row, index) => (
                      <tr key={index}>
                        <td className="border px-4 py-2 font-medium">
                          {row.document_name}
                        </td>
                        <td className="border px-4 py-2">
                          {row.rfq_received_dd ? row.rfq_received_dd : "N/A"}
                        </td>
                        <td className="border px-4 py-2">
                          {row.rfq_deadline
                            ? row.rfq_deadline.replace("T", " ").slice(0, 16)
                            : "N/A"}
                        </td>
                        <td className="border px-4 py-2">{row.total_items}</td>
                        <td className="border px-4 py-2 bg-red-50">
                          {row.not_started}
                        </td>
                        <td className="border px-4 py-2 bg-blue-50">
                          {row.in_progress ?? 0}
                        </td>
                        <td className="border px-4 py-2 bg-green-50">
                          {row.completed}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={7}
                        className="border px-4 py-2 text-center text-gray-500"
                      >
                        Нет данных для отображения.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>

      {/* ===== General Performance ===== */}
      <div className="bg-white shadow rounded-lg">
        <div
          className="flex justify-between items-center p-4 cursor-pointer bg-gray-100"
          onClick={() => toggleSection("performance")}
        >
          <h2 className="text-xl font-semibold">General Performance</h2>
          {sectionsVisible.performance ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </div>
        {sectionsVisible.performance && (
          <div className="p-4 overflow-x-auto">
            {isLoadingGenPerf && <p>Загрузка данных...</p>}
            {errorGenPerf && <p className="text-red-500">{errorGenPerf}</p>}
            {!isLoadingGenPerf && !errorGenPerf && generalPerformance && (
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                  <thead className="bg-gray-200">
                    <tr>
                      <th className="border px-4 py-2">RFQ</th>
                      <th className="border px-4 py-2 w-30 text-center">
                        {currentMonthShort}
                      </th>
                      <th className="border px-4 py-2 w-30 text-center">
                        <select
                          value={secondMonthGP}
                          onChange={(e) => setSecondMonthGP(e.target.value)}
                          className="border p-1 rounded"
                        >
                          {shortMonths.map((m) => (
                            <option key={m} value={m}>
                              {m}
                            </option>
                          ))}
                        </select>
                      </th>
                      <th className="border px-4 py-2 w-30 text-center">
                        <select
                          value={thirdMonthGP}
                          onChange={(e) => setThirdMonthGP(e.target.value)}
                          className="border p-1 rounded"
                        >
                          {shortMonths.map((m) => (
                            <option key={m} value={m}>
                              {m}
                            </option>
                          ))}
                        </select>
                      </th>
                      <th className="border px-4 py-2 w-30 text-center">
                        <select
                          value={fourthMonthGP}
                          onChange={(e) => setFourthMonthGP(e.target.value)}
                          className="border p-1 rounded"
                        >
                          {shortMonths.map((m) => (
                            <option key={m} value={m}>
                              {m}
                            </option>
                          ))}
                        </select>
                      </th>
                      <th className="border px-4 py-2 w-30 text-center">
                        <select
                          value={fifthMonthGP}
                          onChange={(e) => setFifthMonthGP(e.target.value)}
                          className="border p-1 rounded"
                        >
                          {shortMonths.map((m) => (
                            <option key={m} value={m}>
                              {m}
                            </option>
                          ))}
                        </select>
                      </th>
                      <th className="border px-4 py-2 w-30 text-center">
                        <select
                          value={sixthMonthGP}
                          onChange={(e) => setSixthMonthGP(e.target.value)}
                          className="border p-1 rounded"
                        >
                          {shortMonths.map((m) => (
                            <option key={m} value={m}>
                              {m}
                            </option>
                          ))}
                        </select>
                      </th>
                      <th className="border px-4 py-2 w-30 text-center">
                        <select
                          value={yearGP}
                          onChange={(e) => setYearGP(e.target.value)}
                          className="border p-1 rounded"
                        >
                          {possibleYears.map((y) => (
                            <option key={y} value={y}>
                              {y}
                            </option>
                          ))}
                        </select>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* 1) Total RFQ */}
                    <tr className="hover:bg-gray-100">
                      <td className="border px-4 py-2">Total RFQ</td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(currentMonthShort, "total_rfq") || 0}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(secondMonthGP, "total_rfq") || 0}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(thirdMonthGP, "total_rfq") || 0}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(fourthMonthGP, "total_rfq") || 0}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(fifthMonthGP, "total_rfq") || 0}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(sixthMonthGP, "total_rfq") || 0}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(yearGP, "total_rfq") || 0}
                      </td>
                    </tr>

                    {/* 2) Total LI */}
                    <tr className="hover:bg-gray-100">
                      <td className="border px-4 py-2">Total LI</td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(currentMonthShort, "lines") || 0}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(secondMonthGP, "lines") || 0}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(thirdMonthGP, "lines") || 0}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(fourthMonthGP, "lines") || 0}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(fifthMonthGP, "lines") || 0}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(sixthMonthGP, "lines") || 0}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(yearGP, "lines") || 0}
                      </td>
                    </tr>

                    {/* 3) Quotation loaded to Procon */}
                    <tr className="hover:bg-gray-100 bg-green-100">
                      <td className="border px-4 py-2">
                        Quotation loaded to Procon
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(currentMonthShort, "quotation_loaded_procon") || 0}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(secondMonthGP, "quotation_loaded_procon") || 0}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(thirdMonthGP, "quotation_loaded_procon") || 0}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(fourthMonthGP, "quotation_loaded_procon") || 0}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(fifthMonthGP, "quotation_loaded_procon") || 0}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(sixthMonthGP, "quotation_loaded_procon") || 0}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(yearGP, "quotation_loaded_procon") || 0}
                      </td>
                    </tr>

                    {/* 4) In progress (раскрывающийся блок) */}
                    <tr
                      className="hover:bg-gray-100 bg-yellow-100 cursor-pointer"
                      onClick={() => setShowInProgressDetails((prev) => !prev)}
                    >
                      <td className="border px-4 py-2 flex items-center">
                        In progress
                        {showInProgressDetails ? (
                          <ChevronUp className="ml-2" size={16} />
                        ) : (
                          <ChevronDown className="ml-2" size={16} />
                        )}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(currentMonthShort, "in_progress") || 0}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(secondMonthGP, "in_progress") || 0}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(thirdMonthGP, "in_progress") || 0}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(fourthMonthGP, "in_progress") || 0}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(fifthMonthGP, "in_progress") || 0}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(sixthMonthGP, "in_progress") || 0}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {safeGet(yearGP, "in_progress") || 0}
                      </td>
                    </tr>
                    {/* Если нужно раскрыть «In progress» детально: */}
                    {showInProgressDetails && (
                      <>
                        <tr className="hover:bg-gray-50">
                          <td className="border px-4 py-2 pl-8">
                            Not assigned
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(currentMonthShort, "not_assigned")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(secondMonthGP, "not_assigned")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(thirdMonthGP, "not_assigned")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(fourthMonthGP, "not_assigned")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(fifthMonthGP, "not_assigned")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(sixthMonthGP, "not_assigned")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(yearGP, "not_assigned")}
                          </td>
                        </tr>

                        {/* !!! Новые статусы: Price requested, KIM Certificate, etc. */}
                        <tr className="hover:bg-gray-50">
                          <td className="border px-4 py-2 pl-8">
                            Price requested
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(currentMonthShort, "price_requested")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(secondMonthGP, "price_requested")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(thirdMonthGP, "price_requested")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(fourthMonthGP, "price_requested")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(fifthMonthGP, "price_requested")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(sixthMonthGP, "price_requested")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(yearGP, "price_requested")}
                          </td>
                        </tr>
                        <tr className="hover:bg-gray-50">
                          <td className="border px-4 py-2 pl-8">
                            KIM Certificate
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(currentMonthShort, "kim_certificate")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(secondMonthGP, "kim_certificate")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(thirdMonthGP, "kim_certificate")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(fourthMonthGP, "kim_certificate")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(fifthMonthGP, "kim_certificate")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(sixthMonthGP, "kim_certificate")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(yearGP, "kim_certificate")}
                          </td>
                        </tr>
                        <tr className="hover:bg-gray-50">
                          <td className="border px-4 py-2 pl-8">
                            No price found
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(currentMonthShort, "no_price_found")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(secondMonthGP, "no_price_found")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(thirdMonthGP, "no_price_found")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(fourthMonthGP, "no_price_found")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(fifthMonthGP, "no_price_found")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(sixthMonthGP, "no_price_found")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(yearGP, "no_price_found")}
                          </td>
                        </tr>
                        <tr className="hover:bg-gray-50">
                          <td className="border px-4 py-2 pl-8">
                            Vendors long response
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(currentMonthShort, "vendors_long_response")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(secondMonthGP, "vendors_long_response")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(thirdMonthGP, "vendors_long_response")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(fourthMonthGP, "vendors_long_response")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(fifthMonthGP, "vendors_long_response")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(sixthMonthGP, "vendors_long_response")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(yearGP, "vendors_long_response")}
                          </td>
                        </tr>
                        <tr className="hover:bg-gray-50">
                          <td className="border px-4 py-2 pl-8">
                            Historical price is low than current
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(currentMonthShort, "historical_price_is_low_than_current")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(secondMonthGP, "historical_price_is_low_than_current")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(thirdMonthGP, "historical_price_is_low_than_current")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(fourthMonthGP, "historical_price_is_low_than_current")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(fifthMonthGP, "historical_price_is_low_than_current")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(sixthMonthGP, "historical_price_is_low_than_current")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(yearGP, "historical_price_is_low_than_current")}
                          </td>
                        </tr>

                        {/* Старые статусы продолжим */}
                        <tr className="hover:bg-gray-50">
                          <td className="border px-4 py-2 pl-8">
                            Material under search
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(currentMonthShort, "material_under_search")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(secondMonthGP, "material_under_search")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(thirdMonthGP, "material_under_search")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(fourthMonthGP, "material_under_search")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(fifthMonthGP, "material_under_search")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(sixthMonthGP, "material_under_search")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(yearGP, "material_under_search")}
                          </td>
                        </tr>
                        <tr className="hover:bg-gray-50">
                          <td className="border px-4 py-2 pl-8">
                            C&L calculation and dates
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(currentMonthShort, "c_and_l_calculation_and_dates")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(secondMonthGP, "c_and_l_calculation_and_dates")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(thirdMonthGP, "c_and_l_calculation_and_dates")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(fourthMonthGP, "c_and_l_calculation_and_dates")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(fifthMonthGP, "c_and_l_calculation_and_dates")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(sixthMonthGP, "c_and_l_calculation_and_dates")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(yearGP, "c_and_l_calculation_and_dates")}
                          </td>
                        </tr>
                        <tr className="hover:bg-gray-50">
                          <td className="border px-4 py-2 pl-8">
                            Quote price calculation
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(currentMonthShort, "quote_price_calculation")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(secondMonthGP, "quote_price_calculation")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(thirdMonthGP, "quote_price_calculation")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(fourthMonthGP, "quote_price_calculation")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(fifthMonthGP, "quote_price_calculation")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(sixthMonthGP, "quote_price_calculation")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(yearGP, "quote_price_calculation")}
                          </td>
                        </tr>
                        <tr className="hover:bg-gray-50">
                          <td className="border px-4 py-2 pl-8">
                            To be loaded to Procon
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(currentMonthShort, "to_be_loaded_procon")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(secondMonthGP, "to_be_loaded_procon")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(thirdMonthGP, "to_be_loaded_procon")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(fourthMonthGP, "to_be_loaded_procon")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(fifthMonthGP, "to_be_loaded_procon")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(sixthMonthGP, "to_be_loaded_procon")}
                          </td>
                          <td className="border px-4 py-2 w-30 text-center">
                            {safeGet(yearGP, "to_be_loaded_procon")}
                          </td>
                        </tr>
                      </>
                    )}

                    {/* 5) Lost opportunity = Total LI - Quotation loaded to Procon */}
                    <tr className="hover:bg-gray-100 bg-red-100">
                      <td className="border px-4 py-2">Lost opportunity</td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {getLostOpportunity(currentMonthShort)}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {getLostOpportunity(secondMonthGP)}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {getLostOpportunity(thirdMonthGP)}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {getLostOpportunity(fourthMonthGP)}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {getLostOpportunity(fifthMonthGP)}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {getLostOpportunity(sixthMonthGP)}
                      </td>
                      <td className="border px-4 py-2 w-30 text-center">
                        {getLostOpportunity(yearGP)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </div>

      {/* ===== RFQ Tracking ===== */}
      <div className="bg-white shadow rounded-lg">
        <div
          className="flex justify-between items-center p-4 cursor-pointer bg-gray-100"
          onClick={() => toggleSection("tracking")}
        >
          <h2 className="text-xl font-semibold">RFQ Tracking</h2>
          {sectionsVisible.tracking ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </div>
        {sectionsVisible.tracking && (
          <div
            className={`p-4 overflow-x-auto ${
              trackingData.length > 5 ? "max-h-96 overflow-y-auto" : ""
            }`}
          >
            {isLoadingTableTwo && <p>Загрузка данных...</p>}
            {errorTableTwo && <p className="text-red-500">{errorTableTwo}</p>}
            {!isLoadingTableTwo && !errorTableTwo && (
              <table className="min-w-full bg-white border border-gray-200">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="border px-4 py-2">RFQ</th>
                    <th className="border px-4 py-2">Line Item</th>
                    <th className="border px-4 py-2">Due date</th>
                    <th className="border px-4 py-2">Status</th>
                    <th className="border px-4 py-2">Responsible</th>
                  </tr>
                </thead>
                <tbody>
                  {trackingData.length > 0 ? (
                    trackingData.map((row, index) => {
                      // При рендере даты оставляем только 'YYYY-MM-DD'
                      let shortDate = "N/A";
                      if (row.rfq_deadline) {
                        shortDate = row.rfq_deadline.slice(0, 10);
                      }
                      return (
                        <tr key={index}>
                          <td className="border px-4 py-2 font-medium">
                            {row.document_name}
                          </td>
                          <td className="border px-4 py-2">{row.item}</td>
                          <td className="border px-4 py-2">{shortDate}</td>
                          <td className="border px-4 py-2">{row.status}</td>
                          <td className="border px-4 py-2">
                            {row.responsible || "N/A"}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan={5}
                        className="border px-4 py-2 text-center text-gray-500"
                      >
                        Нет данных для отображения.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
