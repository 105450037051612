import React from "react";
import { Link } from "react-router-dom"; // If using React Router

const NotFound = () => {
  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h1 style={styles.title}>404</h1>
        <p style={styles.message}>Oops! The page you're looking for doesn't exist.</p>
        <Link to="/dashboard" style={styles.link}>
          <button style={styles.button}>Go Home</button>
        </Link>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    height: "100vh",
    backgroundColor: "#f8f9fa",
    color: "#343a40",
  },
  content: {
    marginBottom: "20px",
  },
  title: {
    fontSize: "96px",
    fontWeight: "bold",
    margin: "0",
  },
  message: {
    fontSize: "20px",
    margin: "10px 0",
  },
  link: {
    textDecoration: "none",
  },
  button: {
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    padding: "10px 20px",
    cursor: "pointer",
    fontSize: "16px",
  },
  buttonHover: {
    backgroundColor: "#0056b3",
  },
  imageContainer: {
    marginTop: "20px",
  },
  image: {
    maxWidth: "100%",
    height: "auto",
    borderRadius: "10px",
  },
};

export default NotFound;
