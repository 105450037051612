"use client";

import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowLeftFromLine } from "lucide-react";
import UserContext from "../../context/UserContext";

export default function RFQView() {
  const location = useLocation();
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);

  // --------------------- Состояния ---------------------
  const [rfqs, setRFQs] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modalContent, setModalContent] = useState(null); // Модалка длинного текста
  const uploadedFile = location.state?.fileName || "No file uploaded";
  const [currentDocument, setCurrentDocument] = useState(
    location.state?.fileName || null
  );

  // Редактирование таблицы
  const [editedData, setEditedData] = useState({});
  const [changesModal, setChangesModal] = useState(null);

  // Модалка дат
  const [showDatesModal, setShowDatesModal] = useState(false);

  // Даты: 
  // - RFQ Received DD (type="date")
  // - RFQ Deadline (type="datetime-local")
  const [datesForm, setDatesForm] = useState({
    rfq_received_dd: "",
    rfq_deadline: "",
  });

  // Флаг «всё сохранено, можно сабмитить»
  const [isSaved, setIsSaved] = useState(false);

  const isDatesSet = Boolean(datesForm.rfq_received_dd && datesForm.rfq_deadline);

  // Список ответственных
  const [responsibleOptions] = useState([
    { id: 1, first_name: "Тамирлан" },
    { id: 2, first_name: "Саламат" },
    { id: 3, first_name: "Жанарыс" },
    { id: 4, first_name: "Салтанат" },
    { id: 5, first_name: "Алишер" },
    { id: 6, first_name: "Беласар" },
    { id: 7, first_name: "Салават" },
    { id: 8, first_name: "Римма" },
  ]);

  // --------------------- Определяем все колонки для таблицы ---------------------
  // Добавляем width для конкретных столбцов:
  const allColumns = [
    { label: "RFQ#", key: "rfq_number", width: "850px" },
    { label: "RFQ Created By", key: "rfq_created_by", width: "220px" },
    { label: "Item", key: "item", width: "100px" },
    { label: "Material / Service", key: "material_service", width: "100px" },
    { label: "Description", key: "description", width: "30px" },
    // Тут выделяем Responsible (шире + будет другой фон)
    { label: "Responsible", key: "responsible", width: "100px" },
    { label: "Manufacturer", key: "manufacturer", width: "120px" },
    { label: "Part number", key: "manufacturer_part_number", width: "110px" },
    { label: "Qty", key: "qty", width: "400px" },
    // Base Unit – сделаем 60px, потому что там всего 3 буквы
    { label: "Base Unit", key: "base_unit", width: "60px" },
    { label: "Delivery to", key: "delivery_to", width: "130px" },
    { label: "Certificate", key: "certificate", width: "140px" },
    { label: "Certificate of conformity", key: "certificate_of_conformity", width: "140px" },
    { label: "Description (long)", key: "description_long", width: "220px" },
  ];

  // --------------------- Модалки ---------------------
  const handleCellClick = (fullText) => setModalContent(fullText);
  const closeModal = () => setModalContent(null);
  const closeChangesModal = () => setChangesModal(null);
  const openDatesModal = () => setShowDatesModal(true);
  const closeDatesModal = () => setShowDatesModal(false);

  // --------------------- Загрузка PDF ---------------------
  const handlePDFUpload = async (event) => {
    if (userData?.role !== "admin" && userData?.role !== "buyerFull") {
      alert("Вы не имеете права загружать документы.");
      return;
    }
    const file = event.target.files?.[0];
    if (!file) return;

    setIsLoading(true);
    setError(null);

    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch("https://ai-back-23-production.up.railway.app/process-pdf/", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.detail || "Не удалось обработать PDF.");
        return;
      }

      setCurrentDocument(file.name);
      alert(`Файл ${file.name} загружен и получил статус "Not assigned".`);
      fetchData(file.name);
    } catch (err) {
      setError("Ошибка при загрузке файла.");
    } finally {
      setIsLoading(false);
    }
  };

  // --------------------- Загрузка данных ---------------------
  const fetchData = async (documentName) => {
    setIsLoading(true);
    setError(null);
    try {
      const url = documentName
        ? `https://ai-back-23-production.up.railway.app/get-documents/?document_name=${encodeURIComponent(
            documentName
          )}`
        : "https://ai-back-23-production.up.railway.app/get-documents/";

      const resp = await fetch(url);
      const data = await resp.json();

      if (!resp.ok) {
        throw new Error(data.detail || "Не удалось получить данные.");
      }

      // сортируем по item
      data.sort((a, b) => Number(a.item) - Number(b.item));
      setRFQs(data);
      setEditedData({});
      setIsSaved(false);
    } catch (err) {
      setError("Ошибка при запросе к БД (RFQ).");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentDocument) {
      fetchData(currentDocument);
    }
  }, [currentDocument]);

  // --------------------- Редактирование ячеек ---------------------
  const handleCellEdit = (rowIndex, columnKey, newValue) => {
    const oldValue = rfqs[rowIndex]?.[columnKey] || "";
    setEditedData((prev) => {
      const rowEdits = prev[rowIndex] || {};
      return {
        ...prev,
        [rowIndex]: {
          ...rowEdits,
          [columnKey]: { oldValue, newValue },
        },
      };
    });
  };

  // --------------------- Сохранение изменений ---------------------
  const handleSaveChanges = async () => {
    if (!Object.keys(editedData).length) {
      alert("Нет изменений для сохранения.");
      return;
    }

    let changesText = "";
    const updatesForServer = [];

    for (const rowIndexStr of Object.keys(editedData)) {
      const rowIndex = parseInt(rowIndexStr, 10);
      const rowEdits = editedData[rowIndex];
      const itemVal = rfqs[rowIndex]?.item || "";

      for (const colKey of Object.keys(rowEdits)) {
        const { oldValue, newValue } = rowEdits[colKey];
        if (newValue !== oldValue) {
          changesText += `Строка ${rowIndex + 1}, "${colKey}": "${oldValue}" -> "${newValue}"\n`;
          updatesForServer.push({
            rowIndex,
            item: itemVal,
            columnKey: colKey,
            oldValue,
            newValue,
          });
        }
      }
    }

    if (!changesText.trim()) {
      alert("Нет изменений.");
      return;
    }
    setChangesModal(changesText);

    try {
      const resp = await fetch("https://ai-back-23-production.up.railway.app/update-rfqsystem-data/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          document_name: currentDocument,
          updates: updatesForServer,
        }),
      });
      if (!resp.ok) {
        throw new Error("Не удалось сохранить изменения (RFQ).");
      }

      alert("Изменения успешно сохранены!");
      setIsSaved(true);

      // Обновляем локально
      const updated = [...rfqs];
      for (const upd of updatesForServer) {
        const row = updated[upd.rowIndex];
        if (row) {
          row[upd.columnKey] = upd.newValue;
        }
      }
      setRFQs(updated);
      setEditedData({});
      setChangesModal(null);
    } catch (err) {
      alert("Ошибка при сохранении: " + err.message);
    }
  };

  // --------------------- Установка дат ---------------------
  const handleDatesFormChange = (e) => {
    const { name, value } = e.target;
    setDatesForm((prev) => ({ ...prev, [name]: value }));
  };
  const handleSaveDates = async () => {
    if (!datesForm.rfq_received_dd || !datesForm.rfq_deadline) {
      alert("Заполните обе даты!");
      return;
    }

    try {
      const resp = await fetch("https://ai-back-23-production.up.railway.app/update-rfq-dates/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          document_name: currentDocument,
          rfq_received_dd: datesForm.rfq_received_dd,
          rfq_deadline: datesForm.rfq_deadline,
        }),
      });
      if (!resp.ok) {
        const data = await resp.json();
        throw new Error(data.detail || "Ошибка при сохранении дат.");
      }
      alert("Даты успешно сохранены!");
      setShowDatesModal(false);
    } catch (err) {
      alert("Ошибка: " + err.message);
    }
  };

  // --------------------- Submit ---------------------
  const handleSubmit = () => {
    if (!isSaved) {
      alert("Сначала сохраните изменения (Save Changes).");
      return;
    }
    if (!isDatesSet) {
      alert("Сначала укажите обе даты (RFQ Received DD и RFQ Deadline).");
      return;
    }
    alert("RFQ System: Submitted!");
  };

  // --------------------- Рендер ---------------------
  return (
    <div className="min-h-screen p-4 bg-white">
      {/* Заголовок, кнопки, ... */}
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">RFQ check, assign and date set</h1>
        <div className="flex gap-2">
          <button
            className="flex items-center bg-gray-500 text-white px-4 py-2 rounded"
            onClick={() => navigate("/rfq")}
          >
            <ArrowLeftFromLine className="h-4 w-4 mr-2" />
            back to RFQ Status Overview
          </button>
        </div>
      </div>

      {/* Загрузка PDF (только для buyerFull/admin) */}
      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">
          Choosen RFQ: {uploadedFile}
        </label>
      </div>

      {/* Кнопки: SetDates / Save Changes */}
      <div className="mb-4 flex flex-wrap gap-2">
        <button
          onClick={openDatesModal}
          className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded"
          disabled={!currentDocument || isLoading}
        >
          Set RFQ Dates
        </button>
        <button
          onClick={handleSaveChanges}
          className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
          disabled={!currentDocument || isLoading || !Object.keys(editedData).length}
        >
          Save Changes
        </button>
      </div>

      {/* Индикатор загрузки */}
      {isLoading && (
        <div className="flex justify-center items-center mt-6">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
        </div>
      )}

      {/* Таблица с горизонтальным и вертикальным скроллом */}
      {!isLoading && (
        <div className="mt-4 border rounded-md bg-white">
          <div
            className="overflow-auto"
            style={{
              maxHeight: "600px",  // вертикальная прокрутка
              maxWidth: "100000px",    // если таблица шире, пойдёт горизонталь
              overflowX: "auto",
              overflowY: "auto",
            }}
          >
            <table
              className="table-auto text-sm border-collapse"
              style={{ minWidth: "1300px" }}
            >
              <thead className="bg-gray-100 sticky top-0">
                <tr>
                  {allColumns.map((col) => (
                    <th
                      key={col.key}
                      className="px-2 py-1 font-semibold text-left text-xs border-b"
                      style={{
                        width: col.width || "auto",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {col.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rfqs.map((rfq, rowIndex) => (
                  <tr key={rowIndex} className="hover:bg-gray-50">
                    {allColumns.map((col) => {
                      const columnKey = col.key;
                      const cellValue = rfq[columnKey] || "";
                      const editedValue =
                        editedData[rowIndex]?.[columnKey]?.newValue ?? null;
                      const displayValue = editedValue ?? cellValue;

                      // Если Responsible -> выделяем цветом
                      if (columnKey === "responsible") {
                        return (
                          <td
                            key={columnKey}
                            className="border-b align-top text-xs px-2 py-1"
                            style={{
                              width: col.width,
                              verticalAlign: "top",
                              backgroundColor: "#2026f5",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <select
                              className="border border-gray-300 rounded px-1 py-0.5 text-xs"
                              value={displayValue}
                              onChange={(e) =>
                                handleCellEdit(rowIndex, columnKey, e.target.value)
                              }
                            >
                              <option value="">- No Responsible -</option>
                              {responsibleOptions.map((user) => {
                                const fullName = `${user.first_name}`;
                                return (
                                  <option key={user.id} value={fullName}>
                                    {fullName}
                                  </option>
                                );
                              })}
                            </select>
                          </td>
                        );
                      } else {
                        // Иначе обычный инпут
                        const isLongDesc = columnKey === "description_long";
                        return (
                          <td
                            key={columnKey}
                            className="border-b text-xs px-2 py-1"
                            style={{
                              width: col.width,
                              verticalAlign: "top",
                              whiteSpace: isLongDesc ? "pre-wrap" : "nowrap",
                            }}
                          >
                            <input
                              type="text"
                              className="w-full border border-gray-300 rounded px-1 py-0.5 text-xs"
                              style={{ cursor: "pointer" }}
                              value={displayValue}
                              onChange={(e) =>
                                handleCellEdit(rowIndex, columnKey, e.target.value)
                              }
                              onClick={() => {
                                if (cellValue.length > 100) {
                                  handleCellClick(cellValue);
                                }
                              }}
                            />
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Модалка длинного текста */}
      {modalContent && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg w-[90%] max-w-2xl">
            <h2 className="text-xl font-bold mb-4">Полный текст</h2>
            <p className="text-sm text-gray-800 whitespace-pre-wrap">{modalContent}</p>
            <button
              onClick={closeModal}
              className="mt-4 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
            >
              Закрыть
            </button>
          </div>
        </div>
      )}

      {/* Модалка изменений */}
      {changesModal && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg w-[90%] max-w-2xl">
            <h2 className="text-xl font-bold mb-4">Изменения</h2>
            <pre className="text-sm text-gray-800 whitespace-pre-wrap">
              {changesModal}
            </pre>
            <button
              onClick={closeChangesModal}
              className="mt-4 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
            >
              Закрыть
            </button>
          </div>
        </div>
      )}

      {/* Модалка Set RFQ Dates */}
      {showDatesModal && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg w-96 max-w-md flex flex-col gap-4">
            <h2 className="text-xl font-bold">Set RFQ Dates</h2>

            <label className="block">
              <span className="text-sm font-semibold">
                RFQ Received DD (date only)
              </span>
              <input
                type="date"
                name="rfq_received_dd"
                value={datesForm.rfq_received_dd}
                onChange={handleDatesFormChange}
                className="w-full border border-gray-300 rounded px-2 py-1 mt-1"
              />
            </label>

            <label className="block">
              <span className="text-sm font-semibold">
                RFQ Deadline (date + time)
              </span>
              <input
                type="datetime-local"
                name="rfq_deadline"
                value={datesForm.rfq_deadline}
                onChange={handleDatesFormChange}
                className="w-full border border-gray-300 rounded px-2 py-1 mt-1"
              />
            </label>

            <div className="flex justify-end gap-2 mt-4">
              <button
                onClick={closeDatesModal}
                className="bg-gray-300 text-black px-4 py-2 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveDates}
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
              >
                Save Dates
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
