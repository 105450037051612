// src/context/UserContext.js
import React, { createContext, useState, useEffect, useCallback } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(false);

  const fetchUserData = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setUserData(null);
      return;
    }
    
    setIsUserLoading(true);
    try {
      const response = await fetch("https://ai-back-23-production.up.railway.app/users/me", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // <-- важно
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUserData(data);
      } else {
        setUserData(null);
        localStorage.removeItem("token");
        console.warn("Запрос /users/me вернул статус:", response.status);
      }
    } catch (error) {
      console.error("Ошибка при получении данных пользователя:", error);
      setUserData(null);
    } finally {
      setIsUserLoading(false);
    }
  }, []);

  const logout = useCallback(() => {
    // сбрасываем пользовательские данные
    setUserData(null);
    // удаляем токен
    localStorage.removeItem("token");
    // Удаляем refresh-токен (если он есть)
    localStorage.removeItem("refresh_token");
    // (Если нужно, чтобы приветственное окно снова показывалось при новом логине)
    localStorage.removeItem("welcomeShown");
  }, []);

  // Пример вспомогательной функции для запросов с токеном:
  const authorizedFetch = async (url, options = {}) => {
    const token = localStorage.getItem("token");
    const headers = {
      ...(options.headers || {}),
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    // Делаем начальный запрос
    let response = await fetch(url, { ...options, headers });

    // Если получаем 401 (скорее всего, токен протух), пробуем рефрешнуть
    if (response.status === 401) {
      console.warn("Got 401 => trying to refresh access_token...");

      const refreshToken = localStorage.getItem("refresh_token");
      if (!refreshToken) {
        console.warn("No refresh_token => logout()");
        logout();
        return response; // Возвращаем оригинальный ответ, или можете редиректить
      }

      // Запрос /refresh
      const refreshResp = await fetch("https://ai-back-23-production.up.railway.app/refresh", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ refresh_token: refreshToken })
      });

      if (refreshResp.ok) {
        // Обновили access-токен, пробуем повторить исходный запрос
        const data = await refreshResp.json();
        console.log("Got new access_token:", data.access_token);

        localStorage.setItem("token", data.access_token);

        const newHeaders = {
          ...(options.headers || {}),
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        };
        // Повторяем запрос
        response = await fetch(url, { ...options, headers: newHeaders });
      } else {
        console.warn("Refresh failed => logout()");
        logout();
        return response;
      }
    }

    return response;
  };

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  return (
    <UserContext.Provider
      value={{
        userData,
        setUserData,
        fetchUserData,
        logout,
        authorizedFetch, // <-- экспортируем вспомогательную функцию
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
