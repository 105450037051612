"use client";

import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowLeftFromLine } from "lucide-react";
import UserContext from "../../context/UserContext";

export default function POView() {
  const location = useLocation();
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);

  // --------------------- Состояния ---------------------
  const [pos, setPOs] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [currentDocument, setCurrentDocument] = useState(
    location.state?.fileName || null
  );

  const uploadedFile = location.state?.fileName || "No file uploaded";

  // Редактирование
  const [editedData, setEditedData] = useState({});
  const [changesModal, setChangesModal] = useState(null);

  // --------------------- Пагинация ---------------------
  const totalPages = 3;
  const [currentPage, setCurrentPage] = useState(1);

  const goToNextPage = () => {
    setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));
  };

  const goToPrevPage = () => {
    setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
  };

  // --------------------- Определяем столбцы для таблицы по страницам ---------------------
  const pageColumns = {
    1: [
      { label: "PO#", key: "po_number", width: "400px" },
      { label: "Buyer", key: "buyer", width: "300px" },
      { label: "Company name", key: "company_name", width: "300px" }, // новое поле
      { label: "Responsible", key: "responsible", width: "150px" },
      { label: "Item", key: "item", width: "100px" },
      { label: "Material / Service", key: "material_service", width: "280px" },
      { label: "PO Date", key: "po_date", width: "300px" },
    ],
    2: [
      { label: "Deliv. date", key: "deliv_date", width: "200px" },
      { label: "Description", key: "description", width: "180px" },
      { label: "Manufacturer", key: "manufacturer", width: "120px" },
      { label: "Part number", key: "manufacturer_part_number", width: "110px" },
      { label: "Qty", key: "qty", width: "60px" },
      { label: "Unit Price", key: "unit_price", width: "80px" },
      { label: "Order Value", key: "order_value", width: "90px" },
    ],
    3: [
      { label: "Base Unit", key: "base_unit", width: "80px" },
      { label: "Final destination", key: "final_destination", width: "100px" },
      { label: "Certificate", key: "certificate", width: "120px" },
      { label: "Certificate of conformity", key: "certificate_of_conformity", width: "140px" },
      { label: "Manuf. declaration", key: "manufacturer_declaration", width: "140px" },
    ],
  };

  // --------------------- Модалки ---------------------
  const handleCellClick = (fullText) => setModalContent(fullText);
  const closeModal = () => setModalContent(null);
  const closeChangesModal = () => setChangesModal(null);

  // --------------------- Загрузка PDF ---------------------
  const handlePDFUpload = async (event) => {
    if (userData?.role !== "admin" && userData?.role !== "buyerFull") {
      alert("Нет прав на загрузку документов.");
      return;
    }

    const file = event.target.files?.[0];
    if (!file) return;

    setIsLoading(true);
    setError(null);

    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch("https://ai-back-23-production.up.railway.app/parse-po/", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errData = await response.json();
        setError(errData.detail || "Не удалось обработать PDF.");
      } else {
        setCurrentDocument(file.name);
        alert(`Файл ${file.name} успешно обработан!`);
        fetchData(file.name);
      }
    } catch (err) {
      setError("Ошибка при загрузке файла.");
    } finally {
      setIsLoading(false);
    }
  };

  // --------------------- Загрузка данных по PO ---------------------
  const fetchData = async (documentName) => {
    if (!documentName) return;
    setIsLoading(true);
    setError(null);

    try {
      const url = `https://ai-back-23-production.up.railway.app/get-documents-po/?document_name=${encodeURIComponent(
        documentName
      )}`;
      const resp = await fetch(url);
      const data = await resp.json();

      if (!resp.ok) {
        throw new Error(data.detail || "Не удалось получить данные (PO).");
      }

      // Сортируем по item (числово)
      data.sort((a, b) => parseInt(a.item) - parseInt(b.item));
      setPOs(data);
      setEditedData({});
    } catch (err) {
      setError("Ошибка при запросе PO данных из БД: " + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentDocument) {
      fetchData(currentDocument);
    }
  }, [currentDocument]);

  // --------------------- Редактирование ячеек ---------------------
  const staticResponsibleOptions = [
    { id: "1", first_name: "Тамирлан" },
    { id: "2", first_name: "Саламат" },
    { id: "3", first_name: "Жанарыс" },
    { id: "4", first_name: "Салтанат" },
    { id: "5", first_name: "Алишер" },
    { id: "6", first_name: "Беласар" },
    { id: "7", first_name: "Салават" },
    { id: "8", first_name: "Римма" },
  ];

  const handleCellEdit = (rowIndex, columnKey, newValue) => {
    const oldValue = pos[rowIndex]?.[columnKey] || "";

    // Если меняют qty или unit_price => пересчитываем order_value
    if (columnKey === "qty" || columnKey === "unit_price") {
      const rowData = { ...pos[rowIndex], [columnKey]: newValue };
      const qtyNum = parseFloat(rowData.qty) || 0;
      const priceNum = parseFloat(rowData.unit_price) || 0;
      const orderVal = (qtyNum * priceNum).toString();

      setEditedData((prev) => {
        const rowEdits = prev[rowIndex] || {};
        return {
          ...prev,
          [rowIndex]: {
            ...rowEdits,
            [columnKey]: { oldValue, newValue },
            order_value: { oldValue: rowData.order_value || "", newValue: orderVal },
          },
        };
      });
      return;
    }

    setEditedData((prev) => {
      const rowEdits = prev[rowIndex] || {};
      return {
        ...prev,
        [rowIndex]: {
          ...rowEdits,
          [columnKey]: { oldValue, newValue },
        },
      };
    });
  };

  // --------------------- Сохранение изменений ---------------------
  const handleSaveChanges = async () => {
    if (!currentDocument) {
      alert("Нет текущего документа.");
      return;
    }

    if (!Object.keys(editedData).length) {
      alert("Нет изменений");
      return;
    }

    let changesText = "";
    const updatesForServer = [];

    for (const rowIndexStr of Object.keys(editedData)) {
      const rowIndex = parseInt(rowIndexStr, 10);
      const rowEdits = editedData[rowIndex];
      const rowItem = pos[rowIndex]?.item || "";

      for (const columnKey of Object.keys(rowEdits)) {
        const { oldValue, newValue } = rowEdits[columnKey];
        if (newValue !== oldValue) {
          changesText += `Строка ${rowIndex + 1}, поле "${columnKey}": "${oldValue}" -> "${newValue}"\n`;
          updatesForServer.push({
            rowIndex,
            item: rowItem,
            columnKey,
            oldValue,
            newValue,
          });
        }
      }
    }

    if (!changesText.trim()) {
      alert("Нет изменений.");
      return;
    }
    setChangesModal(changesText);

    try {
      const token = localStorage.getItem("token");
      const response = await fetch("https://ai-back-23-production.up.railway.app/update-po-data/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          document_name: currentDocument,
          updates: updatesForServer,
        }),
      });

      if (!response.ok) {
        throw new Error("Не удалось сохранить изменения (PO).");
      }

      alert("Изменения успешно сохранены!");

      // Обновляем локально
      const updatedPOs = [...pos];
      for (const upd of updatesForServer) {
        const row = updatedPOs[upd.rowIndex];
        if (row) {
          row[upd.columnKey] = upd.newValue;
          if (upd.columnKey === "order_value") {
            row["order_value"] = upd.newValue;
          }
        }
      }
      setPOs(updatedPOs);
      setEditedData({});
      setChangesModal(null);
      fetchData(currentDocument);
    } catch (err) {
      alert("Ошибка при сохранении: " + err.message);
    }
  };

  // --------------------- РЕНДЕР ТАБЛИЦЫ ---------------------
  const renderTable = () => {
    // Получаем столбцы для текущей страницы
    const columns = pageColumns[currentPage];

    return (
      <div className="mt-4 border rounded-md p-2 bg-white">
        <div
          className="overflow-auto"
          style={{
            maxHeight: "400px",
            maxWidth: "100%",
          }}
        >
          <table className="table-auto w-full text-sm border-collapse border border-gray-300">
            <thead className="bg-gray-200 sticky top-0">
              <tr>
                {columns.map((col) => (
                  <th
                    key={col.key}
                    className="px-2 py-1 font-semibold text-left text-xs border-b"
                    style={{ width: col.width }}
                  >
                    {col.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {pos.map((po, rowIndex) => (
                <tr key={rowIndex} className="hover:bg-gray-50">
                  {columns.map((col) => {
                    const columnKey = col.key;
                    const cellValue = po[columnKey] || "";
                    const editedValue =
                      editedData[rowIndex]?.[columnKey]?.newValue ?? null;
                    const displayValue = editedValue ?? cellValue;

                    if (columnKey === "responsible") {
                      return (
                        <td
                          key={columnKey}
                          className="px-2 py-1 border-b text-xs"
                          style={{
                            width: col.width,
                            verticalAlign: "top",
                            backgroundColor: "#2026f5",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <select
                            className="w-full border border-gray-300 rounded px-2 py-1 text-xs"
                            value={displayValue}
                            onChange={(e) =>
                              handleCellEdit(rowIndex, columnKey, e.target.value)
                            }
                          >
                            <option value="">- No Responsible -</option>
                            {staticResponsibleOptions.map((user) => (
                              <option key={user.id} value={user.first_name}>
                                {user.first_name}
                              </option>
                            ))}
                          </select>
                        </td>
                      );
                    }
                    return (
                      <td
                        key={columnKey}
                        className="px-2 py-1 border-b text-xs"
                        style={{ width: col.width }}
                      >
                        <input
                          type="text"
                          className="w-full border border-gray-300 rounded px-2 py-1 text-xs"
                          style={{ cursor: "pointer" }}
                          value={displayValue}
                          onChange={(e) =>
                            handleCellEdit(rowIndex, columnKey, e.target.value)
                          }
                          onClick={() => {
                            if (cellValue.length > 8) {
                              handleCellClick(cellValue);
                            }
                          }}
                        />
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* Пагинация */}
        <div className="flex justify-between items-center mt-4">
          <button
            onClick={goToPrevPage}
            disabled={currentPage === 1}
            className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
          >
            Предыдущая
          </button>
          <span className="text-sm">
            Страница {currentPage} из {totalPages}
          </span>
          <button
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
            className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
          >
            Следующая
          </button>
        </div>
      </div>
    );
  };

  // --------------------- РЕНДЕР КОМПОНЕНТА ---------------------
  return (
    <div className="min-h-screen p-4 bg-white">
      {/* Заголовок, кнопки возврата */}
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">PO Check</h1>
        <div className="flex gap-2">
          <button
            className="flex items-center bg-gray-500 text-white px-4 py-2 rounded"
            onClick={() => navigate("/po")}
          >
            <ArrowLeftFromLine className="h-4 w-4 mr-2" />
            back PO Load and Status Overview
          </button>
        </div>
      </div>

      {/* Загрузка PDF */}
      <label className="block text-gray-700 font-bold mb-2">Uploaded PO: {uploadedFile}</label>

      {/* Кнопки управления */}
      <div className="mb-4 flex flex-wrap gap-2">
        <button
          onClick={handleSaveChanges}
          className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
          disabled={
            !currentDocument || isLoading || Object.keys(editedData).length === 0
          }
        >
          Save Changes
        </button>
      </div>

      {/* Loading indicator */}
      {isLoading && (
        <div className="flex justify-center items-center mt-6">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
        </div>
      )}

      {/* Рендер таблицы */}
      {!isLoading && renderTable()}

      {/* Модалка просмотра длинного текста */}
      {modalContent && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg w-[90%] max-w-2xl">
            <h2 className="text-xl font-bold mb-4">Полный текст</h2>
            <p className="text-sm text-gray-800 whitespace-pre-wrap">
              {modalContent}
            </p>
            <button
              onClick={closeModal}
              className="mt-4 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
            >
              Закрыть
            </button>
          </div>
        </div>
      )}

      {/* Модалка изменений */}
      {changesModal && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg w-[90%] max-w-2xl">
            <h2 className="text-xl font-bold mb-4">Изменения</h2>
            <pre className="text-sm text-gray-800 whitespace-pre-wrap">
              {changesModal}
            </pre>
            <button
              onClick={closeChangesModal}
              className="mt-4 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
            >
              Закрыть
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
