import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";

// Функция для красивого отображения статуса (документа или item)
function getStatusLabel(status) {
  switch (status) {
    case "Not assigned":
      return <span className="text-red-500">❌ Not assigned</span>;
    case "Material under search":
      return <span className="text-orange-500">Material under search</span>;
    case "C&L calculation and dates":
      return <span className="text-green-600">C&L calculation and dates</span>;
    case "In Progress":
      return <span className="text-blue-600">In Progress</span>;
    default:
      return <span className="text-gray-500">{status || "—"}</span>;
  }
}

// Функция для обрезки описания (максимум 10 символов и три точки, если длиннее)
function truncateDescription(desc) {
  if (!desc) return "";
  return desc.length > 10 ? desc.slice(0, 10) + "..." : desc;
}

const RFQ = () => {
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);

  // Массив документов — каждый документ должен появляться один раз
  const [documents, setDocuments] = useState([]); // [{document_name, upload_date, status, company}]
  // Массив line items – каждая строка соответствует одному line item
  const [lineItems, setLineItems] = useState([]);
  // Статусы для line items
  const [itemStatuses, setItemStatuses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Состояния для модального окна описания
  const [modalVisible, setModalVisible] = useState(false);
  const [modalDescription, setModalDescription] = useState("");

  // =========================== COMPANY EDITING LOGIC ===========================
  // Храним, в какой документ (document_name) включён режим редактирования колонки Company
  const [editingCompanyDoc, setEditingCompanyDoc] = useState(null);
  // Локальное значение, введённое пользователем
  const [tempCompanyValue, setTempCompanyValue] = useState("");

  // =========================== FETCH FUNCTIONS ===========================

  // -------------------- 1. Загрузка статусов документов --------------------
  // Обращаемся к эндпоинту /get-documents-status/ и фильтруем уникальные документы
  const fetchDocumentsStatus = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch("https://ai-back-23-production.up.railway.app/get-documents-status/");
      if (response.ok) {
        const result = await response.json();
        // Фильтруем уникальные документы по document_name,
        // если дубли появляются, используем Map для удаления повторов.
        const uniqueDocs = Array.from(
          new Map(result.map((doc) => [doc.document_name, doc])).values()
        );
        setDocuments(uniqueDocs);
      } else {
        throw new Error("Failed to fetch documents.");
      }
    } catch (err) {
      setError("Failed to fetch documents from the server.");
    } finally {
      setIsLoading(false);
    }
  };

  // -------------------- 2. Загрузка line items и статусов items --------------------
  const fetchLineItems = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Все line items из get-documents-manually
      const response = await fetch("https://ai-back-23-production.up.railway.app/get-documents-manually/");
      if (!response.ok) {
        throw new Error("Failed to fetch line items.");
      }
      const result = await response.json();

      // Получаем статусы для line items
      const statusResp = await fetch("https://ai-back-23-production.up.railway.app/get-item-status/");
      if (!statusResp.ok) {
        throw new Error("Failed to fetch item statuses.");
      }
      const statusData = await statusResp.json();

      // Сортируем line items по возрастанию id (например, 10, 20, 30, 40, 50...)
      result.sort((a, b) => Number(a.id) - Number(b.id));

      setLineItems(result);
      setItemStatuses(statusData);
    } catch (err) {
      setError(err.message || "Failed to fetch line items.");
    } finally {
      setIsLoading(false);
    }
  };

  // -------------------- Удаление документа --------------------
  const handleDeleteDocument = async (documentName) => {
    if (!window.confirm(`Are you sure you want to delete "${documentName}"?`)) {
      return;
    }
    try {
      setIsLoading(true);
      const response = await fetch("https://ai-back-23-production.up.railway.app/delete-document/", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ document_name: documentName }),
      });

      if (response.ok) {
        alert(`Document "${documentName}" deleted.`);
        // Убираем документ из всех массивов
        setDocuments((prev) => prev.filter((d) => d.document_name !== documentName));
        setLineItems((prev) => prev.filter((i) => i.document_name !== documentName));
        setItemStatuses((prev) => prev.filter((s) => s.document_name !== documentName));
      } else {
        const err = await response.json();
        throw new Error(err.detail || "Failed to delete document.");
      }
    } catch (err) {
      setError(err.message || "Failed to delete document.");
    } finally {
      setIsLoading(false);
    }
  };

  // -------------------- Переход к детальному просмотру RFQSystem --------------------
  const handleDocumentClick = (documentName) => {
    if (userData?.role === "buyerFull" || userData?.role === "admin") {
      navigate("/rfq-view", { state: { fileName: documentName } });
    } else {
      alert("You do not have permission to open this document.");
    }
  };

  // -------------------- Клик по конкретному line item => переход на /rfq-submit --------------------
  const handleLineItemClick = (documentName, itemNumber, responsible) => {
    if (!responsible || responsible === "N/A") {
      alert("Responsible is not assigned. You cannot edit this item.");
      return;
    }
    if (userData?.role === "buyer" || userData?.role === "buyerFull") {
      navigate("/rfq-submit", {
        state: {
          fileName: documentName,
          itemNumber: itemNumber,
        },
      });
    }
  };

  // -------------------- Получение статуса конкретного item --------------------
  const getItemStatus = (document_name, item) => {
    const st = itemStatuses.find(
      (s) => s.document_name === document_name && String(s.item) === String(item)
    );
    return st ? st.status : "Not assigned";
  };

  // -------------------- Модальное окно: показать полное описание --------------------
  const handleShowDescription = (desc) => {
    setModalDescription(desc || "");
    setModalVisible(true);
  };
  const handleCloseModal = () => {
    setModalVisible(false);
    setModalDescription("");
  };

  // =========================== COMPANY EDITING LOGIC ===========================
  // При двойном клике по ячейке Company включается режим редактирования
  const handleCompanyDoubleClick = (doc) => {
    if (!(userData?.role === "buyerFull" || userData?.role === "admin")) {
      return;
    }
    setEditingCompanyDoc(doc.document_name);
    setTempCompanyValue(doc.company || "");
  };

  // При нажатии на кнопку Save отправляем новый company через POST-запрос
  const handleCompanySave = async (docName) => {
    try {
      setIsLoading(true);
      const resp = await fetch("https://ai-back-23-production.up.railway.app/update-company/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          document_name: docName,
          company: tempCompanyValue.trim(),
        }),
      });
      if (!resp.ok) {
        const errData = await resp.json();
        throw new Error(errData.detail || "Failed to update company");
      }
      // После успешного сохранения обновляем локальное состояние документов
      setDocuments((prevDocs) =>
        prevDocs.map((d) => {
          if (d.document_name === docName) {
            return { ...d, company: tempCompanyValue.trim() };
          }
          return d;
        })
      );
    } catch (err) {
      setError(err.message || "Failed to update company");
    } finally {
      setIsLoading(false);
      setEditingCompanyDoc(null);
      setTempCompanyValue("");
    }
  };

  // Новая функция: handleCompanyClear – очищает значение поля Company и сразу сохраняет пустую строку
  const handleCompanyClear = async (docName) => {
    try {
      setIsLoading(true);
      const resp = await fetch("https://ai-back-23-production.up.railway.app/update-company/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          document_name: docName,
          company: "",
        }),
      });
      if (!resp.ok) {
        const errData = await resp.json();
        throw new Error(errData.detail || "Failed to clear company");
      }
      // Обновляем локальное состояние документов, устанавливая пустое значение
      setDocuments((prevDocs) =>
        prevDocs.map((d) => {
          if (d.document_name === docName) {
            return { ...d, company: "" };
          }
          return d;
        })
      );
    } catch (err) {
      setError(err.message || "Failed to clear company");
    } finally {
      setIsLoading(false);
      setEditingCompanyDoc(null);
      setTempCompanyValue("");
    }
  };

  // =========================== useEffect: INITIAL LOAD ===========================
  useEffect(() => {
    fetchDocumentsStatus();
    fetchLineItems();
  }, []);

  // =========================== RENDER ===========================
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">RFQ Status Overview</h1>

      {(userData?.role === "admin" || userData?.role === "buyerFull") && (
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
          onClick={() => navigate("/rfq-system")}
        >
          Upload New RFQ
        </button>
      )}

      {isLoading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}

      {/* -------------------- Таблица 1: статусы документов (уникальные документы) -------------------- */}
      <div className="overflow-auto bg-white p-4 border rounded mb-6" style={{ maxHeight: "300px" }}>
        <table className="w-full border-collapse border border-gray-300 text-sm">
          <thead className="bg-gray-200">
            <tr>
              <th className="border px-4 py-2 text-left">All RFQ's</th>
              <th className="border px-4 py-2 text-left">Company</th>
              <th className="border px-4 py-2 text-left">Upload Date</th>
              <th className="border px-4 py-2 text-left">Document Status</th>
              {(userData?.role === "admin" || userData?.role === "buyerFull") && (
                <th className="border px-4 py-2 text-left">Actions</th>
              )}
            </tr>
          </thead>
          <tbody>
            {documents.map((doc, index) => {
              const isEditingCompany = doc.document_name === editingCompanyDoc;
              return (
                <tr key={index} className="hover:bg-gray-100">
                  {/* document_name */}
                  <td
                    className={`border px-4 py-2 ${
                      userData?.role === "buyerFull" || userData?.role === "admin"
                        ? "cursor-pointer text-blue-500 underline"
                        : ""
                    }`}
                    onClick={
                      userData?.role === "buyerFull" || userData?.role === "admin"
                        ? () => handleDocumentClick(doc.document_name)
                        : undefined
                    }
                  >
                    {doc.document_name}
                  </td>

                  {/* Company */}
                  <td
                    className="border px-4 py-2"
                    onDoubleClick={() => handleCompanyDoubleClick(doc)}
                  >
                    {isEditingCompany ? (
                      <div className="flex items-center">
                        <input
                          type="text"
                          value={tempCompanyValue}
                          onChange={(e) => setTempCompanyValue(e.target.value)}
                          className="border px-2 py-1 mr-2"
                        />
                        <button
                          className="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-700 text-xs mr-2"
                          onClick={() => handleCompanySave(doc.document_name)}
                        >
                          Save
                        </button>
                        <button
                          className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-700 text-xs"
                          onClick={() => handleCompanyClear(doc.document_name)}
                        >
                          Clear
                        </button>
                      </div>
                    ) : (
                      doc.company || "—"
                    )}
                  </td>

                  {/* Upload Date */}
                  <td className="border px-4 py-2">
                    {doc.upload_date ? doc.upload_date.split("T")[0] : "—"}
                  </td>

                  {/* Document Status */}
                  <td className="border px-4 py-2">{getStatusLabel(doc.status)}</td>

                  {/* Actions */}
                  {(userData?.role === "admin" || userData?.role === "buyerFull") && (
                    <td className="border px-4 py-2 text-center">
                      <button
                        className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-700 text-xs"
                        onClick={() => handleDeleteDocument(doc.document_name)}
                      >
                        Delete
                      </button>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* -------------------- Таблица 2: line items (каждая строка = отдельный line item) -------------------- */}
      <div className="overflow-auto bg-white p-4 border rounded" style={{ maxHeight: "300px" }}>
        <table className="w-full border-collapse border border-gray-300 text-sm">
          <thead className="bg-gray-200">
            <tr>
              <th className="border px-4 py-2 text-left">RFQ#</th>
              <th className="border px-4 py-2 text-left">Line item#</th>
              <th className="border px-4 py-2 text-left">Description</th>
              <th className="border px-4 py-2 text-left">Line Item Status</th>
              <th className="border px-4 py-2 text-left">Responsible</th>
            </tr>
          </thead>
          <tbody>
            {lineItems.length > 0 ? (
              lineItems.map((item, index) => {
                const itemStatus = getItemStatus(item.document_name, item.item);
                return (
                  <tr
                    key={index}
                    className={
                      userData?.role === "buyer" || userData?.role === "buyerFull"
                        ? "cursor-pointer hover:bg-gray-100"
                        : ""
                    }
                    onClick={() =>
                      (userData?.role === "buyer" || userData?.role === "buyerFull") &&
                      handleLineItemClick(item.document_name, item.item, item.responsible)
                    }
                  >
                    {/* RFQ# */}
                    <td className="border px-4 py-2">{item.document_name}</td>

                    {/* Line item# */}
                    <td className="border px-4 py-2">{item.item}</td>

                    {/* Description */}
                    <td
                      className="border px-4 py-2 cursor-pointer text-blue-500 underline"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleShowDescription(item.description);
                      }}
                    >
                      {truncateDescription(item.description)}
                    </td>

                    {/* Line Item Status */}
                    <td className="border px-4 py-2">{getStatusLabel(itemStatus)}</td>

                    {/* Responsible */}
                    <td className="border px-4 py-2">
                      {item.responsible ? item.responsible : "N/A"}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="border px-4 py-2 text-center" colSpan={5}>
                  No data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* -------------------- Модальное окно для полного описания -------------------- */}
      {modalVisible && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded shadow-lg p-6 w-11/12 md:w-1/2">
            <h2 className="text-xl font-bold mb-4">Full Description</h2>
            <p className="mb-4">{modalDescription}</p>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RFQ;
