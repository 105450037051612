import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Функция для обрезки описания (максимум 10 символов и три точки, если длиннее)
function truncateDescription(desc) {
  if (!desc) return "";
  return desc.length > 10 ? desc.slice(0, 10) + "..." : desc;
}

const PricingPageOverview = () => {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Состояния для модального окна описания
  const [modalVisible, setModalVisible] = useState(false);
  const [modalDescription, setModalDescription] = useState("");

  const fetchDocuments = async () => {
    setIsLoading(true);
    try {
      const resp = await fetch("https://ai-back-23-production.up.railway.app/get-pricing-status/");
      if (!resp.ok) {
        throw new Error("Не удалось получить данные pricing.");
      }
      const data = await resp.json();
      setDocuments(data);
    } catch (err) {
      setError(err.message || "Ошибка при загрузке данных");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  // Переход к странице /pricing для конкретного документа
  const handleLineItemClick = (doc) => {
    navigate("/pricing", {
      state: {
        document_name: doc.document_name,
        item: doc.item,
        status: doc.status,
      },
    });
  };

  // Модальное окно: показать полное описание
  const handleShowDescription = (desc) => {
    setModalDescription(desc || "");
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setModalDescription("");
  };

  // Отображение статуса с эмоджи
  const renderStatusWithEmoji = (status) => {
    switch (status) {
      case "Not Started":
        return <span className="text-red-500">❌ Not Started</span>;
      case "In Progress":
        return <span className="text-orange-500">⏳ In Progress</span>;
      case "Completed":
        return <span className="text-green-600">✅ Completed</span>;
      default:
        return <span className="text-gray-500">{status}</span>;
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Quote price calculation</h1>

      {isLoading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}

      <div className="overflow-auto bg-white p-4 border rounded">
        <table className="w-full border-collapse border border-gray-300">
          <thead className="bg-gray-200">
            <tr>
              <th className="border px-4 py-2">RFQ#</th>
              <th className="border px-4 py-2">Line item#</th>
              <th className="border px-4 py-2">Description</th>
              <th className="border px-4 py-2">Status</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((doc, i) => (
              <tr
                key={i}
                className="hover:bg-gray-100 cursor-pointer"
                onClick={() => handleLineItemClick(doc)}
              >
                <td className="border px-4 py-2">{doc.document_name}</td>
                <td className="border px-4 py-2">{doc.item}</td>
                {/* Колонка Description с обрезкой + клик для модального окна */}
                <td
                  className="border px-4 py-2 text-blue-500 underline"
                  onClick={(e) => {
                    e.stopPropagation(); // чтобы не срабатывал общий onClick на всю строку
                    handleShowDescription(doc.description);
                  }}
                >
                  {truncateDescription(doc.description)}
                </td>
                <td className="border px-4 py-2">{renderStatusWithEmoji(doc.status)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Модальное окно для полного описания */}
      {modalVisible && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded shadow-lg p-6 w-11/12 md:w-1/2">
            <h2 className="text-xl font-bold mb-4">Full Description</h2>
            <p className="mb-4">{modalDescription}</p>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PricingPageOverview;
