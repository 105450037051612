import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";

// Функция для красивого отображения статуса Procon
function renderProconStatus(status) {
  switch (status) {
    case "Not Started":
      return <span className="text-red-500">❌ Not Started</span>;
    case "In Progress":
      return <span className="text-orange-500">⏳ In Progress</span>;
    case "To be loaded to Procon":
      return <span className="text-blue-600">To be loaded</span>;
    case "Not loaded to Procon":
      return <span className="text-gray-500">Not loaded</span>;
    case "Quotation loaded to Procon":
      return <span className="text-green-600">✅ Quotation loaded</span>;
    default:
      return <span className="text-gray-700">{status || "—"}</span>;
  }
}

// Функция для обрезки описания до 10 символов, потом "..."
function truncateDescription(desc) {
  if (!desc) return "";
  return desc.length > 10 ? desc.slice(0, 10) + "..." : desc;
}

const Procon = () => {
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);

  // Данные для таблицы line items (Procon)
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Новый массив для выпадающего списка документов (из get-documents-status)
  const [allDocNames, setAllDocNames] = useState([]);
  const [selectedDocName, setSelectedDocName] = useState("");

  // Состояние для анимации загрузки
  const [isDownloading, setIsDownloading] = useState(false);

  // -------------------- Для модального окна описания --------------------
  const [modalVisible, setModalVisible] = useState(false);
  const [modalDescription, setModalDescription] = useState("");

  const handleShowDescription = (desc) => {
    setModalDescription(desc || "");
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setModalDescription("");
  };
  // ----------------------------------------------------------------------

  // Функция для получения данных для таблицы Procon (line items)
  const fetchProconDocuments = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `https://ai-back-23-production.up.railway.app/get-procon-documents?t=${Date.now()}`
      );
      if (!response.ok) {
        throw new Error("Failed to load Procon documents.");
      }
      const data = await response.json();
      setDocuments(data);
    } catch (err) {
      setError(err.message || "Ошибка при загрузке Procon данных.");
    } finally {
      setIsLoading(false);
    }
  };

  // Функция для получения списка всех документов из get-documents-status (для выпадающего списка)
  const fetchAllRfqDocuments = async () => {
    try {
      const resp = await fetch("https://ai-back-23-production.up.railway.app/get-documents-status/");
      if (!resp.ok) {
        throw new Error("Failed to fetch documents from get-documents-status.");
      }
      const data = await resp.json();
      const docNames = data.map((d) => d.document_name);
      const unique = [...new Set(docNames)];
      setAllDocNames(unique);
    } catch (error) {
      console.error("Error fetching all RFQ documents:", error);
    }
  };

  // Запросы при монтировании компонента
  useEffect(() => {
    fetchAllRfqDocuments();
    fetchProconDocuments();
  }, []);

  // Обновление таблицы при получении фокуса окна
  useEffect(() => {
    const handleFocus = () => {
      fetchProconDocuments();
    };
    window.addEventListener("focus", handleFocus);
    return () => window.removeEventListener("focus", handleFocus);
  }, []);

  // Клик на строку таблицы => переходим к деталям line item (ProconSubmit)
  const handleLineItemClick = (doc) => {
    navigate("/procon-submit", {
      state: {
        document_name: doc.document_name,
        item: String(doc.item),
      },
    });
  };

  // Хендлер скачивания Google Sheets для выбранного документа
  const handleDownloadGoogleSheet = async () => {
    if (!selectedDocName) {
      alert("Please select a document first.");
      return;
    }
    try {
      setIsDownloading(true);
      const resp = await fetch(
        `https://ai-back-23-production.up.railway.app/export-procon?document_name=${encodeURIComponent(
          selectedDocName
        )}`,
        { method: "GET" }
      );
      if (!resp.ok) {
        throw new Error("Failed to export Google Sheet.");
      }
      const blob = await resp.blob();

      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = `RFQDocument_${selectedDocName}.xlsx`;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      alert("Ошибка при скачивании Google Sheet: " + error.message);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Procon Overview</h1>
      {isLoading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}

      {/* Блок для выбора документа и кнопки скачивания */}
      <div className="bg-white p-4 border rounded mb-4">
        <h2 className="font-semibold mb-2">
          Download Document Data to Google Sheets
        </h2>
        <div className="flex items-center space-x-2">
          <select
            className="border p-2 rounded"
            value={selectedDocName}
            onChange={(e) => setSelectedDocName(e.target.value)}
          >
            <option value="">-- Select Document --</option>
            {allDocNames.map((docName) => (
              <option key={docName} value={docName}>
                {docName}
              </option>
            ))}
          </select>
          <button
            onClick={handleDownloadGoogleSheet}
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
            disabled={isDownloading}
          >
            {isDownloading ? "Downloading..." : "Download to Google Sheets"}
          </button>
        </div>
      </div>

      {/* Таблица line items Procon */}
      <div className="overflow-auto bg-white p-4 border rounded">
        <table className="w-full border-collapse border border-gray-300">
          <thead className="bg-gray-200">
            <tr>
              <th className="border px-4 py-2 text-left">RFQ#</th>
              <th className="border px-4 py-2 text-left">Line item#</th>
              <th className="border px-4 py-2 text-left">Description</th>
              <th className="border px-4 py-2 text-left">Procon Status</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((doc, index) => (
              <tr
                key={index}
                className="cursor-pointer hover:bg-gray-100"
                onClick={() => handleLineItemClick(doc)}
              >
                <td className="border px-4 py-2">{doc.document_name}</td>
                <td className="border px-4 py-2">{doc.item}</td>
                {/* Колонка Description (обрезаем, при клике — модалка) */}
                <td
                  className="border px-4 py-2 text-blue-500 underline"
                  onClick={(e) => {
                    e.stopPropagation(); // чтобы не срабатывал переход на handleLineItemClick
                    handleShowDescription(doc.description);
                  }}
                >
                  {truncateDescription(doc.description)}
                </td>
                <td className="border px-4 py-2">
                  {renderProconStatus(doc.procon_status)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Модальное окно для полного описания */}
      {modalVisible && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded shadow-lg p-6 w-11/12 md:w-1/2">
            <h2 className="text-xl font-bold mb-4">Full Description</h2>
            <p className="mb-4">{modalDescription}</p>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Procon;
