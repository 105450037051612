import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import UserContext from "../../context/UserContext";
import { ChevronLeft, ChevronRight, ArrowLeftFromLine } from "lucide-react";
import { X } from "lucide-react";

// Хелперы для форматирования:
function formatNumberWithCommas(value) {
  // Если значение пустое – возвращаем его как есть
  if (!value) return "";
  // Убираем все запятые, парсим число, затем форматируем с запятыми
  const floatVal = parseFloat(value.replace(/,/g, "")) || 0;
  return floatVal.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
}

const RFQSubmit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userData } = useContext(UserContext);

  // Из location.state
  const uploadedFile = location.state?.fileName || "No file uploaded";
  const chosenItemNumber = location.state?.itemNumber;

  const [items, setItems] = useState([]);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isFormSaved, setIsFormSaved] = useState(false);
  const [error, setError] = useState(null);

  // Основная форма (храним поля в formData)
  const initialFormData = {
    PriceRequested: "",           // "Yes", "No", or "N/A"
    SubStatus: "",                // KIM Certificate, No price found, etc.
    Incoterms: "",
    PriceForUnitVendor: "",
    TotalPriceVendor: "",
    LeadTimeVendor: "",
    VendorName: "",
    Deviation: "",
    ClarificationOnDeviation: "",
    TCTechnicalClarification: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  // Правая панель
  const [isRightVisible, setIsRightVisible] = useState(true);

  // Модалка Related
  const [showRelatedModal, setShowRelatedModal] = useState(false);
  const [docLinkInput, setDocLinkInput] = useState("");
  const [docLinks, setDocLinks] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Базовые поля таблицы (в нужном порядке):
  const baseFields = [
    {
      key: "PriceRequested",
      label: "Price requested",
      type: "select",
      options: ["Yes", "No", "N/A"],
    },
    {
      key: "SubStatus",
      label: "Select status",
      type: "select",
      // Появляется только при PriceRequested="N/A"
      options: [
        "KIM Certificate",
        "No price found",
        "Vendors long response",
        "Historical price is low than current",
      ],
    },
    {
      key: "Incoterms",
      label: "Incoterms",
      type: "select",
      options: ["DAP", "FCA", "DDP", "ExWorks"],
    },
    {
      key: "PriceForUnitVendor",
      label: "Price For Unit Vendor ($)",
      placeholder: "Price / Unit",
    },
    {
      key: "TotalPriceVendor",
      label: "Total Price Vendor ($)",
    },
    {
      key: "LeadTimeVendor",
      label: "Lead Time Vendor (weeks)",
      placeholder: "e.g. 2 weeks",
    },
    {
      key: "VendorName",
      label: "Vendor Name",
    },
    {
      key: "Deviation",
      label: "Deviation",
      type: "select",
      options: ["No", "Yes"],
    },
    {
      key: "ClarificationOnDeviation",
      label: "Clarification On Deviation",
    },
    {
      key: "TCTechnicalClarification",
      label: "Technical Clarification",
      type: "select",
      options: ["No", "Yes"],
    },
  ];

  const buyerFields = [...baseFields];
  const adminFields = [...baseFields];

  // Новая логика: если PriceRequested === "No", то все остальные поля (кроме PriceRequested и SubStatus) отключены
  const isFieldsDisabled = formData.PriceRequested === "No";

  // Проверка, можно ли делать Submit
  const isFormComplete = (() => {
    // Если formData.PriceRequested === "No" или "N/A" => Submit блокируется
    if (formData.PriceRequested === "No" || formData.PriceRequested === "N/A") {
      return false;
    }
    // Иначе проверяем заполненность обычных полей
    const fieldsToCheck = userData?.role === "admin" ? adminFields : buyerFields;
    return fieldsToCheck.every((f) => {
      // SubStatus не проверяем на заполненность, если PriceRequested !== "N/A"
      if (f.key === "SubStatus") return true;
      // ClarificationOnDeviation не нужно, если Deviation="No"
      if (f.key === "ClarificationOnDeviation" && formData.Deviation === "No") {
        return true;
      }
      // Остальные поля — требуется непустое значение
      return (formData[f.key] || "").trim() !== "";
    });
  })();

  // Загрузка items
  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await fetch(
          `https://ai-back-23-production.up.railway.app/get-documents-manually/?document_name=${encodeURIComponent(
            uploadedFile
          )}`
        );
        if (!resp.ok) {
          throw new Error("Не удалось загрузить данные документа.");
        }
        const data = await resp.json();
        if (data.length > 0) {
          setItems(data);
          if (chosenItemNumber) {
            const idx = data.findIndex(
              (i) => String(i.item) === String(chosenItemNumber)
            );
            if (idx !== -1) {
              setCurrentItemIndex(idx);
              updateFormData(data[idx]);
            } else {
              setCurrentItemIndex(0);
              updateFormData(data[0]);
            }
          } else {
            setCurrentItemIndex(0);
            updateFormData(data[0]);
          }
        }
      } catch (err) {
        setError("Ошибка при загрузке данных из БД.");
      }
    };

    if (uploadedFile !== "No file uploaded") {
      fetchData();
    }
  }, [uploadedFile, chosenItemNumber]);

  // Обновлённая функция updateFormData:
  const updateFormData = (item) => {
    // Если в объекте item уже есть сохранённое значение PriceRequested и SubStatus, используем их
    let pr = item.price_requested || "";
    let sub = item.sub_status || "";
    // Если значение отсутствует, вычисляем на основе item.line_status
    if (!pr) {
      const st = item.line_status || "";
      if (st === "Price requested") {
        pr = "Yes";
      } else if (
        ["KIM Certificate", "No price found", "Vendors long response", "Historical price is low than current"].includes(st)
      ) {
        pr = "N/A";
        sub = st;
      } else {
        pr = "";
      }
    }
    setFormData({
      PriceRequested: pr,
      SubStatus: sub,
      Incoterms: item?.incoterms || "",
      PriceForUnitVendor: formatNumberWithCommas(item?.price_for_unit_vendor || ""),
      TotalPriceVendor: formatNumberWithCommas(item?.total_price_vendor || ""),
      LeadTimeVendor: item?.lead_time_vendor || "",
      VendorName: item?.vendor_name || "",
      Deviation: item?.deviation
        ? item.deviation.toLowerCase() === "yes"
          ? "Yes"
          : "No"
        : "",
      ClarificationOnDeviation: item?.clarification_on_deviation || "",
      TCTechnicalClarification: item?.tc_technical_clarification
        ? item.tc_technical_clarification.toLowerCase() === "yes"
          ? "Yes"
          : "No"
        : "",
    });
    setIsFormSaved(false);
    setIsSubmitted(false);
  };

  // Изменение полей
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Price requested
    if (name === "PriceRequested") {
      setFormData((prev) => ({
        ...prev,
        PriceRequested: value,
        // Если переключаемся с "N/A" на другое, обнуляем SubStatus
        SubStatus: value === "N/A" ? prev.SubStatus : "",
      }));
      setIsFormSaved(false);
      return;
    }

    // SubStatus
    if (name === "SubStatus") {
      setFormData((prev) => ({ ...prev, SubStatus: value }));
      setIsFormSaved(false);
      return;
    }

    // PriceForUnitVendor => оставляем только цифры и точку
    if (name === "PriceForUnitVendor") {
      const raw = value.replace(/[^\d.]/g, "");
      setFormData((prev) => ({
        ...prev,
        PriceForUnitVendor: raw,
      }));
      // Параллельно пересчитываем TotalPriceVendor
      // Вытаскиваем qty из items, убираем запятые, парсим
      const rawQty = (items[currentItemIndex]?.qty || "").replace(/[^\d.]/g, "");
      const qtyNum = parseFloat(rawQty) || 0;
      const priceUnit = parseFloat(raw) || 0;
      const totalPrice = (priceUnit * qtyNum).toFixed(2);
      setFormData((prev) => ({
        ...prev,
        TotalPriceVendor: formatNumberWithCommas(totalPrice),
      }));
      setIsFormSaved(false);
      return;
    }

    // Deviation="No" => сбрасываем Clarification
    if (name === "Deviation") {
      if (value === "No") {
        setFormData((prev) => ({
          ...prev,
          Deviation: "No",
          ClarificationOnDeviation: "",
        }));
      } else {
        setFormData((prev) => ({ ...prev, Deviation: value }));
      }
      setIsFormSaved(false);
      return;
    }

    // Иначе просто записываем
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setIsFormSaved(false);
  };

  // При расфокусе PriceForUnitVendor => форматируем
  const handlePriceBlur = (e) => {
    const formatted = formatNumberWithCommas(e.target.value);
    setFormData((prev) => ({
      ...prev,
      PriceForUnitVendor: formatted,
    }));
  };

  // Сохранение данных
  const handleSaveChanges = async () => {
    setIsSaving(true);
    setError(null);

    const token = localStorage.getItem("token");
    const itemNumber = items[currentItemIndex]?.item;

    // Если PriceRequested === "No" => обновляем статус line item в "Price requested"
    if (formData.PriceRequested === "No") {
      try {
        const resp = await fetch("https://ai-back-23-production.up.railway.app/update-document-status/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            document_name: uploadedFile,
            item: itemNumber,
            status: "Price requested",
          }),
        });
        if (!resp.ok) {
          throw new Error("Не удалось установить статус Price requested");
        }
        alert(`Статус обновлен => "Price requested" (item ${itemNumber}).`);
        setIsSubmitted(true);
        setIsFormSaved(true);
      } catch (err) {
        setError(err.message || "Произошла ошибка при установке статуса Price requested.");
      } finally {
        setIsSaving(false);
      }
      return;
    }

    // Для PriceRequested === "Yes" и "N/A" выполняем обычное сохранение данных через /save-data/
    // Если PriceRequested === "N/A", дополнительно проверяем, что SubStatus заполнен
    if (formData.PriceRequested === "N/A" && !formData.SubStatus.trim()) {
      setError("Пожалуйста, выберите подстатус (KIM Certificate и т.д.)");
      setIsSaving(false);
      return;
    }

    try {
      const response = await fetch("https://ai-back-23-production.up.railway.app/save-data/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          document_name: uploadedFile,
          item: itemNumber,
          Incoterms: formData.Incoterms,
          PriceForUnitVendor: formData.PriceForUnitVendor,
          TotalPriceVendor: formData.TotalPriceVendor,
          LeadTimeVendor: formData.LeadTimeVendor,
          VendorName: formData.VendorName,
          Deviation: formData.Deviation,
          ClarificationOnDeviation: formData.ClarificationOnDeviation,
          TCTechnicalClarification: formData.TCTechnicalClarification,
          PriceRequested: formData.PriceRequested,
          SubStatus: formData.SubStatus,
        }),
      });
      if (!response.ok) {
        const errData = await response.json();
        throw new Error(errData.detail || "Не удалось сохранить данные.");
      }

      alert(`Данные сохранены (item ${itemNumber}).`);

      // Обновляем items в стейте
      const newArr = [...items];
      newArr[currentItemIndex] = {
        ...newArr[currentItemIndex],
        incoterms: formData.Incoterms,
        price_for_unit_vendor: formData.PriceForUnitVendor,
        total_price_vendor: formData.TotalPriceVendor,
        lead_time_vendor: formData.LeadTimeVendor,
        vendor_name: formData.VendorName,
        deviation: formData.Deviation,
        clarification_on_deviation: formData.ClarificationOnDeviation,
        tc_technical_clarification: formData.TCTechnicalClarification,
        price_requested: formData.PriceRequested,
        sub_status: formData.SubStatus,
      };
      setItems(newArr);
      setIsSubmitted(true);

      if (isFormComplete) {
        setIsFormSaved(true);
      }
    } catch (err) {
      setError(err.message || "Произошла ошибка при сохранении данных.");
    } finally {
      setIsSaving(false);
    }
  };

  const handlePreviousItem = () => {
    if (currentItemIndex > 0) {
      const prev = currentItemIndex - 1;
      setCurrentItemIndex(prev);
      setIsSubmitted(false);
      updateFormData(items[prev]);
    }
  };

  const handleNextItem = () => {
    if (currentItemIndex < items.length - 1) {
      const nxt = currentItemIndex + 1;
      setCurrentItemIndex(nxt);
      setIsSubmitted(false);
      updateFormData(items[nxt]);
    }
  };

  // Когда нажимаем Submit => переводим статус на "C&L calculation and dates"
  const handleSubmit = async () => {
    setIsSubmitting(true);
    setError(null);

    try {
      const token = localStorage.getItem("token");
      const itemNumber = items[currentItemIndex]?.item;

      if (userData?.role === "buyer" || userData?.role === "buyerFull") {
        const res = await fetch("https://ai-back-23-production.up.railway.app/update-document-status/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            document_name: uploadedFile,
            item: itemNumber,
            status: "C&L calculation and dates",
          }),
        });
        if (!res.ok) {
          throw new Error("Не удалось обновить статус на C&L calculation and dates.");
        }
        alert(
          `Item ${itemNumber} => C&L calculation and dates. Переходим на Certification...`
        );
      } else if (userData?.role === "admin") {
        const response = await fetch("https://ai-back-23-production.up.railway.app/update-document-status/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            document_name: uploadedFile,
            status: "C&L calculation and dates",
          }),
        });
        if (!response.ok) {
          throw new Error("Не удалось обновить статус (admin -> doc-level).");
        }
        alert("Документ переведён в C&L calculation and dates.");
      }

      navigate("/certification-overview");
    } catch (err) {
      setError("Ошибка при обновлении статуса на C&L calculation and dates.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const isBuyerOrFull = userData?.role === "buyer" || userData?.role === "buyerFull";
  const isAdmin = userData?.role === "admin";

  return (
    <div className="h-screen bg-gray-100 p-6 relative">
      {isSubmitting && (
        <div className="absolute inset-0 z-50 flex flex-col items-center justify-center bg-white bg-opacity-90">
          <div className="loader" />
          <p className="mt-2 text-gray-700">Submitting...</p>
        </div>
      )}

      <div className="relative h-[calc(100vh-3rem)] overflow-hidden rounded-xl border bg-white shadow-sm">
        <div className="flex h-full">
          {/* Левая панель */}
          <div
            className={`h-full transition-all duration-300 ease-in-out ${
              isRightVisible ? "w-1/2" : "w-full"
            }`}
          >
            <div className="h-full flex flex-col p-4 overflow-auto">
              <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">RFQ by Line Item</h1>
                <div className="flex space-x-2">
                  <button
                    className="flex items-center bg-gray-500 text-white px-4 py-2 rounded"
                    onClick={() => navigate(-1)}
                  >
                    <ArrowLeftFromLine className="h-4 w-4 mr-2" />
                    back to RFQ Status Overview
                  </button>
                </div>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">
                  Uploaded RFQ: {uploadedFile}
                </label>
                <label className="block text-gray-700 font-bold">
                  Current Item: {items[currentItemIndex]?.item || "No item"}
                </label>
              </div>

              {/* Таблица полей */}
              <div className="overflow-auto border rounded-lg shadow-md mb-4">
                <table className="w-full border-collapse text-sm">
                  <thead className="bg-gray-100 sticky top-0">
                    <tr>
                      <th className="border px-4 py-2">Field</th>
                      <th className="border px-4 py-2">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(isBuyerOrFull ? buyerFields : adminFields).map((field) => {
                      const { key, label, type, options, placeholder } = field;
                      let disabled = false;

                      // Если PriceRequested="No", то все поля (кроме PriceRequested/SubStatus) disabled
                      if (
                        isFieldsDisabled &&
                        key !== "PriceRequested" &&
                        key !== "SubStatus"
                      ) {
                        disabled = true;
                      }

                      // Скрываем SubStatus, если PriceRequested != "N/A"
                      if (key === "SubStatus" && formData.PriceRequested !== "N/A") {
                        return null;
                      }

                      // Если Deviation="No", отключаем ClarificationOnDeviation
                      if (key === "ClarificationOnDeviation" && formData.Deviation === "No") {
                        disabled = true;
                      }

                      // "TotalPriceVendor" => readOnly
                      if (key === "TotalPriceVendor") {
                        return (
                          <tr key={key}>
                            <td className="border px-4 py-2">{label}</td>
                            <td className="border px-4 py-2">
                              <input
                                type="text"
                                name={key}
                                value={formData[key]}
                                readOnly
                                className="w-full border rounded px-2 py-1 bg-gray-100 text-gray-700"
                              />
                            </td>
                          </tr>
                        );
                      }

                      // Поле select
                      if (type === "select") {
                        return (
                          <tr key={key}>
                            <td className="border px-4 py-2">{label}</td>
                            <td className="border px-4 py-2">
                              <select
                                name={key}
                                value={formData[key]}
                                onChange={handleInputChange}
                                disabled={disabled}
                                className={`w-full border rounded px-2 py-1 ${
                                  formData[key] === "" ? "text-gray-500" : "text-black"
                                }`}
                              >
                                <option value="">-select-</option>
                                {options.map((opt) => (
                                  <option key={opt} value={opt}>
                                    {opt}
                                  </option>
                                ))}
                              </select>
                            </td>
                          </tr>
                        );
                      }

                      // Остальные input
                      return (
                        <tr key={key}>
                          <td className="border px-4 py-2">{label}</td>
                          <td className="border px-4 py-2">
                            <input
                              type="text"
                              name={key}
                              value={formData[key]}
                              onChange={handleInputChange}
                              onBlur={key === "PriceForUnitVendor" ? handlePriceBlur : undefined}
                              placeholder={placeholder || ""}
                              className="w-full border rounded px-2 py-1"
                              disabled={disabled}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              {/* Кнопки */}
              {isBuyerOrFull ? (
                <div className="mb-4 flex space-x-2">
                  <button
                    className={`bg-blue-500 text-white px-4 py-2 rounded ${
                      isSaving ? "cursor-not-allowed opacity-50" : ""
                    }`}
                    onClick={handleSaveChanges}
                    disabled={isSaving}
                  >
                    {isSaving ? "Saving..." : "Save Changes"}
                  </button>
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                    onClick={() => setShowRelatedModal(true)}
                  >
                    Related Documents
                  </button>
                </div>
              ) : isAdmin ? (
                <div className="flex justify-between items-center mb-4">
                  <button
                    className="bg-gray-500 text-white px-4 py-2 rounded"
                    onClick={handlePreviousItem}
                    disabled={currentItemIndex === 0}
                  >
                    Previous Item
                  </button>
                  <div className="flex space-x-2">
                    <button
                      className={`bg-blue-500 text-white px-4 py-2 rounded ${
                        isSaving ? "cursor-not-allowed opacity-50" : ""
                      }`}
                      onClick={handleSaveChanges}
                      disabled={isSaving}
                    >
                      {isSaving ? "Saving..." : "Save Changes"}
                    </button>
                    <button
                      className="bg-blue-600 text-white px-4 py-2 rounded"
                      onClick={() => setShowRelatedModal(true)}
                    >
                      Related Documents
                    </button>
                  </div>
                  <button
                    className="bg-gray-500 text-white px-4 py-2 rounded"
                    onClick={handleNextItem}
                    disabled={currentItemIndex === items.length - 1}
                  >
                    Next Item
                  </button>
                </div>
              ) : null}

              {(isBuyerOrFull || isAdmin) && (
                <button
                  className={`bg-green-500 text-white px-4 py-2 rounded ${
                    !isFormComplete || !isFormSaved
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  onClick={handleSubmit}
                  disabled={!isFormComplete || !isFormSaved}
                >
                  Submit
                </button>
              )}

              {error && (
                <p className="text-red-500 mt-4 whitespace-pre-wrap">{error}</p>
              )}
            </div>
          </div>

          {/* Правая панель */}
          <div
            className={`h-full overflow-auto border-l transition-all duration-300 ease-in-out ${
              isRightVisible ? "w-1/2 opacity-100" : "w-0 opacity-0"
            }`}
          >
            <div className="h-full flex flex-col p-6">
              <h2 className="text-xl font-semibold mb-4">RFQ Information</h2>
              <div className="grid gap-6">
                <div className="space-y-4 rounded-lg border p-4">
                  <div className="grid gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Material / Service
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {items[currentItemIndex]?.material_service || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Description
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {items[currentItemIndex]?.description || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Manufacturer
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {items[currentItemIndex]?.manufacturer || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Part Number
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {items[currentItemIndex]?.manufacturer_part_number || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Qty
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {items[currentItemIndex]?.qty || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Base Unit
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {items[currentItemIndex]?.base_unit || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Certificate
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {items[currentItemIndex]?.certificate || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Certificate of Conformity
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {items[currentItemIndex]?.certificate_of_conformity || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Кнопка свёртывания правой панели */}
          <button
            onClick={() => setIsRightVisible(!isRightVisible)}
            className="absolute right-0 top-1/2 z-20 -translate-y-1/2 rounded-l-lg bg-gray-200 px-2 py-8 hover:bg-gray-300"
          >
            {isRightVisible ? (
              <ChevronRight className="h-4 w-4" />
            ) : (
              <ChevronLeft className="h-4 w-4" />
            )}
          </button>
        </div>
      </div>

      {/* Модалка Related */}
      {showRelatedModal && (
        <div className="absolute inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md w-96 relative">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={() => setShowRelatedModal(false)}
            >
              <X className="w-5 h-5" />
            </button>
            <h2 className="text-xl font-bold mb-4">Related Documents</h2>
            <div>
              <label className="block font-semibold mb-1">
                Add Google Drive link:
              </label>
              <input
                type="text"
                className="w-full border rounded px-2 py-1 mb-2"
                value={docLinkInput}
                onChange={(e) => setDocLinkInput(e.target.value)}
                placeholder="https://drive.google.com/..."
              />
              <button
                className="bg-blue-600 text-white px-3 py-1 rounded"
                onClick={() => {
                  if (docLinkInput.trim()) {
                    setDocLinks((prev) => [...prev, docLinkInput.trim()]);
                    setDocLinkInput("");
                  }
                }}
              >
                Add
              </button>
            </div>
            <div className="mt-4">
              <h3 className="font-semibold">Saved Links:</h3>
              <ul className="list-disc list-inside mt-2">
                {docLinks.map((link, i) => (
                  <li key={i}>
                    <a
                      href={link}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-500 underline"
                    >
                      {link}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RFQSubmit;
