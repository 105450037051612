"use client";

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ChevronRight, ArrowLeftFromLine } from "lucide-react";

// Функция для форматирования числа с разделителями тысяч
function formatNumber(numStr) {
  if (!numStr) return "";
  const clean = numStr.replace(/,/g, "");
  const num = Number(clean);
  if (isNaN(num)) return numStr;
  return num.toLocaleString("en-US", { maximumFractionDigits: 20 });
}

// Функция пересчёта прочих расходов (например, транспорт, сертификат, таможня)
function recalcCosts(values) {
  const transCost = parseFloat(values.TransportationCost.replace(/,/g, "")) || 0;
  const certCost = parseFloat(values.CertificateCost.replace(/,/g, "")) || 0;
  const customsCost = parseFloat(values.CustomsClearance.replace(/,/g, "")) || 0;
  const totalCost = transCost + certCost + customsCost;

  const transDays = parseFloat(values.LeadTimeForTransportationDays.replace(/,/g, "")) || 0;
  const certDays = parseFloat(values.LeadTimeForCertificateDays.replace(/,/g, "")) || 0;
  const customDays = parseFloat(values.LeadTimeForCustomsClearanceDays.replace(/,/g, "")) || 0;
  const totalDays = transDays + certDays + customDays;

  return {
    totalCost: totalCost.toString(),
    totalDays: totalDays.toString(),
  };
}

// Функция для вычисления Fee ($) и Final Cost ($)
// Формула: Fee ($) = (Total Price Vendor ($) + Total C&L Costs ($)) * (Fee (%) / 100)
function calculateFee(totalPriceVendor, totalCLCosts, feePercent) {
  const fee = (totalPriceVendor + totalCLCosts) * (feePercent / 100);
  const finalCost = totalPriceVendor + totalCLCosts + fee;
  return { fee, finalCost };
}

const PricingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const docFromQuery = searchParams.get("document_name");
  const itemFromQuery = searchParams.get("item");

  const uploadedFile =
    location.state?.document_name || docFromQuery || "No file uploaded";
  const currentItem =
    location.state?.item || itemFromQuery || "";

  const initialFormData = {
    PriceForUnitVendor: "",
    TotalPriceVendor: "",
    HistoricalPrice: "",
    HistoricalPriceYear: "",
    HistoricalPriceIncoterms: "",
    TotalVendorPriceT_C: "",
    FeePercent: "",
    Fee: "",
    FinalCost: "",
    Comments: "",
    LeadsScope: "", // Новое поле "LeadsScope" (Lead's scope)
  };

  const [formData, setFormData] = useState(initialFormData);
  const [itemData, setItemData] = useState({});
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isFormSaved, setIsFormSaved] = useState(false);
  const [isRightVisible, setIsRightVisible] = useState(true);

  // Проверка заполнения формы (требуются все необходимые поля)
  const isFormComplete = (() => {
    const requiredKeys = [
      "HistoricalPrice",
      "HistoricalPriceYear",
      "HistoricalPriceIncoterms",
      "FeePercent",
      "Fee",
      "FinalCost",
      "Comments",
      "LeadsScope", // Обязательное поле
    ];
    return requiredKeys.every((key) => formData[key].trim() !== "");
  })();

  // Загрузка pricing-данных для текущего item из эндпоинта /get-pricing-data/
  useEffect(() => {
    const fetchPricingData = async () => {
      try {
        const resp = await fetch(
          `https://ai-back-23-production.up.railway.app/get-pricing-data/?document_name=${encodeURIComponent(
            uploadedFile
          )}&item=${encodeURIComponent(currentItem)}`
        );
        if (!resp.ok) {
          throw new Error("Не удалось загрузить pricing-данные.");
        }
        const data = await resp.json();
        setFormData({
          PriceForUnitVendor: data.PriceForUnitVendor || "",
          TotalPriceVendor: data.TotalPriceVendor || "",
          HistoricalPrice: data.HistoricalPrice || "",
          HistoricalPriceYear: data.HistoricalPriceYear || "",
          HistoricalPriceIncoterms: data.HistoricalPriceIncoterms || "",
          TotalVendorPriceT_C: data.TotalVendorPriceT_C || "",
          FeePercent: data.FeePercent || "",
          Fee: data.Fee || "",
          FinalCost: data.FinalCost || "",
          Comments: data.Comments || "",
          LeadsScope: data.LeadsScope || "", // Используем новое поле
        });
      } catch (err) {
        setError(err.message);
      }
    };

    if (uploadedFile !== "No file uploaded" && currentItem) {
      fetchPricingData();
    }
  }, [uploadedFile, currentItem]);

  // Загрузка данных Item Information через /get-documents-manually/
  useEffect(() => {
    const fetchItemInfo = async () => {
      try {
        const resp = await fetch(
          `https://ai-back-23-production.up.railway.app/get-documents-manually/?document_name=${encodeURIComponent(uploadedFile)}`
        );
        if (!resp.ok) {
          throw new Error("Не удалось загрузить данные по документу.");
        }
        const data = await resp.json();
        // Фильтруем по item (предполагается, что поле с номером позиции называется "item")
        const found = data.find((i) => String(i.item) === String(currentItem));
        if (found) {
          setItemData(found);
        }
      } catch (err) {
        setError(err.message);
      }
    };
    if (uploadedFile !== "No file uploaded" && currentItem) {
      fetchItemInfo();
    }
  }, [uploadedFile, currentItem]);

  // Обработка изменения полей формы
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "FeePercent") {
      const totalPriceVendor = parseFloat(formData.TotalPriceVendor.replace(/,/g, "")) || 0;
      const totalCLCosts = parseFloat(formData.TotalVendorPriceT_C.replace(/,/g, "")) || 0;
      const feePercent = parseFloat(value) || 0;
      const { fee, finalCost } = calculateFee(totalPriceVendor, totalCLCosts, feePercent);
      setFormData((prev) => ({
        ...prev,
        [name]: value,
        Fee: formatNumber(fee.toFixed(2)),
        FinalCost: formatNumber(finalCost.toFixed(2)),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    setIsFormSaved(false);
  };

  // onBlur для форматирования числовых значений
  const handleBlurFormat = (e) => {
    const { name, value } = e.target;
    const formatted = formatNumber(value);
    setFormData((prev) => ({
      ...prev,
      [name]: formatted,
    }));
  };

  // Сохранение данных (Save Changes)
  const handleSaveChanges = async () => {
    setIsSaving(true);
    setError(null);
    try {
      const token = localStorage.getItem("token");
      const resp = await fetch("https://ai-back-23-production.up.railway.app/save-pricing-data/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          document_name: uploadedFile,
          item: currentItem,
          PriceForUnitVendor: formData.PriceForUnitVendor,
          TotalPriceVendor: formData.TotalPriceVendor,
          HistoricalPrice: formData.HistoricalPrice,
          HistoricalPriceYear: formData.HistoricalPriceYear,
          HistoricalPriceIncoterms: formData.HistoricalPriceIncoterms,
          TotalVendorPriceT_C: formData.TotalVendorPriceT_C,
          FeePercent: formData.FeePercent,
          Fee: formData.Fee,
          FinalCost: formData.FinalCost,
          Comments: formData.Comments,
          LeadsScope: formData.LeadsScope, // Отправляем новое поле "LeadsScope"
        }),
      });
      if (!resp.ok) {
        const data = await resp.json();
        throw new Error(data.detail || "Не удалось сохранить pricing data.");
      }
      alert("Pricing data saved.");
      setIsFormSaved(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSaving(false);
    }
  };

  // Завершение (Submit – установка статуса "Completed")
  const handleSubmit = async () => {
    if (!isFormComplete || !isFormSaved) {
      alert("Сначала заполните и сохраните форму!");
      return;
    }
    try {
      const token = localStorage.getItem("token");
      const resp = await fetch("https://ai-back-23-production.up.railway.app/update-pricing-status/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          document_name: uploadedFile,
          item: currentItem,
          status: "Completed",
        }),
      });
      if (!resp.ok) {
        throw new Error("Не удалось установить статус 'Completed'.");
      }
      alert("Pricing => Completed. (Procon => Not Started). Возвращаемся к Overview.");
      navigate("/pricing-overview");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="h-screen bg-gray-100 p-6 relative">
      <div className="relative h-[calc(100vh-3rem)] overflow-hidden rounded-xl border bg-white shadow-sm">
        <div className="flex h-full">
          {/* Левая панель */}
          <div className={`h-full transition-all duration-300 ease-in-out ${isRightVisible ? "w-1/2" : "w-full"}`}>
            <div className="h-full flex flex-col p-4 overflow-auto">
              <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">Pricing Data</h1>
                <div className="flex space-x-2">
                  <button
                    className="flex items-center bg-gray-500 text-white px-4 py-2 rounded"
                    onClick={() => navigate("/pricing-overview")}
                  >
                    <ArrowLeftFromLine className="h-4 w-4 mr-2" />
                    back to Quote price calculation
                  </button>
                </div>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-1">RFQ#: {uploadedFile}</label>
                <label className="block text-gray-700 font-bold mb-1">item#: {currentItem}</label>
              </div>

              {/* Таблица с полями */}
              <div className="overflow-auto border rounded-lg shadow-md mb-4">
                <table className="w-full border-collapse text-sm">
                  <thead className="bg-gray-100 sticky top-0">
                    <tr>
                      <th className="border px-4 py-2">Field</th>
                      <th className="border px-4 py-2">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-4 py-2">Price For Unit Vendor ($)</td>
                      <td className="border px-4 py-2">
                        <input
                          type="text"
                          name="PriceForUnitVendor"
                          value={formData.PriceForUnitVendor}
                          readOnly
                          className="w-full border rounded px-2 py-1 bg-gray-100 text-gray-700"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">Total Price Vendor ($)</td>
                      <td className="border px-4 py-2">
                        <input
                          type="text"
                          name="TotalPriceVendor"
                          value={formData.TotalPriceVendor}
                          readOnly
                          className="w-full border rounded px-2 py-1 bg-gray-100 text-gray-700"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">Historical Price ($)</td>
                      <td className="border px-4 py-2">
                        <input
                          type="text"
                          name="HistoricalPrice"
                          value={formData.HistoricalPrice}
                          onChange={handleInputChange}
                          onBlur={handleBlurFormat}
                          className="w-full border rounded px-2 py-1"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">Historical Price Year</td>
                      <td className="border px-4 py-2">
                        <input
                          type="text"
                          name="HistoricalPriceYear"
                          value={formData.HistoricalPriceYear}
                          onChange={handleInputChange}
                          className="w-full border rounded px-2 py-1"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">Historical Price Incoterms</td>
                      <td className="border px-4 py-2">
                        <input
                          type="text"
                          name="HistoricalPriceIncoterms"
                          value={formData.HistoricalPriceIncoterms}
                          onChange={handleInputChange}
                          className="w-full border rounded px-2 py-1"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">Total C&amp;L Costs ($)</td>
                      <td className="border px-4 py-2">
                        <input
                          type="text"
                          name="TotalVendorPriceT_C"
                          value={formData.TotalVendorPriceT_C}
                          readOnly
                          className="w-full border rounded px-2 py-1 bg-gray-100 text-gray-700"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">Fee (%)</td>
                      <td className="border px-4 py-2">
                        <input
                          type="text"
                          name="FeePercent"
                          value={formData.FeePercent}
                          onChange={handleInputChange}
                          onBlur={handleBlurFormat}
                          className="w-full border rounded px-2 py-1"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">Fee ($)</td>
                      <td className="border px-4 py-2">
                        <input
                          type="text"
                          name="Fee"
                          value={formData.Fee}
                          readOnly
                          className="w-full border rounded px-2 py-1 bg-gray-100 text-gray-700"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">Final Cost ($)</td>
                      <td className="border px-4 py-2">
                        <input
                          type="text"
                          name="FinalCost"
                          value={formData.FinalCost}
                          readOnly
                          className="w-full border rounded px-2 py-1 bg-gray-100 text-gray-700"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">Comments</td>
                      <td className="border px-4 py-2">
                        <input
                          type="text"
                          name="Comments"
                          value={formData.Comments}
                          onChange={handleInputChange}
                          className="w-full border rounded px-2 py-1"
                        />
                      </td>
                    </tr>
                    {/* Новая строка для select "Lead's scope" */}
                    <tr>
                      <td className="border px-4 py-2">Lead's scope</td>
                      <td className="border px-4 py-2">
                        <select
                          name="LeadsScope"
                          value={formData.LeadsScope}
                          onChange={handleInputChange}
                          className="w-full border rounded px-2 py-1"
                        >
                          <option value="">-- Select --</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="mb-4 flex space-x-2">
                <button
                  className={`bg-blue-500 text-white px-4 py-2 rounded ${isSaving ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={handleSaveChanges}
                  disabled={isSaving}
                >
                  {isSaving ? "Saving..." : "Save Changes"}
                </button>
                <button
                  className={`bg-green-500 text-white px-4 py-2 rounded ${!isFormComplete || !isFormSaved ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={handleSubmit}
                  disabled={!isFormComplete || !isFormSaved}
                >
                  Submit
                </button>
              </div>

              {error && <p className="text-red-500 mt-2 whitespace-pre-wrap">{error}</p>}
            </div>
          </div>

          {/* Правая панель – Item Information */}
          <div className={`h-full overflow-auto border-l transition-all duration-300 ease-in-out ${isRightVisible ? "w-1/2 opacity-100" : "w-0 opacity-0"}`}>
            <div className="h-full flex flex-col p-6">
              <h2 className="text-xl font-semibold mb-4">Item Information</h2>
              <div className="grid gap-6">
                <div className="space-y-4 rounded-lg border p-4">
                  <div className="grid gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Material / Service
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {itemData?.material_service || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Description
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {itemData?.description || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Manufacturer
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {itemData?.manufacturer || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Part Number
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {itemData?.manufacturer_part_number || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Qty
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {itemData?.qty || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Base Unit
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {itemData?.base_unit || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Certificate
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {itemData?.certificate || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Certificate of Conformity
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {itemData?.certificate_of_conformity || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Кнопка сворачивания правой панели */}
          <button
            onClick={() => setIsRightVisible(!isRightVisible)}
            className="absolute right-0 top-1/2 z-20 -translate-y-1/2 rounded-l-lg bg-gray-200 px-2 py-8 hover:bg-gray-300"
          >
            <ChevronRight className="h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
