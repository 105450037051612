import React, { useContext, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Dashboard from "./pages/Dashboard/Dashboard";
import Chat from "./pages/Chat/Chat";
import LoginPage from "./pages/Auth/LoginPage";
import RegistrationPage from "./pages/Auth/RegistrationPage";
import RFQ from "./pages/RFQ/RFQ";
import CertificationOverview from "./pages/Certification and Logistics/CertificationOverview.js";
import CertificationAndLogistics from "./pages/Certification and Logistics/CertificationAndLogistics.js";
import PricingPage from "./pages/Pricing/PricingPage";
import RFQSystem from "./pages/RFQ/RFQSystem.js";
import RFQView from "./pages/RFQ/RFQView.js";
import RFQSubmit from "./pages/RFQ/RFQSubmit.js";
import PricingPageOverview from "./pages/Pricing/PricingPageOverview.js";
import POComponent from "./pages/PO/PO.js";
import POSystem from "./pages/PO/POSystem.js";
import POSubmit from "./pages/PO/POSubmit.js";
import Procon from "./pages/Procon/Procon.js";
import ProconSubmit from "./pages/Procon/ProconSubmit.js";
import NotFound from "./pages/404/404.js";
import ProtectedRoute from "./components/ProtectedRoute";
import UserContext from "./context/UserContext";
import CalendarPage from "./pages/Dashboard/Calendar.js";
import PoCalendarPage from "./pages/Dashboard/Pocalendar.js";
import Podashboard from "./pages/Dashboard/PoDashboard.js";
import POView from "./pages/PO/POView.js";

function App() {
  const { userData, logout } = useContext(UserContext);
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Проверка, что мы на странице авторизации/регистрации/корня (или календаря)
  // и не показываем Sidebar
  const isAuthPage =
    location.pathname === "/login" ||
    location.pathname === "/register" ||
    location.pathname === "/" ||
    location.pathname === "/calendar"; // <-- Если нужно скрывать сайдбар и на календаре

  // Если нужно более гибко: const isCalendarPage = location.pathname === "/calendar";

  // Пример логики контейнера
  let containerClasses = "";
  if (isAuthPage) {
    containerClasses = "bg-gray-900"; 
  } else {
    containerClasses = "overflow-hidden"; 
  }

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className={`flex min-h-screen ${containerClasses}`}>
      {/* Показываем Sidebar только если не на isAuthPage */}
      {!isAuthPage && (
        <Sidebar
          isChatOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
          userData={userData}
          onLogout={logout}
        />
      )}

      {/* Основной контент */}
      <div
        className={`transition-all flex-grow bg-gray-100 ${
          !isAuthPage && isSidebarOpen ? "ml-16" : !isAuthPage ? "ml-64" : ""
        }`}
      >
        <Routes>
          {/* Стандартные роуты логина/регистрации */}
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegistrationPage />} />

          {/* Главная панель (Dashboard) */}
          <Route
            path="/dashboard/*"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />

          {/* Страница календаря (полноэкранный календарь) */}
          <Route
            path="/calendar"
            element={
              <ProtectedRoute>
                <CalendarPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pocalendar"
            element={
              <ProtectedRoute>
                <PoCalendarPage />
              </ProtectedRoute>
            }
          />
          <Route  path="/podashboard" element={
              <ProtectedRoute>
                <Podashboard />
              </ProtectedRoute>
            }
          />

          {/* Прочие роуты (RFQ, Procon, PO и т.д.) */}
          <Route
            path="/procon"
            element={
              <ProtectedRoute>
                <Procon />
              </ProtectedRoute>
            }
          />
          <Route
            path="/procon-submit"
            element={
              <ProtectedRoute>
                <ProconSubmit />
              </ProtectedRoute>
            }
          />
          <Route
            path="/rfq"
            element={
              <ProtectedRoute>
                <RFQ />
              </ProtectedRoute>
            }
          />
          <Route
            path="/rfq-view"
            element={
              <ProtectedRoute>
                <RFQView />
              </ProtectedRoute>
            }
          />
          <Route
            path="/po"
            element={
              <ProtectedRoute>
                <POComponent />
              </ProtectedRoute>
            }
          />
          <Route
            path="/po-view"
            element={
              <ProtectedRoute>
                <POView />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pricing"
            element={
              <ProtectedRoute>
                <PricingPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/certification-logistics"
            element={
              <ProtectedRoute>
                <CertificationAndLogistics />
              </ProtectedRoute>
            }
          />
          <Route
            path="/certification-overview"
            element={
              <ProtectedRoute>
                <CertificationOverview />
              </ProtectedRoute>
            }
          />
          <Route
            path="/chat"
            element={
              <ProtectedRoute>
                <Chat />
              </ProtectedRoute>
            }
          />
          <Route
            path="/rfq-system"
            element={
              <ProtectedRoute>
                <RFQSystem />
              </ProtectedRoute>
            }
          />
          <Route
            path="/rfq-submit"
            element={
              <ProtectedRoute>
                <RFQSubmit />
              </ProtectedRoute>
            }
          />
          <Route
            path="/po-system"
            element={
              <ProtectedRoute>
                <POSystem />
              </ProtectedRoute>
            }
          />
          <Route
            path="/po-submit"
            element={
              <ProtectedRoute>
                <POSubmit />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pricing-overview"
            element={
              <ProtectedRoute>
                <PricingPageOverview />
              </ProtectedRoute>
            }
          />
          <Route
            path="/404"
            element={
              <ProtectedRoute>
                <NotFound />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
