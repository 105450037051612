import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ChevronLeft, ChevronRight, ArrowLeftFromLine } from "lucide-react";

const ProconSubmit = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Из роутера передаются document_name, item и, возможно, final_cost (из PricingPage)
  const uploadedFile = location.state?.document_name || "No file uploaded";
  const currentItem = location.state?.item || "";
  const finalCostFromPricing = location.state?.final_cost || "";

  // Начальное состояние формы (левая панель)
  const initialFormData = {
    cost_per_unit: "",
    final_cost: "", // Final Cost ($) – поле только для чтения, заполняется из API или из PricingPage
    responsible_company: "",
    procon_status: "Not loaded to Procon", // значение по умолчанию
  };

  // Состояния: форма (левая панель) и данные элемента (правая панель)
  const [formData, setFormData] = useState(initialFormData);
  const [itemData, setItemData] = useState({});
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isFormSaved, setIsFormSaved] = useState(false);
  const [isRightVisible, setIsRightVisible] = useState(true);

  // Получаем данные элемента через API (эндпоинт /get-procon-item)
  useEffect(() => {
    if (!uploadedFile || !currentItem) return;
    const fetchItemData = async () => {
      try {
        setError(null);
        const resp = await fetch(
          `https://ai-back-23-production.up.railway.app/get-procon-item/?document_name=${encodeURIComponent(
            uploadedFile
          )}&item=${encodeURIComponent(currentItem)}`
        );
        if (!resp.ok) {
          throw new Error("Failed to fetch Procon item data");
        }
        const data = await resp.json();
        // Заполняем данные для правой панели (Item Information)
        setItemData(data);
        // Заполняем поля формы: если с роутера пришло final_cost – используем его, иначе из API
        setFormData((prev) => ({
          ...prev,
          final_cost: finalCostFromPricing || data.final_cost || "",
          responsible_company: data.responsible_company || "",
          procon_status: data.procon_status || "Not loaded to Procon",
        }));
      } catch (err) {
        setError(err.message);
      }
    };
    fetchItemData();
  }, [uploadedFile, currentItem, finalCostFromPricing]);

  // Вычисляем автоматически cost_per_unit = (Final Cost / Qty)
  // При этом удаляем разделители тысяч из final_cost и форматируем результат с запятыми (например, 1,352.032)
  useEffect(() => {
    if (itemData.qty) {
      const qtyValue = parseFloat(itemData.qty);
      let finalCostStr = formData.final_cost || "";
      // Убираем все запятые, чтобы правильно выполнить деление
      finalCostStr = finalCostStr.replace(/,/g, "");
      const finalCostValue = parseFloat(finalCostStr) || 0;
      if (!isNaN(qtyValue) && qtyValue !== 0) {
        const computedCost = finalCostValue / qtyValue;
        // Форматируем число с разделителем тысяч и 3 знаками после запятой
        const computedCostFormatted = computedCost.toLocaleString("en-US", {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        });
        setFormData((prev) => ({ ...prev, cost_per_unit: computedCostFormatted }));
      }
    }
  }, [formData.final_cost, itemData.qty]);

  // Обработка изменения редактируемых полей формы (responsible_company и procon_status)
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setIsFormSaved(false);
  };

  // Сохранение изменений – POST /save-procon-data
  const handleSaveChanges = async () => {
    if (!uploadedFile || !currentItem) return;

    setIsSaving(true);
    setError(null);
    try {
      const token = localStorage.getItem("token");

      // Расчет total_lead_time_weeks (если требуется)
      const vendorWeeks = parseFloat(itemData.lead_time_vendor_weeks) || 0;
      const cAndLDays = parseFloat(itemData.total_lead_time_c_and_l_days) || 0;
      const sumWeeks = (vendorWeeks + cAndLDays / 7).toFixed(1);

      const bodyData = {
        document_name: uploadedFile,
        item: currentItem,
        cost_per_unit: formData.cost_per_unit,
        final_cost: formData.final_cost,
        lead_time_vendor_weeks: itemData.lead_time_vendor_weeks || "",
        total_lead_time_c_and_l_days: itemData.total_lead_time_c_and_l_days || "",
        responsible_company: formData.responsible_company,
        total_lead_time_weeks: String(sumWeeks),
      };

      const resp = await fetch("https://ai-back-23-production.up.railway.app/save-procon-data/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      });
      if (!resp.ok) {
        const data = await resp.json();
        throw new Error(data.detail || "Failed to save Procon data");
      }
      alert("Procon data saved. (Status => In Progress)");
      setIsFormSaved(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSaving(false);
    }
  };

  // Завершение – обновление статуса через /update-procon-status
  const handleSubmit = async () => {
    if (!isFormSaved) {
      alert("Please save changes first!");
      return;
    }
    try {
      const token = localStorage.getItem("token");
      const resp = await fetch("https://ai-back-23-production.up.railway.app/update-procon-status/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          document_name: uploadedFile,
          item: currentItem,
          new_status: formData.procon_status,
        }),
      });
      if (!resp.ok) {
        throw new Error("Failed to update Procon status");
      }
      alert(`Procon status updated to ${formData.procon_status}. Returning to Overview.`);
      navigate("/procon");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="h-screen bg-gray-100 p-6 relative">
      <div className="relative h-[calc(100vh-3rem)] overflow-hidden rounded-xl border bg-white shadow-sm">
        <div className="flex h-full">
          {/* Левая панель – форма редактирования Procon данных */}
          <div className={`h-full transition-all duration-300 ease-in-out ${isRightVisible ? "w-1/2" : "w-full"}`}>
            <div className="h-full flex flex-col p-4 overflow-auto">
              <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">Procon Data</h1>
                <div className="flex space-x-2">
                  <button
                    className="flex items-center bg-gray-500 text-white px-4 py-2 rounded"
                    onClick={() => navigate(-1)}
                  >
                    <ArrowLeftFromLine className="h-4 w-4 mr-2" />
                    Back to Procon Overview
                  </button>
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-1">
                  RFQ#: {uploadedFile}
                </label>
                <label className="block text-gray-700 font-bold mb-1">
                  Item#: {currentItem}
                </label>
              </div>
              {/* Форма Procon */}
              <div className="overflow-auto border rounded-lg shadow-md mb-4">
                <table className="w-full border-collapse text-sm">
                  <thead className="bg-gray-100 sticky top-0">
                    <tr>
                      <th className="border px-4 py-2">Field</th>
                      <th className="border px-4 py-2">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Cost per Unit – вычисляется автоматически */}
                    <tr>
                      <td className="border px-4 py-2">Cost per Unit</td>
                      <td className="border px-4 py-2">
                        <input
                          type="text"
                          name="cost_per_unit"
                          value={formData.cost_per_unit}
                          readOnly
                          className="w-full border rounded px-2 py-1 bg-gray-100 text-gray-700"
                        />
                      </td>
                    </tr>
                    {/* Final Cost ($) – поле только для чтения */}
                    <tr>
                      <td className="border px-4 py-2">Final Cost ($)</td>
                      <td className="border px-4 py-2">
                        <input
                          type="text"
                          name="final_cost"
                          value={formData.final_cost}
                          readOnly
                          className="w-full border rounded px-2 py-1 bg-gray-100 text-gray-700"
                        />
                      </td>
                    </tr>
                    {/* Responsible Company – редактируемое поле */}
                    <tr>
                      <td className="border px-4 py-2">Responsible Company</td>
                      <td className="border px-4 py-2">
                        <input
                          type="text"
                          name="responsible_company"
                          value={formData.responsible_company}
                          onChange={handleInputChange}
                          className="w-full border rounded px-2 py-1"
                        />
                      </td>
                    </tr>
                    {/* Procon Status – редактируемое поле (select) */}
                    <tr>
                      <td className="border px-4 py-2">Procon Status</td>
                      <td className="border px-4 py-2">
                        <select
                          name="procon_status"
                          value={formData.procon_status}
                          onChange={handleInputChange}
                          className="w-full border rounded px-2 py-1"
                        >
                          <option value="Not loaded to Procon">Not loaded to Procon</option>
                          <option value="To be loaded to Procon">To be loaded to Procon</option>
                          <option value="Quotation loaded to Procon">Quotation loaded to Procon</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Кнопки сохранения и сабмита */}
              <div className="mb-4 flex space-x-2">
                <button
                  className={`bg-blue-500 text-white px-4 py-2 rounded ${isSaving ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={handleSaveChanges}
                  disabled={isSaving}
                >
                  {isSaving ? "Saving..." : "Save Changes"}
                </button>
                <button
                  className={`bg-green-500 text-white px-4 py-2 rounded ${!isFormSaved ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={handleSubmit}
                  disabled={!isFormSaved}
                >
                  Submit
                </button>
              </div>
              {error && <p className="text-red-500 mt-2">{error}</p>}
            </div>
          </div>
          {/* Правая панель – Item Information (такая же, как в RFQSubmit) */}
          <div
            className={`h-full overflow-auto border-l transition-all duration-300 ease-in-out ${isRightVisible ? "w-1/2 opacity-100" : "w-0 opacity-0"}`}
          >
            <div className="h-full flex flex-col p-6">
              <h2 className="text-xl font-semibold mb-4">Item Information</h2>
              <div className="grid gap-6">
                <div className="space-y-4 rounded-lg border p-4">
                  <div className="grid gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Material / Service
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {itemData?.material_service || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Description
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {itemData?.description || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Manufacturer
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {itemData?.manufacturer || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Part Number
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {itemData?.manufacturer_part_number || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Qty
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {itemData?.qty || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Base Unit
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {itemData?.base_unit || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Certificate
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {itemData?.certificate || "N/A"}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Certificate of Conformity
                      </label>
                      <div className="mt-1 rounded-md border bg-gray-50 px-3 py-2">
                        {itemData?.certificate_of_conformity || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Кнопка сворачивания/разворачивания правой панели */}
          <button
            onClick={() => setIsRightVisible(!isRightVisible)}
            className="absolute right-0 top-1/2 z-20 -translate-y-1/2 rounded-l-lg bg-gray-200 px-2 py-8 hover:bg-gray-300"
          >
            {isRightVisible ? <ChevronRight className="h-4 w-4" /> : <ChevronLeft className="h-4 w-4" />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProconSubmit;
